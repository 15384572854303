/**
 * Saving user favorite path for default user landing
 * input : {userId:"",keyAccountId:"",favourites:{key:"landingPage",value:"landingPage",description:"landingPage"}}
 * output: true | false
 */
export const mutationSaveUserFavorite = `
  mutation saveUserFavourite ($input:InputFavourite) {
    saveUserFavourite(input:$input)
  }
`;


/**
 * send feed back api handleFeedbackForm
 */
export const handleFeedbackForm = /* GraphQL */ `
  mutation handleFeedbackForm($input: InputFeedbackForm) {
    handleFeedbackForm(input: $input)
  }
`;
