/**
 * Appsync query for getting user cognito pool information
 */
export const queryIdentityInfo = `
    query get {
        fetchAccount(id:"@userId") {
            poolID
            clientID
            termsAndConditionAccepted
        }
    }
`;

/**
 * Query to get domains
 * TODO Add `version` property here once BR is updated to fetch dynamic version from server
 */
export const queryGetDomains = `
    query fetchPilets {
        fetchPilets {
            microapp
            domain
            appsync
            title
            icon
            title
            order
            displayInToolbar
            version
            piralSchemaVersion
            features {
                id
                iv
                name
                title
                order
                path
                routeAlias
                subtitle
                isDefaultPath
                icon
                displayInToolbar
            }
        }
    }
`;

/**
 * Query to reset password
 */
export const queryResetPassword = `
    query resetPassword {
        resetPassword(id:"@userId")
    }
`;

/**
 * Fetch the User favorites appsync query
 */
export const queryUserFavorites = `
    query getUserFavourites {
        getUserFavourites (keyAccountId:"@keyAccountId",userId:"@userId") {
            key
            value
            description
        }
    }
`;

/**
 * Query to update user TnC and subscription
 */
export const queryUpdateUserSubscription = `
    query updateUserSubscription($input:InputUpdateSubscription) {
        updateUserSubscription(input:$input)
    }
`;

/**
* get user default service customer
*/
export const GetUser = `
  query getUser {
    getUser (keyAccountId:"@keyAccountId",id:"@userId"){
        defaultServiceCustomer
  	}
  }
  `;

/**
 * Get key account name
 */
export const GetKeyAccount = `
  query getKeyAccount {
    getKeyAccount (id:"@keyAccountId"){
  		name
  	}
  }
`;


/**
 * Get language
 */
 export const queryGetLanguage = `
 query getLanguage {
    getLanguage {
        id
        name
     }
 }
`;
