/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import * as React from "react";

import {
  TooltipProps,
  withStyles,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import { PentairBrightBlue, PentairThemeProvider } from "@pentair/react";

const styles: any = {
  tooltip: {
    margin: "0 4px",
    borderRadius: 0,
    backgroundColor: PentairBrightBlue[500],
  },
  arrow: {
    position: "absolute",
    color: PentairBrightBlue[500],
    fontSize: 6,
    width: "3em",
    height: "3em",
    "&::before": {
      content: "''",
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
};

export const Component: React.FC<TooltipProps> = (props) => (
  <PentairThemeProvider>
    <MuiTooltip
      arrow
      placement="left"
      {...(props || {})}
    />
  </PentairThemeProvider>
);

export const Tooltip = withStyles(styles)(Component);
