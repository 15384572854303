/// App-module names
export const MICRO_APP_NAME = "appshell";
export const MICRO_APP_USER_MANAGEMENT = "user-management";
export const MICRO_APP_PLATFORM_MANAGEMENT = "platform-management";
export const MICRO_APP_DASHBOARDS = "operations";
export const MOBILE_APP = "mobile-app";
export const MOBILE_APP_FEATURE = "MobileApp";
export const LANDING_PAGE_OBJECT_KEY = "landingPage";
export const SERVICE_CUSTOMER = "serviceCustomer";

/// Browser stored constant names
export const BROWSER_STORE_DOMAINS = "domains";
export const BROWSER_STORE_USER_ID = "userId";
export const BROWSER_STORE_PILET_INFO = "piletInfo";
export const BROWSER_STORE_SHELL_PERMISSIONS = "permissions_appShell";
export const BROWSER_STORE_DEF_LANDING_PAGE = "defaultLandingPage";
export const BROWSER_STORE_FAVORITES = "favorites";
export const BROWSER_STORE_IS_AUTHENTICATED = "isAuthenticated";
export const BROWSER_STORE_DEF_CONFIG = "default_config";
export const BROWSER_STORE_IS_MENU_HIDDEN = "hide-menus";
export const BROWSER_STORE_REDIRECT_TO = "redirect-to";
export const BROWSER_STORE_POOL_ID = "poolId";
export const BROWSER_STORE_WEB_CLIENT_ID = "webClientId";

export const BROWSER_STORE_I18NEXTLNG = "i18nextLng";
export const BROWSER_STORE_LANGUAGES = "languages";

export const PERSONAL_PROFILE = "Personal Profile";

/// Failure messages/data store
export const ERROR_FAV_PAGE_INVALID = "favoritePageInvalid";

/// Event names
export const REDIRECT_EVENT = "redirect-event";
export const BREADCRUMB_UPDATE_EVENT = "breadcrumb-update";

/// User domain names
export const USER_DOMAIN = "pentair.com";

/// Help center links
export const MS_TEAMS_OF_PENTAIR = "https://teams.microsoft.com/l/channel/19%3a55984aebe2ce41a8b89c033654786405%40thread.tacv2/BrewAssist%2520Help%2520Center?groupId=6a486fd5-9267-41a3-8129-f32667f51935&tenantId=8237194f-de9d-4e96-b705-d713a214c4ea";
export const PUBLIC_SUPPORT_PORTAL_OF_BREWASSIST = "https://pentair.atlassian.net/servicedesk/customer/portals";

export const ROLE_CATEGORY = "roleCategory";
export const PRODUCT_CATEGORY_MICROAPPS = "productCategoryMicroapps";
export const IS_MOBILE_LOGIN = "isMobileLogin";
export const MOBILE_TO_MESSAGE = "SendTokens";
export const MOBILE_FROM_MESSAGE = "getData";
export const MOBILE_APP_SECRET_KEY = "MOBILE_APP_SECRET_KEY";
export const CALLBACK_SET_TOKEN = "receiveExternalAuthToken"
export const FEED_BACK_CATEGORY = {
  bug: "BugReport",
  feature: "FeatureRequest",
}
export const UPLOAD_WHITE_LIST = [
  "image/*",
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".csv",
  ".xslx",
].join(",");

export const FAQ_PAGE_PATH = "faq";

