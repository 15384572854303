import { useState } from "react";
import { application } from "../application/application";

export const useAPIOnDemand = <T>(
  initialData: T,
  mockResponse?: T,
): [(query: string, variables: any, microApp?: string, formatterFn?: (data: any) => T) => void, T, boolean, Error | null] => {
  const [data, setData] = useState<T>(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetch = async (query: string, variables: any, microApp?: string,
    formatterFn?: (data: any) => T) => {
    try {
      setIsLoading(true);
      const response = await application.network.fetch(
        query,
        { ...variables },
        microApp
      );
      if (mockResponse) {
        setData(mockResponse);
      } else {
        if (formatterFn) setData(formatterFn(response) as T);
        else setData(response as T);
      }
    } catch (error) {
      if (mockResponse) {
        setData(mockResponse);
      } else {
        setError(error as Error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return [fetch, data, isLoading, error];
};
