import * as React from "react";
import {
  ComponentsState,
  ErrorComponentsState
} from "piral";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  Typography
} from "@material-ui/core";
import {
  getSessionData,
  PentairBlue,
  PentairDarkGray,
  pentairTheme,
  PentairThemeProvider,
  SnackbarProvider
} from "@pentair/react";
import {
  StylesProvider,
  createGenerateClassName,
  makeStyles
} from '@material-ui/core/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  isExternalPath,
  route,
} from "../../../config/routes";
import { AuthRoutes } from "../../../app-routes";
import { Notifications } from "../notifications";
import { Favorite } from "../favorite";
import { ToolBar } from "../toolbar";
import { PiletLayout } from "./pilet-layout";
import { IMicroAppFeature } from "../types";
import { BROWSER_STORE_SHELL_PERMISSIONS, PRODUCT_CATEGORY_MICROAPPS } from "../../../config/constants";
import LoadingIcon from "../../../components/loading-icon";
import { useLocation } from "react-router-dom";

const generateClassName = createGenerateClassName({
  productionPrefix: 'apsh',
});

library.add(fal, fas);

/**
 * Component styles
 */
const useStyles = makeStyles((theme) => ({
  actionsButton: {
    height: "38px",
    fontSize: "14px",
    textAlign: "left",
    textTransform: "unset",
    fontWeight: 500,
    margin: theme.spacing(1),
    marginLeft: 0
  }
}));

const disableEvent = (e: any) => {
  e.preventDefault();
};

/**
 * Defining the container layout to render all components
 */
export const layout: Partial<ComponentsState> = {
  Layout: ({ children }) => (
    <StylesProvider generateClassName={generateClassName}>
      <PentairThemeProvider>
        <SnackbarProvider>
          <CssBaseline />
          <div onContextMenu={disableEvent}>
            <AuthRoutes children={children}></AuthRoutes>
          </div>
        </SnackbarProvider>
      </PentairThemeProvider>
    </StylesProvider>
  ),
  LoadingIndicator: () => {
    if (isExternalPath()) {
      return <LoadingIcon></LoadingIcon>;
    }
    return null;
  },
  DashboardContainer: ({ children }) => {
    return (
      <PiletLayout children={children}></PiletLayout>
    );
  },
  MenuContainer: React.memo(() => {

    const [apps, setApps] = React.useState([] as IMicroAppFeature[]);

    const [openFavorite, setOpenFavorite] = React.useState(0);

    const [openNotification, setOpenNotification] = React.useState(0);
    /** Permission decided by matching product category micro apps and
     * User permissions micro apps */
    React.useEffect(() => {
      const getUser = async () => {
        const microAppFeatures = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);
        const productCategoryMicroapps = getSessionData(PRODUCT_CATEGORY_MICROAPPS, []);
        let availableMicroappList: IMicroAppFeature[] = [];
        if (!microAppFeatures.find((x) => x.microapp === "platform-management")) {
          let microapps: string[] = [];
          productCategoryMicroapps.forEach((microapp: any) => {
            microapps = [...microapps, ...microapp?.microapps];
          });
          availableMicroappList = microAppFeatures.filter((x) => microapps.includes(x.microapp));
        } else {
          availableMicroappList = microAppFeatures;
        }
        setApps(availableMicroappList);
      };
      getUser();
    }, []);

    /**
     * Callback to open favorite drawer
     * @param openDrawer 
     */
    const openFavoriteSideDrawer = (openDrawer: boolean) => {
      setOpenFavorite(openFavorite + 1);
    };

    /**
     * Callback to open notification drawer
     * @param openDrawer 
     */
    const openNotificationSideDrawer = (openDrawer: boolean) => {
      setOpenNotification(openNotification + 1);
    };
    const loc = useLocation();
    return (
      <div>
        <Notifications open={openNotification} />
        <Favorite location={loc} open={openFavorite} />
        <ToolBar
          microApps={apps}
          openFavoriteSideDrawer={openFavoriteSideDrawer}
          openNotificationSideDrawer={openNotificationSideDrawer}
        />
      </div>
    );
  }),
  ErrorInfo: (component) => {
    const errorMessage = 'failed to fetch';
    let isLoading = true;

    // Validate whether component has any issue while rendering.
    for (const [key, value] of Object.entries(component)) {
      // Compare the ERROR message, if exist then set isLoading flag to False as error occurred.
      if ('error' === key.toLowerCase() &&
        `${value}`.toLowerCase().includes(errorMessage) === true) {
        isLoading = false;
        break;
      }
    }
    const classes = useStyles();
    const [isError, showError] = React.useState<boolean>(true);

    return isLoading ? <LoadingIcon /> : (
      <Dialog
        style={{ font: "Barlow SemiBold", padding: pentairTheme.spacing(2), margin: pentairTheme.spacing(2), borderRadius: 0 }}
        disableEscapeKeyDown maxWidth="sm" open={isError}>
        <span style={{ font: "Barlow SemiBold", padding: pentairTheme.spacing(2) }}>
          <Typography variant="h4" style={{ color: PentairBlue[500] }}>Error on Page: {component?.type || ""}</Typography>
          <span style={{ font: "Barlow SemiBold" }}>
            <Typography variant="body2" style={{ color: PentairDarkGray[500] }}>
              Something went wrong. Try to refresh your Browser screen. If this issue persist, please reach out to us at <a style={{ color: PentairBlue[500] }} id="link" href="Mailto:pentairq.support@pentair.com">pentairq.support@pentair.com</a>
            </Typography>
          </span>
        </span>
        <DialogActions>
          <Button
            color="default"
            className={classes.actionsButton}
            onClick={() => {
              showError(false);
              window.location.href = route("app.shell.administration");
            }}
            startIcon={<FontAwesomeIcon
              icon={["fal", "long-arrow-left"]}
              style={{ marginRight: 8 }}
              color={"#464747"}
            />}
          >
            Back
          </Button>
          <Button
            color="primary"
            className={classes.actionsButton}
            onClick={() => {
              showError(false);
              window.location.reload();
            }}
            startIcon={<FontAwesomeIcon icon={["fal", "check"]} />}
          >
            Close and Reload
          </Button>
        </DialogActions>
      </Dialog>);
  }
};

/**
 * Defining the errors on Piral/Pilet to be captured
 */
export const errors: Partial<ErrorComponentsState> = {
  extension: () => <div />,
  feed: ({ error }) => (
    <span>
      <h1>Data Unavailable</h1>
      <div>
        The demanded data has not been found. Please contact support to resolve this issue.
      </div>
    </span>
  ),
  loading: () => (
    <span>
      <h1>Something Went Wrong</h1>
      <div>
        An error occurred during the loading process. Try refreshing or come back later.
      </div>
    </span>
  ),
  not_found: () => (
    <span>
      <h1>Page Not Found</h1>
      <div>
        An error occurred during the loading process. Try refreshing or come back later.
        The provided URL does not map to a page. Please contact support to resolve this issue.
      </div>
    </span>
  ),
  page: () => (
    <span>
      <h1>Page Crashed</h1>
      <div>
        Sorry for the inconvenience. We try to resolve the issue as soon as possible.
      </div>
    </span>
  ),
  unknown: () => (
    <span>
      <h1>Unknown Error</h1>
      <div>
        An unknown error occurred.
      </div>
    </span>
  )
};
