import {
  PiralInstance,
  renderInstance,
} from "piral";
import { getSessionData } from "@pentair/react";
import {
  layout,
  errors,
} from "./features/common/layouts/layout";
import {
  IMicroAppFeature,
  IRedirectEvent,
} from "./features/common/types";
import {
  BROWSER_STORE_PILET_INFO,
  BROWSER_STORE_SHELL_PERMISSIONS,
  REDIRECT_EVENT,
} from "./config/constants";
import {
  onHandleEvent,
  route,
} from "./config/routes";

/**
 * The instance of Micro-app singleton to hold the
 * pilet instance
 */
export class MicroAppPiletSingleton {
  /**
   * A Piral instance of an application
   */
  private app: PiralInstance;

  /**
   * A singleton instance of this class
   */
  private static instance: MicroAppPiletSingleton;

  /**
   * Constructor
   * @param app An instance of a Piral(App-shell)
   */
  private constructor(app: PiralInstance) {
    this.app = app;
  }

  /**
   * An utility to create an instance of MicroAppPiletSingleton with unique Piral app instance
   * @param app An instance of a Piral(App-shell)
   * @returns a singleton instance of MicroAppPiletSingleton
   */
  public static initInstance(app: PiralInstance): MicroAppPiletSingleton {
    if (!this.instance) {
      this.instance = new MicroAppPiletSingleton(app);
    }
    return this.instance;
  }

  /**
   * Utility to get singleton instance through the App-shell
   * @returns a singleton instance of MicroAppPiletSingleton
   */
  public static getInstance(): MicroAppPiletSingleton {
    return this.instance;
  }

  /**
   * Utility to get Piral instance through the App-shell
   * @returns An instance of a Piral(App-shell)
   */
  public getPiralInstance(): PiralInstance {
    return this.app;
  }
} /// End of :: MicroAppPiletSingleton class

/**
 * A Piral API to prepare and load UX for Pilets
 * @param param The options to use when setting up the Piral instance.
 */
const instance = renderInstance({
  layout,
  errors,
  requestPilets() {
    /// Capture piral instance
    MicroAppPiletSingleton.initInstance(instance);

    const piletInfo = window.sessionStorage.getItem(BROWSER_STORE_PILET_INFO) || "";
    // const piletInfo = "127.0.0.1:8080"
    const microApp: IMicroAppFeature =
      getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []).find((app: IMicroAppFeature) => app.domain === piletInfo) || ({} as IMicroAppFeature);

    if (
      piletInfo !== "" &&
      window.location.pathname !== route("app.shell.default") &&
      window.location.pathname !== route("app.shell.login") &&
      window.location.pathname !== route("app.shell.administration")
    ) {
      const piletFile = microApp.version ? `index.${microApp.version}.js` : `index.js`;
      const piletConfig = {
        name: microApp.microapp,
        version: microApp?.version || "1.0.0",
        link: `https://${piletInfo}/${piletFile}`,
        hash: "",
      }
      if (microApp.piralSchemaVersion) piletConfig["spec"] = microApp.piralSchemaVersion;
      return Promise.resolve([
        piletConfig
      ]);
    }
    else return Promise.resolve([]);
  },
});

/**
 * Set and configure the data to the Pilet-micro app */
if ((window.sessionStorage.getItem(BROWSER_STORE_PILET_INFO) || "") !== "") {

  const microApp: IMicroAppFeature =
    getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []).find(
      (app: IMicroAppFeature) =>
        app.domain === window.sessionStorage.getItem(BROWSER_STORE_PILET_INFO) || ""
    ) || ({} as IMicroAppFeature);
  instance.root.setData("permissions_" + microApp.microapp, microApp);
  instance.root.setData(microApp.microapp, JSON.stringify([microApp]));

  /// Handle redirect events from source pilet to destination
  instance.on(REDIRECT_EVENT, (data: IRedirectEvent) => {
    onHandleEvent(data);
  });
}
// render(<Piral instance={instance} />, document.querySelector('#app')); // does the explicit rendering now


/**
 * Register an event listener for Browser back/forward buttons
 */
window.addEventListener('popstate', (event: any) => {
  const path = event?.path[0]?.window?.location;
  if (path?.pathname) {
    onHandleEvent({ path: path?.pathname } as IRedirectEvent);
  }
}, false);
