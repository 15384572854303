/* eslint-disable import/prefer-default-export */

import { BasePresenter, getSessionData, setSessionData } from "@pentair/react";
import { History } from "history";
import { setUserPermission } from "../../../config/userPermission";
import { BROWSER_STORE_DOMAINS, BROWSER_STORE_FAVORITES, BROWSER_STORE_SHELL_PERMISSIONS, BROWSER_STORE_USER_ID, ERROR_FAV_PAGE_INVALID, LANDING_PAGE_OBJECT_KEY, MICRO_APP_USER_MANAGEMENT, PRODUCT_CATEGORY_MICROAPPS } from "../../../config/constants";
import { IFavorite, IMicroAppFeature } from "../../common/types";
import { MicroAppPiletSingleton } from "../../../default";
import { Auth } from "aws-amplify";
import { NetworkModule } from "../../../application/network";
import { mutationSaveUserFavorite } from "../../common/graphql/mutation";
import { getPermissionToken } from "../../common/graphql/queries";

/**
 * An Admin page state
 */
interface IAdminPageState {
  // List of micro-apps and features
  microAppFeatures: IMicroAppFeature[];
  // QR Code
  qrCode: string;
  // Loading progress state
  isLoading: boolean;
  // A confirm dialog model data
  modelData: IDialogModel;
}

/**
 * Dialog model props
 */
interface IDialogModel {
  /// Message string
  message: string;
  /// Show/hide flag
  show: boolean;
}


/**
 * Class component to build admin page state and logic
 */
export class DefaultAdminPagePresenter extends BasePresenter<IAdminPageState> {

  public history: History;
  public location: any;
  private microAppDetails: IMicroAppFeature[] = []
  private piralInstance: MicroAppPiletSingleton;
  private _defaultDialogModel = {
    message: '',
    show: false,
  };

  public state: IAdminPageState = {
    modelData: this._defaultDialogModel,
    microAppFeatures: [],
    qrCode: "",
    isLoading: false
  };

  /**
  * Constructor
  * @param props Props instance
  */
  constructor(private _network: NetworkModule) {
    super();
  }

  /**
   * Set local state object
   * @param state New state
   */
  public change = (state: Partial<IAdminPageState>) => {
    this.setState({ ...this.state, ...state });
  }

  /**
   * Load micro-apps and their domains
   */
  private loadMicroApps = async () => {
    this.change({ isLoading: true });
    try {
      // await this.updateUserAppPermission();
      this.microAppDetails = JSON.parse(window.sessionStorage.getItem(BROWSER_STORE_DOMAINS) || "{}") || [];
      const microAppFeatures: IMicroAppFeature[] = await this.updateUserAppPermission();
      const productCategoryMicroapps = getSessionData(PRODUCT_CATEGORY_MICROAPPS, []);
      let availableMicroappList: IMicroAppFeature[] = [];
      if (!microAppFeatures.find((x) => x.microapp === "platform-management")) {
        let microapps: string[] = [];
        productCategoryMicroapps.forEach((microapp: any) => {
          microapps = [...microapps, ...microapp?.microapps];
        });
        availableMicroappList = microAppFeatures.filter((x) => microapps.includes(x.microapp));
      } else {
        availableMicroappList = microAppFeatures;
      }
      this.change({ microAppFeatures: availableMicroappList });
    } catch (error) {
      // this.showAcknowledgeMessage(error?.message ? error.message : error, "error");
    } finally {
      this.change({ isLoading: false });
    }
  }

  /**
 * Return user permission in encrypted
 * @param session User Session object
 * @returns Permission in String
 */
  private getUserPermission = async (session: any) => {
    try {
      const response: any = await this._network.fetch(
        getPermissionToken
          .replace("@keyAccountId", session.getIdToken().payload["keyaccountid"])
          .replace("@emailId", session.getIdToken().payload.email),
        {},
        MICRO_APP_USER_MANAGEMENT
      );

      if (!response.errors && response.data.getPermissionToken !== null) {
        return response.data.getPermissionToken;
      }
    } catch (error) { }
    return "";
  }

  /**
 * Update the App permission for the user
 */
  private updateUserAppPermission = async () => {
    try {
      // Update the app permissions
      const session = await Auth.currentSession();
      const { sub } = session.getIdToken().payload;
      window.sessionStorage.setItem(BROWSER_STORE_USER_ID, sub);
      const microApps: IMicroAppFeature[] = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);
      // Check if permission already stored in session
      if (microApps && microApps?.length > 0) {
        return microApps;
      } else {
        // fetch the user permission and Store the data in session
        const permission = await this.getUserPermission(session);
        return setUserPermission(this.microAppDetails, permission, session);
      }
    }
    catch (ex) {
      console.log("error ", ex)
    }
    return [];
  }

  /**
   * Handle landing page error if route not exist or correct
   */
  public onAcceptDialog = async () => {
    this.onCloseDialog();
    try {
      this.change({ isLoading: true, modelData: this._defaultDialogModel });
      const session = await Auth.currentSession();
      const microAppFeatures: IMicroAppFeature[] = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);
      /**
      * Store pilet info in Description
      * This is required when landing page navigation
      */
      const microApp = microAppFeatures.find((app: IMicroAppFeature) => app.microapp === MICRO_APP_USER_MANAGEMENT) || null;
      if (!microApp) {
        return;
      }
      let favorites: IFavorite[] = [{
        key: LANDING_PAGE_OBJECT_KEY,
        value: '',
        description: '',
      }];
      const response: any = await this._network.fetch(mutationSaveUserFavorite, {
        input: {
          userId: session.getIdToken().payload.sub,
          keyAccountId: session.getIdToken().payload["keyaccountid"],
          favourites: favorites,
        }
      },
        MICRO_APP_USER_MANAGEMENT
      );
      if (!response.errors && response.data.saveUserFavourite != null) {
        setSessionData(BROWSER_STORE_FAVORITES, JSON.stringify(favorites));
        this.piralInstance.getPiralInstance().root.setData(ERROR_FAV_PAGE_INVALID, null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.change({ isLoading: false });
    }
  }

  /**
   * Handle landing page error if route not exist or correct
   */
  public onCloseDialog = () => {
    this.change({
      modelData: {
        message: '',
        show: false,
      }
    });
  }

  /**
   * Mount event
   */
  public mount = async () => {
    await this.loadMicroApps();
    this.piralInstance = MicroAppPiletSingleton.getInstance();
    /// Capture Piral instance
    const data = this.piralInstance.getPiralInstance().root.getData(ERROR_FAV_PAGE_INVALID);
    if (data) {
      const modelData: IDialogModel = {
        message: data.message,
        show: true
      }
      this.change({ modelData });
    }
  };

  public unmount = async () => { }
}
