import * as React from "react";
import { InputBaseComponentProps } from "@material-ui/core";
import {
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core";
import { makePresenter } from "../../../application/application";
import { AuthLayout } from "../common/auth-layout";
import { AlertError } from "../../../components/mui-alert/alert";
import { MfaVerificationPresenter } from "./mfa-verification-presenter";
import { useTranslate } from "../../../components/translator/use-translator";
import LoadingIcon from "../../../components/loading-icon";

/**
 * Components style
 */
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
});

/**
 * Component props
 */
interface IOwnProps {
    // Define the callback to login with MFA
    loginWithMFA: (verificationCode: string) => any;
    // Define the callback to navigate backward
    navigateBack: () => void;
    // Alert props, if any
    alert: any;
}

/**
 * An utility to create the presenter instance
 */
const usePresenter = makePresenter(
    () => new MfaVerificationPresenter(),
    (presenter, props: IOwnProps) => {
        presenter.loginWithMFA = props.loginWithMFA;
        presenter.navigateBack = props.navigateBack;
    }
);

/**
 * A reset old password component definition
 */
export const MfaVerification: React.FC<IOwnProps> = (props) => {

    const { state,
        onLoginWithMFA,
        onNavigateBack,
        onKeyPress,
        onChange
    } = usePresenter(props);

    const {
        otp,
        isLoading,
        refArray,
        error
    } = state;

    const classes = useStyles();

    const alert = error.errorMessage ? error : props.alert;

    /**
     * Mui input text field properties
     */
    const inputProps: InputBaseComponentProps = {
        maxLength: 1,
        style: { textAlign: "center" }
    };

    return (
        <AuthLayout
            isBackExists={true}
            onClick={onLoginWithMFA}
            enableLoginButton={false}
            navigateBack={onNavigateBack}
        >
            <div>
                <div className={classes.root}>
                    <Typography>{useTranslate("login:VERIFICATION CODE")}</Typography>
                    <Typography>
                        {useTranslate("login:verificationText")}.
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {otp.map((m) => (
                            <TextField
                                key={m}
                                inputProps={inputProps}
                                onKeyPress={onKeyPress}
                                onChange={onChange}
                                name={m.toString()}
                                inputRef={(el) => {
                                    refArray[m] = el;
                                }}
                                style={{ margin: 4, width: 36 }}
                            />
                        ))}
                    </div>
                    <AlertError
                        text={alert.errorMessage}
                        enableError={alert.showError}
                    />
                </div>
            </div>
            {isLoading ? <LoadingIcon /> : null}
        </AuthLayout>
    )

}
