/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/self-closing-comp */
import { getSessionData } from "@pentair/react";
import * as React from "react";
import {
  Route,
  Redirect,
  matchPath,
  RouteProps,
} from "react-router-dom";
import {
  BROWSER_STORE_FAVORITES,
  BROWSER_STORE_IS_AUTHENTICATED,
  BROWSER_STORE_IS_MENU_HIDDEN,
  BROWSER_STORE_REDIRECT_TO,
  BROWSER_STORE_SHELL_PERMISSIONS,
  LANDING_PAGE_OBJECT_KEY,
} from "./config/constants";
import { route } from "./config/routes";
import { PiletLayout } from "./features/common/layouts/pilet-layout";
import { IFavorite, IMicroAppFeature } from "./features/common/types";

export const SecureRoute = (props: any) => {
  const { component: Component, ...rest } = props;

  return (
    <Route

      {...rest}
      render={() => (
        (window.sessionStorage.getItem(BROWSER_STORE_IS_AUTHENTICATED) || "false") === "true"
          ? (<PiletLayout children={<Component {...props} />}></PiletLayout>)
          : (
            <Redirect
              to={{
                pathname: route("app.shell.login"),
                state: { from: props.location },
              }}
            />
          ))}
    />
  );
};

interface IOwnProps {
  Component: any;
}

/**
 * This method will iterate through available micro app and check micro app path is present
 * @param path current path
 * @returns return micro app details for current path argument passed.
 */
const isMicroAppPathMatched = (path: string) => {
  const microApps: IMicroAppFeature[] = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);
  const matched = microApps.filter((microApp: IMicroAppFeature) => {
    const routeProp = { path: `/${microApp.microapp}/**`, exact: true } as RouteProps;
    const match = matchPath(path, routeProp);
    return match;
  });
  return matched && matched.length > 0;
};

/**
 * This method returns default path ie favorite or administration
 * @returns path name
 */
const getDefaultPath = () => {
  let redirectTo = route("app.shell.administration");
  const favorites = getSessionData(BROWSER_STORE_FAVORITES, []) as IFavorite[];
  if (favorites && favorites.length) {
    const favoritesArr: IFavorite[] = favorites;
    const landingPage = favoritesArr?.find((fav: IFavorite) => fav.key === LANDING_PAGE_OBJECT_KEY);
    if (landingPage) {
      const favoriteRoute = JSON.parse(landingPage.value);
      if (favoriteRoute?.pathname) {
        const pathMatched = isMicroAppPathMatched(favoriteRoute.pathname);
        if (pathMatched) {
          redirectTo = favoriteRoute.pathname;
        }
      }
    }
  }
  return redirectTo;
};

/**
 * Route mapping for pilet routes
 * @param paths will contain all routs having access Permission
 * @returns Route component
 */
export const SecurePiletRoute = ({ Component }: IOwnProps):JSX.Element => (
  <Route
    strict={false}
    component={() => {
      // Checking user is authenticated
      if ((window.sessionStorage.getItem(BROWSER_STORE_IS_AUTHENTICATED) || "false") === "true") {
        if (window.location.pathname !== "/dashboard") {
          // Check any matching rout configured for the path
          const pathMatched = isMicroAppPathMatched(window.location.pathname);
          if (pathMatched) {
            return (Component);
          }

          const redirectTo = getDefaultPath();
          return (
            <Route path="*">
              <Redirect to={redirectTo} />
            </Route>
          );
        }
      }
      const query = new URLSearchParams(window.location.search);
      const userName: string | undefined = query.get("username") || undefined;
      const password: string | undefined = query.get("password") || undefined;
      const hideMenu: string | undefined = query.get("hide-menus") || undefined;
      const autoLogin = userName && password;
      // Invoke auto-login execution if autoLogin=TRUE
      if (autoLogin) {
        window.sessionStorage.setItem(BROWSER_STORE_REDIRECT_TO, window.location.pathname);
        window.sessionStorage.setItem(BROWSER_STORE_IS_MENU_HIDDEN, hideMenu || "false");
        window.sessionStorage.setItem(BROWSER_STORE_IS_AUTHENTICATED, "false");
        window.location.href = `${route("app.shell.login")}?${query.toString()}`;
        return (<Redirect to={{ pathname: `/?${query.toString()}` }} />);
      }
      return (
        <Redirect
          to={{
            pathname: route("app.shell.login"),
          }}
        />
      );
    }}

  />
);
