import * as React from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    makeStyles
} from "@material-ui/core";
import {
    Application,
    makePresenter
} from "../../../application/application";
import {
    PageTitles,
    PentairBlue,
    PentairBrightBlue,
} from "@pentair/react";
import { ResetPasswordPresenter } from "./reset-password-presenter";
import { PasswordField } from "../../../components/password";
import { AlertError } from "../../../components/mui-alert/alert";
import { BackButton } from "../../../components/back-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslate } from "../../../components/translator/use-translator";
import LoadingIcon from "../../../components/loading-icon";

/**
 * Component styles
 */
const useStyles = makeStyles({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url("../../images/background.png")`,
        backgroundSize: "cover",
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
    },
    cardLayout: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    gridLayout: {
        padding: 19,
        maxWidth: 840,
    },
    gridChildren: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        paddingBottom: 0,
    },
    cardFooter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    footer: {
        color: "#fff",
        font: "Barlow SemiBold",
        marginBottom: 0,
    },
    footerBackground: {
        backgroundColor: PentairBlue[500],
    },
    footerAlign: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 60,
    },
    footerWidth: {
        maxWidth: "90%",
        textAlign: "center",
    },
    icon: {
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        }
    }
});

/**
 * Component props
 */
interface IOwnProps {
    // Update the user the identity password
    completeNewPassword: (newPassword: string, termsAndConditionAccepted: string, newsSubscriptionAccepted: string) => any;
    // On logout action callback
    onLogout: () => void;
    // Alert props, if any
    alert: any;
    // Get the flag to see the T&C checkboxes
    showTnCCheckboxes: boolean;
}

/**
 * An utility to create the presenter instance
 */
const usePresenter = makePresenter(
    ({ network }: Application) => new ResetPasswordPresenter(network),
    (presenter, props: IOwnProps) => {
        presenter.completeNewPassword = props.completeNewPassword;
    }
);

/**
 * A reset password component definition
 */
export const ResetPassword: React.FC<IOwnProps> = (props) => {

    const { state,
        onChange,
        onKeyPress,
        onValueChange,
        onContinueClick,
    } = usePresenter(props);

    const {
        error,
        isLoading,
        termsAndConditionAccepted,
        newsSubscriptionAccepted,
        disableResetPassword
    } = state;

    const classes = useStyles();

    const alert = error.errorMessage ? error : props.alert;
    const email = useTranslate("login:pentairq.support@pentair.com")

    return (
        <div className={classes.root}>
            <Card className={classes.cardLayout}>
                <CardContent>
                    <Grid container spacing={3} className={classes.gridLayout}>
                        <Grid item xs={12} md className={classes.gridChildren}>
                            <div>
                                <PageTitles title={useTranslate("login:Password").toLocaleUpperCase()} subtitle={useTranslate("login:RESET REQUIRED")} />
                            </div>
                            <div>
                                <PasswordField
                                    fullWidth
                                    margin="normal"
                                    placeholder={useTranslate("login:Enter new password")}
                                    name="newPassword"
                                    id="newPassword"
                                    onChange={onChange}
                                    onKeyPress={onKeyPress}
                                    label={<div>{useTranslate("login:New Password")}</div>}
                                />
                                <PasswordField
                                    fullWidth
                                    margin="normal"
                                    placeholder={useTranslate("login:Enter password again")}
                                    id="password"
                                    name="confirmPassword"
                                    onKeyPress={onKeyPress}
                                    onChange={onChange}
                                    label={<div>{useTranslate("login:Confirm New Password")}</div>}
                                />
                                {!props.showTnCCheckboxes && <><FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(_event) => onValueChange(_event)}
                                            name={"termsAndConditionAccepted"}
                                            color="primary"
                                            value={termsAndConditionAccepted?.length}
                                            icon={<span className={classes.icon} />}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body2">
                                            {useTranslate("login:termsAndCondition")}&nbsp;
                                            <a
                                                style={{
                                                    color: PentairBrightBlue[500],
                                                    textDecoration: "none",
                                                    fontWeight: 600,
                                                }}
                                                target="_blank"
                                                href="../../../documents/terms-of-service.pdf"
                                            >{useTranslate("login:Terms of Service")} *</a>
                                        </Typography>
                                    } />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(_event) => onValueChange(_event)}
                                                name={"newsSubscriptionAccepted"}
                                                value={newsSubscriptionAccepted?.length}
                                                color="primary"
                                                icon={<span className={classes.icon} />}
                                            />
                                        }
                                        label={<Typography
                                            variant="body2">
                                            {useTranslate("login:newsSubscription")}
                                        </Typography>
                                        }
                                    />
                                </>}
                            </div>
                            <div>
                                <AlertError
                                    text={alert.errorMessage}
                                    enableError={alert.showError}
                                />
                            </div>
                            <div className={classes.cardFooter}>
                                <BackButton text={useTranslate("login:LOGOUT")} onClick={props.onLogout} />
                                <Button
                                    disabled={disableResetPassword}
                                    size="small" onClick={onContinueClick}
                                >
                                    {useTranslate("login:Continue")}
                                    <FontAwesomeIcon
                                        icon={["fas", "long-arrow-right"]}
                                        style={{ marginLeft: 6 }}
                                    />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <Grid container>
                    <Grid item xs={12} md={12} className={classes.footerBackground}>
                        <div className={classes.footerAlign}>
                            <div className={classes.footerWidth}>
                                <Typography
                                    paragraph
                                    variant="body2"
                                    className={classes.footer}
                                >
                                    {useTranslate("login:Please contact")}
                                     &nbsp;
                                    <span>
                                        <a style={{ color: "#D0DF00" }} href={`Mailto:${email}`}>{email}</a>
                                    </span> &nbsp;
                                    {useTranslate("login:in case you need support")}.
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Card>
            {isLoading > 0 ? <LoadingIcon /> : null}
        </div>
    );
}