import { setupPresenter } from "@pentair/react";
import { NetworkModule } from "./network";
import { MICRO_APP_NAME } from "../config/constants";

export interface Resource {}

/**
 * Product type providers
 */
interface Providers {}

/**
 * Build application
 */
export class Application {
  // application props
  public name = MICRO_APP_NAME;

  public providers: Providers;

  public network = new NetworkModule();

  /**
   * Application constructor
   */
  constructor() {}
}

/**
 * Export the Application instance
 */
export const application = new Application();
export const { makePresenter } = setupPresenter<Application>(application);
