import * as React from "react";
import {
    ListItem,
    ListItemText,
    makeStyles,
    StyleRules,
    Typography,
} from "@material-ui/core";
import { onNavigate } from "../../../config/routes";
import { useTranslate } from "../../../components/translator/use-translator";
import { PentairTheme } from "@pentair/react";

/**
 * Component UX styles
 */
 const useStyles = makeStyles((theme: PentairTheme): StyleRules => ({
    listItem: {
        "&:hover": {
            backgroundColor: "transparent !important",
            color: theme.palette.brightBlue.main,
            borderBottomColor: theme.palette.brightBlue.main,
        },
    },
    listItemSelected: {
        backgroundColor: "transparent !important",
        color: theme.palette.brightBlue.main,
        borderBottomColor: theme.palette.brightBlue.main,
    },
    listItemPrimary: {
        fontWeight: 600,
    },
    listItemSecondary: {
        fontWeight: 400,
        fontSize: "14px",
    }
}));

/**
 * To view the feature as a list item on admin dashboard.
 * @param props 
 * @returns component
 */
export const FeatureListItem: React.FC<any> = (props) => {
    // Access state constants
    const {
        microAppFeature,
        feature,
    } = props;

    // Style object
    const classes = useStyles();
    const title = useTranslate(`${microAppFeature.microapp}:${feature.title}`, microAppFeature.microapp);
    const subtitle = useTranslate(`${microAppFeature.microapp}:${feature.subtitle}`, microAppFeature.microapp);

    return (
        <ListItem
            button
            divider
            key={`key-featureIndex-${feature.order}`}
            disableGutters
            onClick={() => {
                onNavigate(
                    microAppFeature.microapp,
                    feature.path,
                    microAppFeature.domain
                );
            }}
            className={classes.listItem}
        >
            <ListItemText
                classes={{ primary: classes.listItemPrimary }}
                primary={title}
                secondary={
                    <Typography className={classes.listItemSecondary}>
                        {subtitle}
                    </Typography>
                }
            />
        </ListItem>
    );
}