import * as React from "react";
import { RouteComponentProps } from "react-router";
import {
    TextField,
    Typography
} from "@material-ui/core";
import { AuthLayout } from "../../common/auth-layout";
import { AlertError } from "../../../../components/mui-alert/alert";
import {
    Application,
    makePresenter
} from "../../../../application/application";
import { ForgetPasswordPresenter } from "./forget-password-presenter";
import { useTranslate } from "../../../../components/translator/use-translator";
import LoadingIcon from "../../../../components/loading-icon";

/**
 * An utility to create the presenter instance
 */
const usePresenter = makePresenter(
    ({ network }: Application) => new ForgetPasswordPresenter(network),
    (presenter, props: RouteComponentProps) => {
        presenter.history = props.history;
        presenter.location = props.location;
    }
);

/**
 * A forgot password component
 */
export const ForgetPassword: React.FC<RouteComponentProps> = (props) => {

    const { state,
        onKeyPress,
        onSendCode,
        onChange,
        onNavigateBack,
        onChangePassword
    } = usePresenter(props);

    const {
        isLoading,
        error,
        disable
    } = state;

    return (
        <AuthLayout
            isBackExists={true}
            onClick={onSendCode}
            enableLoginButton={disable}
            navigateBack={onNavigateBack}
            buttonText={useTranslate("login:Send Code")}
            changePassword={true}
            onChangePassword={onChangePassword}
        >
            <div>
                <Typography variant={"subtitle2"}>{useTranslate("login:RESET PASSWORD")}</Typography>
                <br />
                <TextField
                    fullWidth
                    margin="normal"
                    placeholder={useTranslate("login:User Name")}
                    id="userName"
                    name="userName"
                    disabled={disable}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    label={<div>{useTranslate("login:User Name")}</div>}
                />
            </div>
            <br />
            <div>
                <AlertError
                    text={error.errorMessage}
                    enableError={error.showError}
                />
            </div>
            {isLoading ? <LoadingIcon /> : null}
        </AuthLayout>
    );
}