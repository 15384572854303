import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import { AlertError } from "../../../../components/mui-alert/alert";
import { PageTitles } from "@pentair/react";
import { useTranslate } from "../../../../components/translator/use-translator";

interface OwnProps {
  open: boolean;
  qrCode: string;
  onCloseQRCode: () => void;
  token: string;
  alert: any;
  onChange: (event: any) => void;
  qrSecret: string;
}

/**
 * Qr code generator
 * @param param0
 */
export const QRCodeDialog = ({
  open,
  qrCode,
  onCloseQRCode,
  token,
  onChange,
  alert,
  qrSecret,
}: OwnProps) => {
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open}>
      <DialogTitle id="confirmation-dialog-title">
        <PageTitles title={"Manage"} subtitle={"MFA Device"} />
      </DialogTitle>
      <DialogContent>
        <Typography paragraph variant="body1">
          {useTranslate("login:message2")}
        </Typography>
        <div
          style={{
            padding: 24,
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {qrCode === "" ? null : <QRCode value={qrCode} />}
        </div>
        <Typography paragraph variant="body1">
          {useTranslate("login:message3")}
        </Typography>

        <TextField
          fullWidth
          placeholder={useTranslate("login:Code")}
          margin="normal"
          value={token}
          name="token"
          onChange={onChange}
          label={<div>{useTranslate("login:Code")}</div>}
        />
      </DialogContent>
      <AlertError
        text={alert.errorMessage}
        enableError={alert.showError}
      />
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          onClick={onCloseQRCode}
          disabled={token.length === 0}
        >
          {useTranslate("login:Verify")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
