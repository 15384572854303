/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import { decryptUtils, setSessionData } from "@pentair/react";
import { ICategoryFeature, IFeature, IMicroAppFeature } from "../features/common/types";
import {
  BROWSER_STORE_POOL_ID,
  BROWSER_STORE_SHELL_PERMISSIONS,
  BROWSER_STORE_USER_ID,
  ROLE_CATEGORY,
} from "./constants";
import { CognitoUserSession } from "amazon-cognito-identity-js";


/**
 * Gets and sets the user-permissions
 * @param microApps
 * @returns
 */
export function setUserPermission(
  microApps: IMicroAppFeature[],
  permission: string,
  session: CognitoUserSession
) {
  const microAppFeatures: IMicroAppFeature[] = [];
  if (session) {
    /** reading permission from jwt token */
    let userPermissions: ICategoryFeature = {} as ICategoryFeature;
    const { sub } = session.idToken.payload;
    /** set the userId in session */
    window.sessionStorage.setItem(BROWSER_STORE_USER_ID, sub);
    const iv = window.sessionStorage.getItem(BROWSER_STORE_POOL_ID) || "";
    if (permission) {
      const strPermission = decryptUtils(permission, sub, iv, false, true);
      userPermissions = JSON.parse(strPermission) as ICategoryFeature;
    }
    try {
      /**
       * Filtering micro apps based on user permissions for micro app metadata for micro app features
       */
      userPermissions?.features?.sort(
        (a: IMicroAppFeature, b: IMicroAppFeature) => Number(a.order) - Number(b.order),
      )
        ?.forEach((app: IMicroAppFeature) => {
          const microApp = microApps?.find(
            (f: IMicroAppFeature) => f.microapp.trim() === app.microapp.trim() && app.domain != "",
          );
          if (microApp) {
            const features: IFeature[] = [];
            app.features?.forEach((feature: IFeature) => {
              /// Find feature and add to the list
              const nestedFeature: IFeature = microApp.features?.find(
                (fd: IFeature) => fd.name && fd.name.trim() === feature.name.trim(),
              ) || ({} as IFeature);
              if (nestedFeature.name) {
                nestedFeature.permissions = feature.permissions;
                features.push({ ...nestedFeature });
              } else if (feature.name) {
                const newFeature: IFeature = {
                  id: feature.id,
                  name: feature.name.trim(),
                  routeAlias: "",
                  isDefaultPath: "false",
                  order: (features.length + 1).toString(),
                  path: "",
                  permissions: feature.permissions,
                  subtitle: feature.name.trim(),
                  title: feature.name.trim(),
                  icon: feature.icon,
                  displayInToolbar: false,
                  piralSchemaVersion: feature.piralSchemaVersion,
                };
                features.push({ ...newFeature });
              }
            });
            features.sort(
              (a: IFeature, b: IFeature) => Number(a.order) - Number(b.order),
            );
            microAppFeatures.push({
              microapp: microApp.microapp.trim(),
              title: microApp.title,
              features,
              appsync: microApp.appsync,
              domain: microApp.domain,
              icon: microApp.icon,
              order: microApp.order,
              displayInToolbar: microApp.displayInToolbar,
              version: microApp.version,
              productCategoryId: app.productCategoryId,
              piralSchemaVersion: microApp.piralSchemaVersion,
            });
          }
        });
    } catch (ex) {
      console.log(ex);
    }
    setSessionData(BROWSER_STORE_SHELL_PERMISSIONS,
      JSON.stringify(microAppFeatures),
    );
    window.sessionStorage.setItem(ROLE_CATEGORY,
      JSON.stringify(userPermissions.productCategoryId));
  }
  return microAppFeatures;
}
