import i18n from "i18next";
import Fetch from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
const packageConfig = require("/package.json");

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Fetch)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: (lng: string, ns: string) => getPath(lng, ns)
      //loadPath:`/locales/{{lng}}/{{ns}}.json`
    },
    detection: { order: ['sessionStorage', 'navigator'], caches: ['sessionStorage'] },
    whitelist: ['en', 'de', 'fr', 'es'],// available lang english, german, french, spanish
    fallbackLng: "en", // use en if detected lang is not available
    debug: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });


/**
 * Get locale resources path
 * @returns path string
 */
const getPath = (lng: string, ns: string) => {
  const domain = getDomainPath(ns);
  let localeUri = "";
  if (domain) {
    localeUri = `https://${domain}`;
  }
  localeUri = localeUri + `/locales/${lng}/${ns}.json?var=${packageConfig.piralversion}`;
  return localeUri;
}

/**
 * Get the domain path for name-spaces
 * @param microApp 
 * @returns URI
 */
const getDomainPath = (microApp: string): string => {
  let dashboardDomain;
  try {
    const domains = JSON.parse(window.sessionStorage.getItem("domains") || "") || [];
    dashboardDomain = domains?.find((elem: any) => elem?.microapp?.toString()?.toLowerCase() === microApp.toLowerCase());
  }
  catch {
    console.warn("No domains present in session store while reading translation file");
  }
  return dashboardDomain?.domain || "";
}

export default i18n;
