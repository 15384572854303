/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { IconName } from "@fortawesome/pro-light-svg-icons";

import {
  IFeature,
  IMicroAppFeature,
} from "../types";
import { onNavigate } from "../../../config/routes";
import { IconButton } from "../../../components/mui-extended/mui-icon-button";
import { Tooltip } from "./tooltip";
/**
 * Component props
 */
interface IProps {
  features: IFeature[];
  microApp: IMicroAppFeature;
}

/**
 * Left Navigation tool bar dock icon component.
 */
export const ToolBarItem: React.FC<IProps> = (props) => {
  // Access state constants
  const {
    features,
    microApp,
  } = props;

  return (
    <>
      {features.map((feature: IFeature, index: any) => (
        <Tooltip title={feature.title} placement="right" arrow>
          <nav>
            <IconButton
              variant="primary"
              icon={(feature.icon || microApp.icon) as IconName}
              key={feature.name + index}
              onClick={() => onNavigate(
                microApp.microapp,
                feature.path,
                microApp.domain,
              )}
            />
          </nav>
        </Tooltip>
      ))}
    </>
  );
};
