import * as React from "react";
import { RouteComponentProps } from "react-router";
import { checkPageInMobile } from "../../../../utils/commonUtils";
import { CALLBACK_SET_TOKEN, IS_MOBILE_LOGIN, MOBILE_FROM_MESSAGE, MOBILE_TO_MESSAGE } from "../../../../config/constants";
import LoadingIcon from "../../../../components/loading-icon";
import { useEffect, useState } from "react";
import { PageTitles } from "@pentair/react";
import { ExternalAuth, assignTokenToSession } from "../../external-auth-utils";
import User from "../../user";
import { defaultUserLogin } from "../../default-login";
import { determineLandingPage } from "../../../../utils/route-util";

/**
 * A component to create the login UX
 */
export const LoginMobile: React.FC = () => {
  const secretKey = process.env.MOBILE_APP_SECRET_KEY || "";
  window.localStorage.setItem(IS_MOBILE_LOGIN, `${checkPageInMobile()}`);
  const externalAuth = new ExternalAuth();
  const [isLoading, setIsLoading] = useState(true);

  /** */
  const initializeTokenLogin = async () => {
    await defaultUserLogin();
    try {
      const tokens = await externalAuth.getExternalAuth({ callback: CALLBACK_SET_TOKEN });
      if (!tokens) return;
      const { idToken, accessToken, refreshToken } = tokens

      if (!idToken || !accessToken || !refreshToken) return;
      const session = await assignTokenToSession(idToken, accessToken, refreshToken);

      if (session && session.isValid()) {
        console.time("afterToken")
        const userObj = User.tokenLogin(session);
        const result = await userObj.finishedLoginProcess;
        console.timeEnd("afterToken")
        const navigationUrl = determineLandingPage();

        window.location.href = navigationUrl.toLocaleLowerCase();
      }
    }
    catch (error) {
      console.error("Token login error", error);
    }
  }

  useEffect(() => {
    initializeTokenLogin();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener(CALLBACK_SET_TOKEN, () => { });
    };
  }, []);


  return (
    <>
      <PageTitles title={'Loading ...'} />
      {isLoading ? <LoadingIcon /> : null}
    </>

  );
}
