import { isExternal } from "../config/data";
import { getRouteKeyAccount } from "./routeKeyAccount";
import { routeMap } from "../config/routes";
import { BROWSER_STORE_PILET_INFO, LANDING_PAGE_OBJECT_KEY, MICRO_APP_DASHBOARDS } from "../config/constants";
import User from "../features/auth/user";
import { Location } from "history";

/**
 * New method extracted from login presenter.ts. 
 * Now work for operations landing page and administration page.
 * TODO: extract all logic from login presenter to here. and reduce login page size
 * @returns landing page url for the logged user
 */
export const determineLandingPage = () => {
  const user = User.getInstance();

  let redirectPath = routeMap["app.shell.login"].path;
  let microAppName = "";
  // Check logged in environment
  if (!user?.session?.isValid()) return redirectPath;
  redirectPath = routeMap["app.shell.administration"].path;
  const routeKeyAccount = getRouteKeyAccount(user.session);
  const routeKeyAccountID = routeKeyAccount?.id || routeKeyAccount;
  if (isExternal() && routeKeyAccountID) {
    const dashboardPath = routeMap["app.mobile.dashboard"].path;
    redirectPath = `${dashboardPath}/${routeKeyAccountID}`;
    // window.sessionStorage.setItem(BROWSER_STORE_PILET_INFO, domain);
    microAppName = MICRO_APP_DASHBOARDS;
  }
  // Check if the user has a favorite page saved
  else if (user.favorites && user.favorites.length) {
    const favLandingObj = user.favorites?.find(
      (fav: any) => fav.key === LANDING_PAGE_OBJECT_KEY,
    );
    if (favLandingObj) {
      const routeObject: Location = JSON.parse(favLandingObj.value || "");
      redirectPath = routeObject?.pathname || "";
      microAppName = favLandingObj.description;
    }
  }
  const domain = user.permissions?.find(
    (app: IMicroAppFeature) => app.microapp === microAppName,
  )?.domain;
  if (domain) {
    window.sessionStorage.setItem(BROWSER_STORE_PILET_INFO, domain);
  }
  return redirectPath.toLocaleLowerCase();
};

