/**
 * Fetch the User permission token appsync query
 */
export const getPermissionToken = `
 query getPermissionToken {
    getPermissionToken(emailId: "@emailId", keyAccountId: "@keyAccountId")
 }
`;

/**
 * get Product Category micro apps 
 */
 export const QueryProductCategory = `query getProductCategoryMicroappDetails{
   getProductCategoryMicroappDetails{
     productCategoryId
     microapps
   }
 }`;
