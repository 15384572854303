import { BasePresenter } from "@pentair/react";
import { History } from "history";

/**
 * Component state
 */
interface IComponentState {
    // A OTP state
    otp: number[];
    // A ref array state
    refArray: any[];
    // A string state
    codes: string[];
    // An error state
    error: any;
    // Enable or disable state
    disable: boolean;
    // A flag to control show/hide for confirmation dialog
    isLoading: boolean;
}

/**
 * Class component to handle reset password
 */
export class MfaVerificationPresenter extends BasePresenter<IComponentState> {

    public history: History;
    public location: any;

    public loginWithMFA: (verificationCode: string) => any;
    public navigateBack: () => void;

    public state: IComponentState = {
        isLoading: false,
        disable: false,
        otp: [0, 1, 2, 3, 4, 5],
        codes: ["", "", "", "", "", ""],
        refArray: [],
        error: {
            errorMessage: "",
            showError: false
        }
    }

    /**
     * Set local state object
     * @param state New state
     */
    public change = (state: Partial<IComponentState>) => {
        this.setState({ ...this.state, ...state });
    }

    /**
     * Mount event
     */
    public mount = async () => {
        this.setFocus(-1);
    }

    /**
     * On browser back navigation callback
     */
    public onNavigateBack = () => {
        this.navigateBack();
    }

    /**
     * Validating user mfa
     */
    public onLoginWithMFA = async() => {
        /**
         * Validating all mfa keys are entered
         */
        try {
            this.change({ isLoading: true });
            if (this.state.codes.filter((code) => code === "").length <= 0) {
                this.setErrorMessage("", false);
                this.change({ disable: true });
                this.loginWithMFA(this.state.codes.join(""));
            } else {
                this.setErrorMessage("Invalid verification code", true);
                this.change({ disable: false });
            }
        } catch (error) {
            this.setErrorMessage("Invalid verification code", true);
            this.change({ disable: false });
        } finally {
            this.change({ isLoading: false });
        }
    }

    /**
     * Set error message state to hide on show message on UX
     * @param errorMessage An error string
     * @param enable Show or hide error
     */
    private setErrorMessage = (errorMessage: string, enable: boolean) => {
        const error = {
            ...this.state.error,
        };
        error.showError = enable;
        error.errorMessage = errorMessage;
        this.change({ error });
    }

    /**
     * Set state of input target on change action callback
     * @param event target element
     */
    public onChange = (_event: any) => {
        const codes = [...this.state.codes];
        codes[Number(_event.target.name)] = _event.target.value;
        this.change({ codes });
        this.setFocus(Number(_event.target.name));
    }

    /**
     * On <ENTER> key press action callback
     * @param _event A key event
     */
    public onKeyPress = (_event: any) => {
        // Validate the <ENTER> key code on key press
        if (_event.charCode === 13) {
            this.onLoginWithMFA();
        }
    }

    /**
     * Set focus to component
     * @param _index index 
     */
    public setFocus = (_index: number) => {
        if (_index <= 4) {
            (this.state.refArray[Number(_index) + 1] as HTMLInputElement).focus();
        }
    }

    /**
     * Un-mount event
     */
    public unmount = async () => { }
}