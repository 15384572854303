import { Back, TimelineMax, TweenMax } from "gsap";
import * as React from "react";

export default class LoadingIcon extends React.Component<
  { scale?: number },
  {}
> {
  private element: any;

  private timeline: any;

  private setSVGElement = () => (ref: any) => {
    this.element = ref;
  };

  public componentDidMount = () => {
    const elements = this.element.querySelectorAll("polygon");
    this.timeline = new TimelineMax({ repeat: -1 });
    this.timeline.add(
      TweenMax.staggerFrom(
        elements,
        0.7,
        {
          opacity: 0,
          x: -50,
          ease: Back.easeOut,
        },
        0.1,
      ),
    );

    this.timeline.add(
      TweenMax.staggerTo(
        elements,
        0.7,
        {
          opacity: 0,
          x: 50,
          ease: Back.easeIn,
        },
        0.1,
      ),
    );
  };

  public componentWillUnmount = () => {
    this.timeline.clear();
  };

  public render() {
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2,
          cursor: "pointer",
        }}
      >
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              transform: `scale(${this.props.scale || 0.66})`,
            }}
          >
            <svg
              width="100px"
              height="100px"
              style={{ margin: "25px", marginRight: 0 }}
              viewBox="0 0 250 250"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              ref={this.setSVGElement()}
            >
              <title>Artboard</title>
              <desc>Created with Sketch.</desc>
              <defs />
              <g
                id="Artboard"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="triangle_5"
                  fill="#0C3471"
                  points="88 67.74453 108.18479 47.55974 108.18479 87.925 128.36527 67.74453 128.36527 108.1098 108.18479 87.925 108.18479 128.29027 88 108.1098 88 166.69019 166.76951 87.925 88 9"
                />
                <polygon
                  id="triangle_4"
                  fill="#64A70B"
                  points="68 48 68 87.8417969 87.7460938 67.9208984"
                />
                <polygon
                  id="triangle_3"
                  fill="#64A70B"
                  points="68 88 68 127.841797 87.7460938 107.920898"
                />
                <polygon
                  id="triangle_2"
                  fill="#64A70B"
                  points="88 68 88 107.841797 107.746094 87.9208984"
                />
                <polygon
                  id="triangle_1"
                  fill="#64A70B"
                  points="48 68 48 107.841797 67.7460938 87.9208984"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
