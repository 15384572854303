import React, { useEffect, useState } from "react";
import { Drawer, Button, TextField, makeStyles, StyleRules, Chip, Card, CardActions, CardContent, Typography, CardHeader, Avatar, IconButton, Snackbar } from "@material-ui/core";
import { getSessionData, PentairTheme, useSnackbar } from "@pentair/react";
import { useTranslate } from "../../components/translator/use-translator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faCommentAltEdit } from "@fortawesome/pro-light-svg-icons";
import { BROWSER_STORE_SHELL_PERMISSIONS, BROWSER_STORE_USER_ID, FAQ_PAGE_PATH, FEED_BACK_CATEGORY, MICRO_APP_DASHBOARDS, MICRO_APP_USER_MANAGEMENT, UPLOAD_WHITE_LIST } from "../../config/constants";
import { useAPIOnDemand } from "../../hooks/api-hook";
import { handleFeedbackForm } from "./graphql/mutation";
import { InputFeedbackForm } from "../../application/types";
import { onNavigate } from "../../config/routes";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme: PentairTheme): StyleRules => ({
  categoryPaper: {

  },
  feedbackDrawer: {
    padding: theme.spacing(1.8),
    color: "#fff",
    minWidth: 400,
    minHeight: 500,
    maxWidth: 600,
    height: "auto",

    maxHeight: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    position: "fixed",
    right: 0,
    top: "15% !important",
    border: "10px solid #ececec",
    resize: "horizontal"
  },
  floatingButton: {
    position: "fixed",
    top: "50%",
    right: 0,
    transform: "translateY(-50%) rotate(180deg)",
    backgroundColor: "#333",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#555",
    },
    height: "150px", // Adjust height to make it vertical
    width: "30px", // Adjust width to make it vertical
    borderRadius: "0 4px 4px 0",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    writingMode: "vertical-rl", // This makes the text vertical
    textOrientation: "revert", // This ensures the text remains upright
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    cursor: "pointer",
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  actionCard: {
    marginBottom: theme.spacing(1),
    width: "100%",
    color: "#000",
  },
  cardHeader: {
    padding: 0, //theme.spacing(1),
    width: "100%"
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  fileChipContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
    overflowY: "auto",
    minHeight: theme.spacing(3),
    height: theme.spacing(3),
    flexWrap: "wrap",
  },
  input: {
    display: "none",
  },
  chip: {
    width: 120,
    borderRadius: 5,
    margin: 1,
  }

}));

const categoryDetail = {
  [FEED_BACK_CATEGORY.bug]: {
    title: "Report an issue",
    avatar: "I",
    description: "Encountered a problem or something not working as expected?",
  },
  [FEED_BACK_CATEGORY.feature]: {
    title: "REQUEST A FEATURE",
    avatar: "F",
    description: "Have an idea that can make Pentair Q better?",
  }
}
const userId = window.sessionStorage.getItem(BROWSER_STORE_USER_ID, "");

const FeedbackDrawer: React.FC = () => {
  const microAppFeatures = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);
  const faqAppDomain = microAppFeatures.find((microApp) => microApp.microapp === MICRO_APP_DASHBOARDS)?.domain
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState<InputFeedbackForm>({
    userId: userId || "",
    category: "",
    title: "",
    email: "",
    firstName: "",
    lastName: "",
    details: "",
    microapp: window.location.pathname,
    attachments: [],
  });

  const [queryMutation, queryResult, isLoadingMutation, errorMutation] = useAPIOnDemand<any>(
    null,
    false,
  );
  const { showSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({
    category: "",
    title: "",
    email: "",
    firstName: "",
    lastName: "",
    details: "",
    microapp: "",
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const readMultipleFile = (file: FileList) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.onload = function () {
        resolve({ name: file.name, data: reader.result });
      };
      reader.onerror = function (err) {
        showSnackbar("Error occurred", "error");
        reject(reader);
      };
      reader.readAsDataURL(file);
    });

  }

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let readerPromise = []
    if (e.target.files && e.target.files.length) {
      for (let i = 0; i < e.target.files.length; i++) {
        readerPromise.push(readMultipleFile(e.target.files[i]))
      };

      const attachments = form.attachments;
      Promise.all(readerPromise).then((readers, file) => {
        readers.forEach(reader => {
          const attachmentName = reader.name;
          const attachmentData = reader.data.split(",")[1];
          attachments.push({ attachmentName, attachmentData })
        });
        setForm(prev => ({ ...prev, attachments }));
      }).catch(ex => showSnackbar("Error occurred during file upload!", "error"));
    }
  }

  const onFileDelete = (index: number) => {
    const attachments = form.attachments;
    attachments.splice(index, 1)
    setForm((prev) => ({ ...prev, attachments }));
    showSnackbar("File removed !", "info")
  }

  const validate = () => {
    let tempErrors = { ...errors };
    tempErrors.title = form.title ? "" : "Title is required";
    tempErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email) ? "" : "Email is not valid";
    tempErrors.details = form.details ? "" : "Details are required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };
  const clearForm = () => {
    setForm({
      category: "",
      title: "",
      email: "",
      firstName: "",
      lastName: "",
      details: "",
      microapp: "",
      attachments: [],
    });
  }
  const handleSubmit = () => {
    if (validate()) {
      const input = { input: form };
      queryMutation(handleFeedbackForm, input, MICRO_APP_USER_MANAGEMENT)
      clearForm();
      setIsOpen(false);
    } else {
      showSnackbar("Check the feed back form!")
    }
  };

  const setCategory = async (value: string) => {
    const user = await Auth.currentAuthenticatedUser();
    const { email, family_name, given_name } = user.attributes;
    setForm((prev) => ({
      ...prev,
      category: value,
      email: email || "",
      firstName: given_name || "",
      lastName: family_name || ""
    }));
  };
  useEffect(() => {
    if (errorMutation) {
      showSnackbar("Error sending the feedback!", "error")
    }
    else if (queryResult) {
      showSnackbar("Feedback has been sent", "success")
    }
  }, [queryResult, errorMutation]);
  return (
    <>
      <span className={classes.floatingButton} onClick={handleToggle} data-testid="feed-back" id="feed-back">
        {useTranslate("Feedback")}
      </span>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleToggle}
        classes={{ paper: classes.feedbackDrawer }}
      >

        <form noValidate autoComplete="off" id="feed-back-form">
          <div style={{ display: form.category ? "block" : "none" }}>
            <Card variant="elevation" className={classes.actionCard}>
              {form.category && <CardHeader
                className={classes.cardHeader}
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    {categoryDetail[form.category].avatar || "!"}
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings" data-testid="clear-form" onClick={clearForm} title="Clear">
                    <FontAwesomeIcon icon={["fal", "times"]} />
                  </IconButton>
                }
                title={
                  <Typography variant="h5" component="h2">
                    {categoryDetail[form.category].title}
                  </Typography>
                }
                subheader={categoryDetail[form.category].description}
              />}

            </Card>
            <TextField
              required
              fullWidth
              margin="normal"
              label={useTranslate("login:Title")}
              name="title"
              value={form.title}
              onChange={handleChange}
              className={classes.textField}
              placeholder={useTranslate("login:Title")}
              error={!!errors.title}
              helperText={errors.title}
              data-testid="input-title"
              inputProps={{ "data-testid": "title-input" }}
            />

            <TextField
              required
              label={useTranslate("login:Email")}
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
              fullWidth
              placeholder={useTranslate("login:email")}
              className={classes.textField}
              error={!!errors.email}
              helperText={errors.email}
              data-testid="input-email"
              inputProps={{ "data-testid": "email-input" }}
            />
            <TextField
              label={useTranslate("login:First Name")}
              name="firstName"
              value={form.firstName}
              onChange={handleChange}
              fullWidth
              placeholder={useTranslate("login:First Name")}
              className={classes.textField}
              data-testid="input-firstName"
              inputProps={{ "data-testid": "firstName-input" }}
            />
            <TextField
              label={useTranslate("login:Last Name")}
              name="lastName"
              value={form.lastName}
              onChange={handleChange}
              fullWidth
              placeholder={useTranslate("login:Last Name")}
              className={classes.textField}
              inputProps={{ "data-testid": "lastName-input" }}
              data-testid="input-lastName"
            />
            <TextField
              required
              label={useTranslate("login:Details")}
              name="details"
              value={form.details}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              className={classes.textField}
              error={!!errors.details}
              placeholder={useTranslate("login:Details")}
              helperText={errors.details}
              data-testid="input-details"
              inputProps={{ "data-testid": "details-input" }}
            />
            <input
              accept={UPLOAD_WHITE_LIST}
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
              multiple
            />
            <div className={classes.fileChipContainer}>
              {form.attachments?.map((x, index) => (
                <Chip
                  id={`file-chip-index-${index}`}
                  color="primary"
                  className={classes.chip}
                  size="small"
                  onDelete={() => onFileDelete(index)}
                  label={x?.attachmentName}
                  title={x?.attachmentName}
                  data-testid={`chip-file-${index}`}
                />)
              )}
            </div>
            <div className={classes.buttonContainer}>
              <input
                data-testid="upload"
                accept={UPLOAD_WHITE_LIST}
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
                multiple
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faUpload} />}
                  size="small"
                >
                  {useTranslate("login:Attach File")}
                </Button>
              </label>
              <Button
                data-testid="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                size="small"
              >
                {useTranslate("login:Submit")}
              </Button>
            </div>
          </div>
          <div style={{ display: form.category ? "none" : "block" }}>
            <Card className={classes.actionCard}>
              <CardHeader avatar={
                <Avatar aria-label="feed back" >
                  <FontAwesomeIcon icon={faCommentAltEdit} />
                </Avatar>}
                title={
                  <Typography variant="h5" component="h2">
                    WE VALUE YOUR FEEDBACK
                  </Typography>
                }
                subheader="Help us improve by sharing your thoughts or reporting issues."
                style={{ fontWeight: 900 }}
              >
              </CardHeader>
            </Card>
            <Card variant="outlined" className={classes.actionCard}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {categoryDetail[FEED_BACK_CATEGORY.bug].title}
                </Typography>
                <Typography variant="body2" component="p">
                  {categoryDetail[FEED_BACK_CATEGORY.bug].description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button style={{ width: 140 }} color="secondary" size="small" data-testid="button-bug" onClick={() => setCategory(FEED_BACK_CATEGORY.bug)}>
                  {useTranslate("login:Report Issue")}</Button>
              </CardActions>
            </Card>
            <Card variant="outlined" className={classes.actionCard}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {categoryDetail[FEED_BACK_CATEGORY.feature].title}
                </Typography>
                <Typography variant="body2" component="p">
                  {categoryDetail[FEED_BACK_CATEGORY.feature].description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button style={{ width: 140 }} fullWidth color="secondary" data-testid="button-feature" size="small" onClick={() => setCategory(FEED_BACK_CATEGORY.feature)}>
                  {useTranslate("login:Request Feature")}</Button>
              </CardActions>
            </Card>
            <Card variant="outlined" className={classes.actionCard}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {useTranslate("login:FREQUENTLY ASKED QUESTIONS")}
                </Typography>
                <Typography variant="body2" component="p">
                  {useTranslate("login:Looking for answers?")}
                </Typography>
              </CardContent>
              <CardActions>
                <Button color="secondary" size="small" onClick={() => onNavigate(
                  MICRO_APP_DASHBOARDS,
                  FAQ_PAGE_PATH,
                  faqAppDomain,
                )}>{useTranslate("login:GO TO FAQ")}</Button>
              </CardActions>
            </Card>
          </div>
        </form>
      </Drawer >
    </>
  );
};

export default FeedbackDrawer;
