import * as React from "react";
import {
    Grid,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    makeStyles
} from "@material-ui/core";
import {
    Application,
    makePresenter
} from "../../../../application/application";
import { PageTitles, PentairBlue, PentairBrightBlue } from "@pentair/react";
import { AlertError } from "../../../../components/mui-alert/alert";
import { BackButton } from "../../../../components/back-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TnCDialogPresenter } from "./tnc-dialog-presenter";
import { useTranslate } from "../../../../components/translator/use-translator";

/**
 * Component styles
 */
const useStyles = makeStyles({
    gridLayout: {
        padding: 19,
        maxWidth: 840,
        overflow: "hidden"
    },
    gridChildren: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        paddingBottom: 0,
    },
    cardFooter: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        marginTop: 12
    },
    footer: {
        color: "#fff",
        font: "Barlow SemiBold",
        marginBottom: 0,
    },
    footerBackground: {
        backgroundColor: PentairBlue[500],
    },
    footerAlign: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 60,
    },
    footerWidth: {
        maxWidth: "80%",
        textAlign: "center",
    },
    icon: {
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
    }
});

/**
 * Component props
 */
interface IOwnProps {
    // On accept callback to continue
    onContinue: (termsAndConditionAccepted: string, newsSubscriptionAccepted: string) => any;
    // On logout action callback
    onLogout: () => void;
    // Alert props, if any
    alert: any;
    // display the TnC dialog
    open: boolean;
}

/**
 * An utility to create the presenter instance
 */
const usePresenter = makePresenter(
    ({ network }: Application) => new TnCDialogPresenter(network),
    (presenter, props: IOwnProps) => {
        presenter.onContinue = props.onContinue;
        presenter.onLogout = props.onLogout;
    }
);

/**
 * Class component to handle the TnC view
 */
export const TnCDialog: React.FC<IOwnProps> = (props) => {

    const { state,
        onValueChange,
        onBackAction,
        onContinueAction
    } = usePresenter(props);

    const {
        error,
        disable,
        termsAndConditionAccepted,
        newsSubscriptionAccepted
    } = state;

    const classes = useStyles();

    const alert = error.errorMessage ? error : props.alert;
    const email = useTranslate("login:pentairq.support@pentair.com")

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={props.open}>
            <DialogTitle id="confirmation-dialog-title">
                <PageTitles title={useTranslate("login:Terms and conditions updated")} subtitle={useTranslate("login:ACCEPTANCE REQUIRED")}></PageTitles>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3} className={classes.gridLayout}>
                    <Grid item xs={12} md className={classes.gridChildren}>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(_event) => onValueChange(_event)}
                                        name={"termsAndConditionAccepted"}
                                        color="primary"
                                        value={termsAndConditionAccepted?.length}
                                        icon={<span className={classes.icon} />}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="body2">
                                        {useTranslate("login:termsAndCondition")} &nbsp;
                                        <a
                                            style={{
                                                color: PentairBrightBlue[500],
                                                textDecoration: "none",
                                                fontWeight: 600,
                                            }}
                                            target="_blank"
                                            href={"../../../../documents/terms-of-service.pdf"}
                                        >{useTranslate("login:Terms of Service")} *</a>
                                    </Typography>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(_event) => onValueChange(_event)}
                                        name={"newsSubscriptionAccepted"}
                                        value={newsSubscriptionAccepted?.length}
                                        color="primary"
                                        icon={<span className={classes.icon} />}
                                    />
                                }
                                label={<Typography
                                    variant="body2">
                                    {useTranslate("login:newsSubscription")}
                                </Typography>
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <AlertError
                text={alert.errorMessage}
                enableError={alert.showError}
            />
            <DialogActions style={{ padding: "0px 18px 10px 18px" }} className={classes.cardFooter}>
                <BackButton text={"BACK"} onClick={onBackAction} />
                <Button
                    disabled={disable}
                    size="small" onClick={onContinueAction}>
                    I agree
                    <FontAwesomeIcon
                        icon={["fas", "long-arrow-right"]}
                        style={{ marginLeft: 6 }}
                    />
                </Button>
            </DialogActions>
            <Grid container>
                <Grid item xs={12} md={12} className={classes.footerBackground}>
                    <div className={classes.footerAlign}>
                        <div className={classes.footerWidth}>
                            <Typography
                                paragraph
                                variant="body2"
                                className={classes.footer}
                            >
                                {useTranslate("login:LoginText6")} &nbsp; <a style={{ color: "#D0DF00" }} href={`Mailto:${email}`}>{email}</a>&nbsp; {useTranslate("login:LoginText7")}
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Dialog>
    );
}