import * as React from "react";
import { RouteComponentProps } from "react-router";
import {
    Application,
    makePresenter
} from "../../../../application/application";
import { QRCodeDialog } from "../sections/qr-code";
import { LoginPresenter } from "./login-presenter";
import { LoginSection } from "../sections/login-section";
import { MfaVerification } from "../../mfa-verification/mfa-verification";
import { ResetPassword } from "../../reset-password/reset-password";
import { TnCDialog } from "../tnc-dialog/tnc-dialog";
import {
    Button,
    Dialog,
    DialogActions,
    makeStyles
} from "@material-ui/core";
import {
    PageTitles,
    PentairBlue,
    pentairTheme
} from "@pentair/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslate } from "../../../../components/translator/use-translator";
import LoadingIcon from "../../../../components/loading-icon";
import { IS_MOBILE_LOGIN } from "../../../../config/constants";
import { checkPageInMobile } from "../../../../utils/commonUtils";
import { LoginMobile } from "./mobil";
import { isExternal } from "../../../../config/data";

const background = require("../../../../images/background.png");

/**
 * An utility to create the presenter instance
 */
const usePresenter = makePresenter(
    ({ network }: Application) => new LoginPresenter(network),
    (presenter, props: RouteComponentProps) => {
        presenter.history = props.history;
        presenter.location = props.location;
    }
);

/**
 * Component styles
 */
const useStyles = makeStyles((theme) => ({
    actionsButton: {
        height: "38px",
        fontSize: "14px",
        textAlign: "left",
        textTransform: "unset",
        fontWeight: 500,
        margin: theme.spacing(1),
        marginLeft: 0
    },
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
    },
}));

/**
 * A component to create the login UX
 */
export const Login: React.FC<RouteComponentProps> = (props) => {
    window.sessionStorage.setItem(IS_MOBILE_LOGIN, `${checkPageInMobile()}`);
    const classes = useStyles();

    const {
        state,
        onUserLogin,
        onChange,
        onKeyPress,
        onLoginWithMFA,
        onBackNavigate,
        onCompleteNewPassword,
        onTnCAcceptance,
        onLogout,
        onCloseQRCode,
        showError
    } = usePresenter(props);

    const {
        isLoading,
        isError,
        enableLogin,
        captureTnC,
        params,
        alert,
        showQR,
        credentials,
        qrCode,
        qrSecret,
        termsAndConditionAccepted,
    } = state;

    const { token } = credentials;
    const email = useTranslate("login:pentairq.support@pentair.com")
    return (
        <React.Fragment>
            <div className={classes.root}>
                {(isExternal()) && <LoginMobile {...props} /> ||
                    {
                        login: (
                            <LoginSection
                                login={onUserLogin}
                                onKeyPress={onKeyPress}
                                onChange={onChange}
                                credentials={credentials}
                                enableLoginButton={enableLogin}
                                alert={{
                                    errorMessage: alert.errorMessage,
                                    enableError: alert.showError,
                                }}
                            />
                        ),
                        verification: (
                            <MfaVerification
                                loginWithMFA={onLoginWithMFA}
                                navigateBack={onBackNavigate}
                                alert={alert}
                            />
                        ),
                        resetPassword: (
                            <ResetPassword
                                completeNewPassword={onCompleteNewPassword}
                                onLogout={onLogout}
                                alert={alert}
                                showTnCCheckboxes={termsAndConditionAccepted}
                            />
                        )
                    }[params]
                }
            </div>
            <div>
                <TnCDialog
                    alert={alert}
                    open={captureTnC}
                    onContinue={onTnCAcceptance}
                    onLogout={onLogout}
                />
            </div>
            <div>
                <QRCodeDialog
                    open={showQR}
                    qrCode={qrCode}
                    onCloseQRCode={onCloseQRCode}
                    token={token}
                    alert={alert}
                    onChange={(_event: any) => onChange(_event)}
                    qrSecret={qrSecret}
                />
            </div>
            <div>{isLoading ? <LoadingIcon /> : null}</div>
            <Dialog
                PaperProps={{
                    style: {
                        margin: pentairTheme.spacing(2),
                        borderRadius: 0,
                        paddingRight: pentairTheme.spacing(2),
                        paddingLeft: pentairTheme.spacing(2)
                    }
                }}
                disableBackdropClick disableEscapeKeyDown maxWidth="sm" open={isError}>
                <span style={{ padding: pentairTheme.spacing(1) }}>
                    <PageTitles title={'Unknown Error'} />
                    <span>
                        Something went wrong. Try to refresh your Browser screen. If this issue persist, please reach out to us at <a style={{ color: PentairBlue[500] }} id="link" href={`Mailto:${email}`}>{email}</a>
                    </span>
                </span>
                <DialogActions>
                    <Button
                        color="primary"
                        className={classes.actionsButton}
                        onClick={() => {
                            showError(false);
                        }}
                        startIcon={<FontAwesomeIcon icon={["fal", "check"]} />}
                    >
                        Close and Reload
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
