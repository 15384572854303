
/**
 * Query to reset the password
 */
export const queryResetPassword = `
    query resetPassword{
       resetPassword(id:"@userId")
    }
`;

/**
 * Appsync query for getting user cognito pool information
 */
export const queryIdentityInfo = `
    query fetchAccount{
        fetchAccount(id:"@userId"){
            poolID
            clientID
            termsAndConditionAccepted
        }
    }
`;

/**
 * Query to get domains
 */
export const queryGetDomains = `
    query fetchPilets {
        fetchPilets {
            microapp
            domain
            appsync
            title
            icon
            title
            order
            displayInToolbar
            version
            piralSchemaVersion
            features {
                id
                iv
                name
                title
                order
                path
                routeAlias
                subtitle
                isDefaultPath
                icon
                displayInToolbar
            }
        }
    }
`;
