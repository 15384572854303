import { BasePresenter } from "@pentair/react";
import { Auth } from "aws-amplify";
import { History } from "history";
import { route } from "../../../config/routes";

/**
 * Component state
 */
interface IComponentState {
    // A password state
    password: any;
    // An error state
    error: any;
    // Enable or disable state
    disable: boolean;
    // A flag to control show/hide for confirmation dialog
    isLoading: number;
}

/**
 * Class component to handle reset old password
 */
export class ResetOldPasswordPresenter extends BasePresenter<IComponentState> {

    public history: History;
    public location: any;

    public state: IComponentState = {
        isLoading: 0,
        disable: false,
        password: {
            code: "",
            password: "",
            confirmPassword: ""
        },
        error: {
            errorMessage: "",
            showError: false,
        }
    }

    /**
     * Set local state object
     * @param state New state
     */
    public change = (state: Partial<IComponentState>) => {
        this.setState({ ...this.state, ...state });
    }

    /**
     * Mount event
     */
    public mount = async () => { }
    
    /**
     * Un-mount event
     */
    public unmount = async () => { }

    /**
     * On <ENTER> key press action callback
     * @param _event A key event
     */
    public onKeyPress = (_event: any) => {
        // Validate the <ENTER> key code on key press
        if (_event.charCode === 13) {
            this.onContinueClick();
        }
    }
    
    /**
     * Set state of input target on change action callback
     * @param event target element
     */
    public onChange = (_event: any) => {
        _event.preventDefault();
        const password = {
            ...this.state.password,
            [_event.target.name]: _event.target.value,
        };
        this.change({ password });
    }

    /**
     * Get the user name from location state
     */
    private getUserName = () => {
        return this.location?.state["userName"]
    }

    /**
     * On browser back navigation callback
     */
    public onNavigateBack = () => {
        this.history.push(route("app.shell.forgetpassword"));
    }

    /**
     * Validate the user inputs
     */
    private validateFormDetails = () => {
        if (this.state.password.code.trim() === "") {
            this.setErrorMessage("Enter password code", true);
            this.change({ disable: false });
            return false;
        }
        else if (this.state.password.password.trim() === "") {
            this.setErrorMessage("Enter new password", true);
            this.change({ disable: false });
            return false;
        }
        else if (this.state.password.confirmPassword.trim() === "") {
            this.setErrorMessage("Enter confirm new password", true);
            this.change({ disable: false });
            return false;
        }
        else if (this.state.password.confirmPassword.trim() !== this.state.password.password.trim()) {
            this.setErrorMessage("New password and Confirm password are not matching", true);
            this.change({ disable: false });
            return false;
        }
        return true;
    }

    /**
     * Updating new password for user
     * @param code authentication code
     * @param newPassword
     * @param userName
     * Success: it will return session to presenter
     * Error: Throws error if any
     */
    private forgetPasswordSubmit = (
        code: string,
        newPassword: string,
        userName: string
    ) => {
        this.change({ isLoading: 1 });
        return Auth.forgotPasswordSubmit(userName, code, newPassword)
            .then((result: any) => {
                this.change({ isLoading: 0 });
                return result;
            })
            .catch((error: any) => {
                this.change({ isLoading: 0 });
                throw error;
            });
    }

    /**
     * Submitting user new password
     */
    public onContinueClick = async () => {
        this.setErrorMessage("", false);
        this.change({ disable: true });
        if (this.validateFormDetails()) {
            this.forgetPasswordSubmit(
                    this.state.password.code,
                    this.state.password.password,
                    this.getUserName()
                )
                .then((result: any) => {
                    /**
                     * Reset password success user will be redirected to login screen
                     */
                    this.history.push(route("app.shell.login"));
                })
                .catch(async(error: any) => {
                    /**
                     * Error while updating user new password logging error in console
                     */
                    await this.setErrorMessage(error.message, true);
                    this.change({ disable: false });
                    console.log(error);
                });
        }
    }

    /**
     * Set error message state to hide on show message on UX
     * @param errorMessage An error string
     * @param enable Show or hide error
     */    
    private setErrorMessage = async(errorMessage: string, enable: boolean) => {
        const error = {
            ...this.state.error,
        };
        error.showError = enable;
        error.errorMessage = errorMessage;
        this.state.error = error;
    }

}