import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { PageTitles } from "@pentair/react";

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    height: theme.spacing(4),
    width: "130px",
    backgroundColor: "#64a70b",
    color: "white",
  },
  abortButton: {
    height: theme.spacing(4),
    width: "110px",
  },
}));

/**
 * delete confirmation dialog
 */
interface OwnProps {
  /**
   * dialog open status
   */
  open: boolean;
  /**
   * on dialog close call back
   */
  onClose: () => void;
  /**
   * on confirm call back
   */
  onOk: () => void;
  title: string;
  subTitle: string;
  children: any;
  confirmButtonTitle?: string;
}

export const ConfirmDialog = ({
  open,
  onClose,
  onOk,
  title,
  subTitle,
  children,
  confirmButtonTitle,
}: OwnProps) => {
  const classes = useStyles();

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" open={open}>
      <DialogTitle id="confirmation-dialog-title" style={{ paddingBottom: 0 }}>
        <PageTitles title={title} subtitle={subTitle} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          id="confirm"
          color="primary"
          className={classes.confirmButton}
          onClick={onOk}
          startIcon={<FontAwesomeIcon icon={faCheck} />}
        >
          Confirm
        </Button>
        <Button
          id="abort"
          autoFocus
          color="default"
          className={classes.abortButton}
          onClick={onClose}
          startIcon={<FontAwesomeIcon icon={faTimes} />}
        >
          Abort
        </Button>
      </DialogActions>
    </Dialog>
  );
};
