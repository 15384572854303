import * as React from "react";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import {
    Grid,
    makeStyles,
    StyleRules,
    Typography
} from "@material-ui/core";
import { useTranslate } from "../../../components/translator/use-translator";
import { PentairBlue, PentairSilverGray, PentairTheme } from "@pentair/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IFeature } from "../../common/types";
import { FeatureListItem } from "./feature-list-item";

/**
 * Component UX styles
 */
 const useStyles = makeStyles((theme: PentairTheme): StyleRules => ({
    title: { color: PentairSilverGray[500] },
    icon: {
        color: PentairBlue[500],
        marginRight: theme.spacing(1),
    },
}));

/**
 * To view the micro-app as a list item on admin dashboard.
 * @param props 
 * @returns component
 */
export const MicroAppListItem: React.FC<any> = (props) => {
    // Access state constants
    const { microAppFeature, isMobileLogin } = props;

    // Style object
    const classes = useStyles();
    const appTitle = useTranslate(`${microAppFeature.microapp}:${microAppFeature.title}`, microAppFeature.microapp);
    return (
        <Grid item xs={12} sm={6} md={5} lg={4}>
            <div style={{ display: "flex" }}>
                <FontAwesomeIcon
                    icon={["fal", microAppFeature.icon as IconName]}
                    size={"lg"}
                    className={classes.icon}
                />
                <Typography
                    paragraph
                    variant="h4"
                    className={classes.title}
                >
                    {appTitle}
                </Typography>
            </div>

            {microAppFeature.features
                ?.filter((filter: IFeature) => (filter.path && filter.path !== "" && !(isMobileLogin && filter.hideInMobile)))
                ?.map(
                    (feature: IFeature) => {
                        return <FeatureListItem feature={feature} microAppFeature={microAppFeature}></FeatureListItem>
                    }
                )}
        </Grid>
    );
}
