import { CognitoUserSession } from "amazon-cognito-identity-js";
import { setSessionData } from "@pentair/react";
import { BROWSER_STORE_DOMAINS, BROWSER_STORE_FAVORITES, LANDING_PAGE_OBJECT_KEY, MICRO_APP_PLATFORM_MANAGEMENT, MICRO_APP_USER_MANAGEMENT, PRODUCT_CATEGORY_MICROAPPS, SERVICE_CUSTOMER } from "../../config/constants";
import { QueryProductCategory, getPermissionToken } from "../common/graphql/queries";
import { GetKeyAccount, GetUser, queryUserFavorites } from "./login/graphql/queries";
import { IFavorite, IMicroAppFeature, IProductCategory } from "../common/types";
import { application } from "../../application/application";
import { setUserPermission } from "../../config/userPermission";

export const api = async (query: string, responseKey: string, args?: any, microApp?: string) => {
  try {
    const response: any = await application.network.fetch(query, args || {}, microApp);
    if (response.errors) {
      throw new Error(response.errors);
    }
    if (!response.errors && response.data[responseKey] !== null) {
      return response.data[responseKey];
    }
  } catch (error) {
    console.error(error);
  }
  return;
};

class User {
  private static instance: User | null = null;
  private errors: any[];
  private permissionToken: string;
  public session: CognitoUserSession | null;
  public permissions: IMicroAppFeature[];
  public userId: string;
  public keyAccountId: string;
  public productCategories: IProductCategory[];
  /**
   * [{
   *      key:"landingPage",
   *      value: contain "location Route object" in string format
   *      description: "product-management"
   * }]
  */
  public favorites: IFavorite[];
  public finishedLoginProcess: Promise<[PromiseSettledResult<void>, PromiseSettledResult<IFavorite[]>, PromiseSettledResult<void>]>;

  private constructor(loginSession: CognitoUserSession) {
    if (!loginSession.isValid) return;
    this.favorites = [];
    this.permissions = [];
    this.errors = [];
    this.productCategories = [];
    this.session = loginSession;
    this.keyAccountId = loginSession?.getIdToken().payload["keyaccountid"];
    this.userId = loginSession?.getIdToken().payload.sub;
    const permissionPromise = this.setUserPermission();
    const categoryPromise = this.setProductCategoryMicroapps();
    const favPromise = this.fetchUserFavorite(loginSession);
    const serviceCustomerPromise = this.setDefaultServiceCustomer(loginSession);
    const microAppDetails = sessionStorage.getItem(
      BROWSER_STORE_DOMAINS
    );
    JSON.parse(microAppDetails || "{}")
    permissionPromise.then((token: string) => {
      this.permissions = setUserPermission(JSON.parse(microAppDetails || "{}"), this.permissionToken, loginSession);
    });
    this.finishedLoginProcess = Promise.allSettled([categoryPromise, favPromise, serviceCustomerPromise]);
  }

  static getInstance(): User {
    if (!User.instance) {
      throw new Error("User instance not created yet!");
    }
    return User.instance;
  }

  static tokenLogin(loginSession: CognitoUserSession): User {
    if (!User.instance) {
      User.instance = new User(loginSession);
    }
    return User.instance;
  }



  /**
  * Return user permission in encrypted
  * @param session User Session object
  * @returns Permission in String
  */
  private setUserPermission = async () => {

    const query = getPermissionToken.replace("@keyAccountId", this.keyAccountId)
      .replace("@emailId", this.session?.getIdToken().payload.email);
    this.permissionToken = await api(query, "getPermissionToken", {}, MICRO_APP_USER_MANAGEMENT) || "";

    return this.permissionToken;
  };

  /**
   * An API call to get product category configured
   */
  private setProductCategoryMicroapps = async () => {
    try {
      const response: any = await api(QueryProductCategory, "getProductCategoryMicroappDetails",
        {}, MICRO_APP_PLATFORM_MANAGEMENT);

      if (response !== null) {
        setSessionData(PRODUCT_CATEGORY_MICROAPPS, JSON.stringify(response));
        this.productCategories = response;
      } else {
        console.error("Error fetching product category", response);
      }
    } catch (error) {
      console.error("Error fetching product category", error);
    }
    return;
  };

  /**
   * An API call to get list of favorite items
   * @param session Current user session
   * @returns list of favorite items or empty array
   */
  private fetchUserFavorite = async (session: any) => {
    const response: IFavorite[] = await api(queryUserFavorites
      .replace("@keyAccountId", this.keyAccountId)
      .replace("@userId", this.userId), "getUserFavourites",
      {},
      MICRO_APP_USER_MANAGEMENT,
    );

    // To filter out favorites which doesn't have any value.
    const filteredFav = response?.filter(
      (fav: any) => fav.value && fav.value.trim(),
    );
    setSessionData(BROWSER_STORE_FAVORITES, JSON.stringify(filteredFav))
    this.favorites = filteredFav;

    return this.favorites;
  };

  /**
   * Set service customer if available from db to session object
   * @param session
   */
  protected setDefaultServiceCustomer = async (session: any) => {
    const serviceCustomer = { id: "", name: "" };

    const userResponse = await api(GetUser.replace("@keyAccountId", this.keyAccountId)
      .replace("@userId", this.userId), "getUser", {}, MICRO_APP_USER_MANAGEMENT);
    const defaultServiceCustomerId = userResponse?.defaultServiceCustomer;

    if (defaultServiceCustomerId && defaultServiceCustomerId.trim()) {
      serviceCustomer.id = defaultServiceCustomerId;
      const keyAccountResponse = await api(
        GetKeyAccount.replace("@keyAccountId", defaultServiceCustomerId),
        "getKeyAccount", {}, MICRO_APP_USER_MANAGEMENT,
      );
      if (keyAccountResponse && keyAccountResponse.name) {
        serviceCustomer.name = keyAccountResponse.name;
        setSessionData(SERVICE_CUSTOMER, JSON.stringify(serviceCustomer));
      }
    }
  };

  static logout(): void {
    // Clear or nullify the instance when the user logs out.
    User.instance = null;
  }

}

export default User;
