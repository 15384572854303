import  {Amplify, API, Auth, graphqlOperation } from "aws-amplify";
import { BROWSER_STORE_DOMAINS, MICRO_APP_NAME } from "../config/constants";
import { route } from "../config/routes";
import { IMicroAppFeature } from "./types";

/**
 * Network to establish connectivity to backend graphql
 */
export class NetworkModule {
  constructor() {}

  /**
   * Graphql query execution
   * @param querystring graphql query
   * @param variables query input parameters
   * @param microAppName Specific Pilet name to get the AppSync
   */
  public fetch = async (
    querystring: string,
    variables?: any,
    microAppName?: string,
  ) => {
    let result;
    try {
      const isValid = ((await Auth.currentSession()) as any).isValid();
      if (isValid) {
        setAppSync(microAppName);
        result = await API.graphql(graphqlOperation(querystring, variables));
      } else {
        window.location.href = route("app.shell.login");
      }
    } catch (err) {
      console.log("ERROR", err);
      result = err;
    }
    return result;
  };
}

/**
 * Configure the AppSync to access the backend data source
 * @param microAppName Specifies the micro-app name, if accessed the Pilet APIs.
 * By default is empty for current store
 */
const setAppSync = (microAppName = "") => {
  if (microAppName) {
    const appSync: string = getGraphqlEndpoint(microAppName);
    Amplify.configure({
      API: {
        aws_appsync_graphqlEndpoint: appSync,
      },
      aws_appsync_graphqlEndpoint: appSync,
    });
  } else {
    const defaultAppSync = window.sessionStorage.getItem(
      `${MICRO_APP_NAME}-appsync`,
    );
    if (defaultAppSync) {
      Amplify.configure({
        aws_appsync_graphqlEndpoint: defaultAppSync,
      });
    } else {
      const url = getGraphqlEndpoint(MICRO_APP_NAME);
      window.sessionStorage.setItem(`${MICRO_APP_NAME}-appsync`, url);
    }
  }
};

/**
 * Searches for the AppSync URL to access the backend data source from Browser store
 * @param microAppName Specifies the micro-app name
 */
function getGraphqlEndpoint(microAppName: string) {
  // Get the list of apps from Browser store
  const microAppFeatures: IMicroAppFeature[] = [
    ...JSON.parse(
      window.sessionStorage.getItem(BROWSER_STORE_DOMAINS) as string,
    ),
  ];
  // Find and return the AppSync URI
  return (
    microAppFeatures.find(
      (app: IMicroAppFeature) => app.microapp === microAppName,
    )?.appsync || ""
  );
}
