import { useTranslation  } from "react-i18next";
import i18n from '../../i18n'

/**
 * Method to set the language
 * @param lang Language to set
 */
export const setLanguage = (lang: string) => {
  if (lang && lang.length) {
    i18n.changeLanguage(lang);
  }
}

/**
 * Return translated words
 * @param key Keyword to look
 */
export const useTranslate = (key: string, domain?: string) => {
  const domains: string[] = ['administration', 'favorites', 'login'];
  if (domain) {
    domains.push(domain);
  }
  const { t } = useTranslation(domains, {
    useSuspense: false
  });
  return t(key);
}
