/* eslint-disable import/prefer-default-export */
import * as React from "react";
import {
  Grid,
  StyleRules,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  PageTitles,
  PentairTheme,
} from "@pentair/react";
import { RouteComponentProps } from "react-router";
import {
  Application,
  makePresenter,
} from "../../../application/application";
import { DefaultAdminPagePresenter } from "./default-admin-page-presenter";
import { IMicroAppFeature } from "../../common/types";
import { ConfirmDialog } from "../../../components/confirmation-dialog";
import { useTranslate } from "../../../components/translator/use-translator";
import { MicroAppListItem } from "../partials/microapp-list-item";
import LoadingIcon from "../../../components/loading-icon";
import { IS_MOBILE_LOGIN } from "../../../config/constants";

/**
 * Component UX styles
 */
const useStyles = makeStyles((theme: PentairTheme): StyleRules => ({
  root: {},
  container: {
    marginTop: 55,
    width: "95%",
    transition: "width 0.3s",
    background: "#fff",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "unset",
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

/**
 * An utility to create the presenter instance
 */
const usePresenter = makePresenter(
  ({ network }: Application) => new DefaultAdminPagePresenter(network),
  (presenter, props: RouteComponentProps) => {
    presenter.history = props.history;
    presenter.location = props.location;
  },
);

/**
 * A component to manage the micro-apps as administration page
 */
export const DefaultAdminPage: React.FC<RouteComponentProps> = (props) => {
  // Prepare state constant with presenter
  const {
    state,
    onCloseDialog,
    onAcceptDialog,
  } = usePresenter(props);

  // Access state constants
  const {
    isLoading,
    microAppFeatures,
    modelData,
  } = state;

  // Style object
  const classes = useStyles();
  const isMobileLogin =   window.sessionStorage.getItem(IS_MOBILE_LOGIN) == 'true';
  return (
    <>
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <PageTitles title={useTranslate("administration:title")} subtitle={useTranslate("administration:subTitle")} />
          <Typography variant="body2" component="span">
            {useTranslate("administration:description")}
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12} sm={6} md={9} lg={9}>
          <Grid container className={classes.container} spacing={3}>
            {microAppFeatures
              ?.sort((oldApp: IMicroAppFeature, newApp: IMicroAppFeature) => Number(oldApp.order) - Number(newApp.order))
              .map((microAppFeature: IMicroAppFeature, ind: number) => {
                // Filter and ignore the Micro apps with empty `domain`
                if (!microAppFeature.domain || microAppFeature.domain === "") {
                  return null;
                }
                if (microAppFeature.hideInMobile && isMobileLogin) { /// hide feature if in mobile view and feature need to be hiden
                  return null;
                }
                return (
                  <MicroAppListItem
                    key={`key-${microAppFeature.microapp}-${ind}`}
                    microAppFeature={microAppFeature}
                    isMobileLogin={isMobileLogin}
                  />
                );
              })}
          </Grid>
        </Grid>
      </Grid>
      <div>{isLoading ? <LoadingIcon /> : null}</div>
      <ConfirmDialog
        title={useTranslate("administration:dialogTitle")}
        subTitle={useTranslate("administration:dialogSubTitle")}
        open={modelData.show}
        onClose={onCloseDialog}
        onOk={onAcceptDialog}
      >
        <Typography style={{ marginTop: -12 }}>
          <div dangerouslySetInnerHTML={{ __html: modelData.message || "" }} />
        </Typography>
      </ConfirmDialog>
    </>
  );
};
