import * as React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BackButton } from "../../../components/back-button";
import {
  PageTitles,
  PentairBlue,
  PentairBrightBlue
} from "@pentair/react";
import { useTranslate } from "../../../components/translator/use-translator";
import { LangIcon } from "../../../components/langIcons/langIcon";
import { Application, makePresenter } from "../../../application/application";
import { AuthLayoutPresenter } from "./auth-layout-presenter";

const logo = require("../../../images/logo.svg") as string;
const background = require("../../../images/background.png");

interface OwnProps {
  /**
   * Layout children
   */
  children: any;
  /**
   * Enable or Disable back button
   */
  isBackExists: boolean;
  /**
   * Callback function
   */
  onClick: () => void;
  /**
   * Back button navigation
   */
  navigateBack?: () => void;
  /**
   * Enable disable login button
   */
  enableLoginButton: boolean;
  /**
   * Text to be displayed for button, default Login
   */
  buttonText?: string;
  /**
    * Text to be displayed for button, default Login
    */
  changePassword?: boolean;
  /**
   * Callback function for Change Password
   */
  onChangePassword?: () => void;
}

/**
 * Component styles
 */
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "right top",
    backgroundRepeat: "no-repeat",
  },
  cardContent: {
    borderRadius: 0,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  language: {
    float: "right"
  },
  rootLang: {
    color: "black"
  },
  dropdownStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  dropdownStyleText: {
    fontWeight: 400,
    paddingRight: 5,
    paddingLeft: 5,
  },
  selectIcon: {
    fill: "black"
  }
}));

/**
 * An utility to create the presenter instance
 */
const usePresenter = makePresenter(
  ({ network }: Application) => new AuthLayoutPresenter(network),
);


/**
 * Layout definition
 * @param param0 
 * @returns 
 */
export const AuthLayout = (
  (props: OwnProps) => {
    const {
      children,
      isBackExists,
      onClick,
      navigateBack,
      enableLoginButton,
      buttonText,
      changePassword,
      onChangePassword
    } = props;
    const { state, languageChange } = usePresenter(props);
    const { selectedLang, languages } = state;
    const classes = useStyles();

    const email = useTranslate("login:pentairq.support@pentair.com")
    return (
      <div className={classes.root}>
        <Card className={classes.cardContent}>
          <div className={classes.language}>
            <Select
              disableUnderline
              value={selectedLang?.toLowerCase()}
              onChange={languageChange}
              MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" }, getContentAnchorEl: null }}
              classes={{
                root: classes.rootLang,
                icon: classes.selectIcon,
              }
              }
            >
              {languages.map((lang: any) => (
                <MenuItem key={lang.id?.toLowerCase()} value={lang.id?.toLowerCase()} style={{ padding: 6 }} >
                  <div className={classes.dropdownStyle} >
                    <LangIcon value={lang.id?.toLowerCase()} />
                    <div className={classes.dropdownStyleText}>{lang.name} </div>
                  </div>
                </MenuItem>
              ))
              }
            </Select>
          </div>
          <CardContent>
            <Grid container spacing={3} style={{ padding: 19, maxWidth: 840 }}>
              <Grid
                item
                xs={12}
                md
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  paddingBottom: 0,
                }}
              >
                <div>
                  <img src={logo} style={{ height: 44 }}></img>
                </div>
                <div>{children}</div>
                <div className={classes.buttons} >
                  {isBackExists ? (
                    <BackButton
                      text={useTranslate("login:BACK")}
                      onClick={navigateBack}
                    />
                  ) : (
                    <div></div>
                  )}
                  {
                    changePassword ?
                      (
                        <Button
                          size="small"
                          onClick={onChangePassword}
                          disabled={enableLoginButton}
                        >
                          {useTranslate("login:RESET PASSWORD")}
                          <FontAwesomeIcon
                            icon={["fas", "long-arrow-right"]}
                            style={{ marginLeft: 6 }}
                          />
                        </Button>
                      ) : (
                        <div></div>
                      )
                  }

                  <Button
                    size="small"
                    onClick={onClick}
                    disabled={enableLoginButton}
                  >
                    {buttonText ? buttonText : useTranslate("login:Login")}
                    <FontAwesomeIcon
                      icon={["fas", "long-arrow-right"]}
                      style={{ marginLeft: 6 }}
                    />
                  </Button>
                </div>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={12} md>
                <Card className={classes.cardContent}>
                  <PageTitles title={useTranslate("login:Login").toLocaleUpperCase()} subtitle={useTranslate("login:PENTAIR-Q").toLocaleUpperCase()} />
                  <Typography paragraph variant="body2">
                    {useTranslate("login:LoginText1")}
                  </Typography>
                  <Typography paragraph variant="body2">
                    {useTranslate("login:LoginText2")}
                  </Typography>
                  <Typography paragraph variant="body2">
                    {useTranslate("login:LoginText3")}
                  </Typography>
                  <Typography variant="body2">
                    {useTranslate("login:LoginText4")} &nbsp;
                    <a
                      style={{
                        color: PentairBrightBlue[500],
                        textDecoration: "underline",
                        fontWeight: 600,
                      }}
                      target="_blank"
                      href="https://foodandbeverage.pentair.com/en/products/beverage-filtration-solutions-bmf-18"
                    >
                      {useTranslate("login:learn more")}
                    </a>
                    &nbsp; {useTranslate("login:LoginText5")}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </CardContent>

          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              style={{ backgroundColor: PentairBlue[500] }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: 60,
                  alignItems: "center",
                }}
              >
                <Typography
                  paragraph
                  variant="body2"
                  style={{
                    color: "#fff",
                    font: "Barlow SemiBold",
                    marginBottom: 0,
                  }}
                >
                  {useTranslate("login:LoginText6")}&nbsp;
                  <span>
                    <a style={{ color: "#D0DF00" }} id="link" href={`Mailto:${email}`} > {email}</a>
                  </span>
                  &nbsp; {useTranslate("login:LoginText7")}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div >
    );
  }
);
