import React from "react";
import { TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Select with dropdown component
 * @param props The title for select material ui
 */
export function PasswordField(props: any) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <FontAwesomeIcon
            title={showPassword ? "Hide password" : "Show password"}
            icon={showPassword ? ["fas", "eye-slash"] : ["fas", "eye"]}
            onClick={handleClickShowPassword}
            style={{ margin: 5, cursor: "pointer" }}
          />
        ),
      }}
    />
  );
}
