import { generatePath } from "react-router";
import { getSessionData } from "@pentair/react";
import { DefaultAdminPage } from "../features/administration/view/default-admin-page";
import { ForgetPassword } from "../features/auth/forget-password/view/forget-password";
import { Login } from "../features/auth/login/view/login";
import { ResetOldPassword } from "../features/auth/reset-old-password/reset-old-password";
import {
  BROWSER_STORE_PILET_INFO,
  BROWSER_STORE_SHELL_PERMISSIONS,
} from "./constants";
import { IMicroAppFeature, IRedirectEvent } from "../features/common/types";
import { getRouteKeyAccount } from "../utils/routeKeyAccount";
import { Auth } from "aws-amplify";
import { LoginMobile } from "../features/auth/login/view/mobil";

/**
 * Defining application routes
 */
export interface IRoute {
  /**
   * route path
   */
  path: string;
  /**
   * title of menu
   */
  title: string;
  /**
   * component to render on route redirection
   */
  component: any;
  /**
   * menu type
   * default is false
   */
  mainMenu?: boolean;
  /**
   * state of menu based on permission
   * default false
   */
  disabled?: boolean;
  /**
   * available to all users
   * default false
   */
  public?: boolean;
  /**
   * allowed to redirect
   * default false
   */
  redirect?: string;
  /**
   * making secure route
   */
  securedAt: "NILL" | "APP_SHELL" | "PILETS";
  /**
   * flag to see if route is secure
   */
  isSecure?: boolean;
}

/**
 * Define the route mapping config for each page component
 */
export const routeMap = {
  "app.shell.default": {
    path: "/",
    component: Login,
    mainMenu: false,
    securedAt: "NILL",
  },
  "app.shell.login": {
    path: "/login",
    component: Login,
    mainMenu: false,
    securedAt: "NILL",
  },
  "app.shell.forgetpassword": {
    path: "/forgetpassword",
    component: ForgetPassword,
    mainMenu: false,
    securedAt: "NILL",
  },
  "app.shell.submitpassword": {
    path: "/submitpassword",
    component: ResetOldPassword,
    mainMenu: false,
    securedAt: "NILL",
  },
  "app.shell.administration": {
    path: "/administration",
    component: DefaultAdminPage,
    title: "Manage",
    securedAt: "APP_SHELL",
    isSecure: true,
    mainMenu: false,
  },
  "app.mobile.dashboard": {
    path: "/operations",
    title: "Operations",
    securedAt: "PILET",
    isSecure: true,
    mainMenu: false,
  },
  "app.dashboards.faq": {
    path: "/faq",
    title: "Support",
    securedAt: "PILET",
    isSecure: true,
    mainMenu: false,
  },
  "app.shell.mobile": {
    path: "/mobile",
    component: LoginMobile,
    mainMenu: false,
    securedAt: "NILL",
  },
};

export type RouteNames = keyof typeof routeMap;

/**
 * Generating complete route with parameters
 */
export const route = (
  name: RouteNames,
  args?: Record<string, string | boolean | number>,
) => generatePath(routeMap[name].path, args);

/**
 * validating route is configured in routes
 */
export const routes: IRoute[] = Object.keys(routeMap).map(
  (name: string) => routeMap[name],
);

/**
 * User credentials state and error state
 * @param app App feature
 * @param path path/route
 * @param domain domain
 */
export const onNavigate = async (app: string, path: string, domain: string) => {
  const user = await Auth.currentSession();
  window.sessionStorage.setItem(BROWSER_STORE_PILET_INFO, domain);
  if (path.lastIndexOf(":keyAccountId") > -1) {
    const keyAccount = getRouteKeyAccount(user);
    const keyAccountId = typeof keyAccount === "string" ? keyAccount : keyAccount.id;
    const url = path.replace(":keyAccountId", keyAccountId);
    window.location.href = `/${app}/${url}`;
    return;
  }
  window.location.href = `/${app}/${path.toLocaleLowerCase()}`;
};

/**
 * Get the default path of the micro app feature
 * @param microAppName Get the path for given micro-app
 */
const getDefaultApp = async (microAppName: string) => {
  const microApps: IMicroAppFeature[] = getSessionData(
    BROWSER_STORE_SHELL_PERMISSIONS,
    [],
  );

  const app: IMicroAppFeature = microApps?.find(
    (microApp: IMicroAppFeature) => microApp.microapp === microAppName,
  ) as IMicroAppFeature;
  if (app) {
    return app;
  }
  throw `You do not have enough permissions to see ${microAppName} Application.`;
};

/**
 * To handle the redirect page request
 * @param data Event data
 */
export const onHandleEvent = async (data: IRedirectEvent) => {
  try {
    if (data?.path) {
      /// If path is within App shell route scope
      if (
        data?.path === route("app.shell.default")
        || data?.path === route("app.shell.login")
        || data?.path === route("app.shell.administration")
      ) {
        window.location.href = data.path;
        return;
      }

      /// If path is contains Micro-App route scope
      const pathNames = data.path.split("/").filter((x: any) => x);
      if (pathNames && pathNames?.length) {
        const app = await getDefaultApp(pathNames[0]);
        window.sessionStorage.setItem(BROWSER_STORE_PILET_INFO, app.domain);
        window.location.href = data.path;
      }
    } else {
      throw "Can not redirect to the clicked link";
    }
  } catch (err) {
    console.error(err);
  }
};

/**
 * Validate if current path is not App-shell internal path
 * @returns true, if path is App-shell's internal route
 */
export const isExternalPath = () => {
  routes?.forEach((_route: IRoute) => {
    if (
      window.location.pathname === route("app.shell.administration")
      || (window.location.pathname === _route.path && _route.securedAt === "NILL")
    ) {
      return false;
    }
  });
  return true;
};
