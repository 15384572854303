import * as React from "react";
import { TopBar } from "../top-bar";
import {
  withStyles,
  WithStyles
} from "@material-ui/core";
import { Amplify, Auth } from "aws-amplify";
import {
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import { route } from "../../../config/routes";
import {
  BROWSER_STORE_DEF_CONFIG,
  BROWSER_STORE_IS_MENU_HIDDEN,
  BROWSER_STORE_POOL_ID,
  BROWSER_STORE_WEB_CLIENT_ID
} from "../../../config/constants";
import { Menu } from "piral";
import i18n from "../../../i18n";
import FeedbackDrawer from "../feedBack";

/** Read global config object and export
 * import this module to read dynamic configuration from global object
 */
export const getConfig = () => {
  return JSON.parse(
    window.sessionStorage.getItem(BROWSER_STORE_DEF_CONFIG) || "{}"
  )
}

/**
 * After user login default layout will be rendered
 * And it will configure user cognito pool information in amplify in order to access user tokens across all micro apps( Pilets)
 */
const generateHeader = async () => {
  try {
    const token = ((await Auth.currentSession()) as any).idToken.jwtToken;
    return { Authorization: token };
  } catch (e) {
    window.location.href = route("app.shell.login");
    return {};
  }
};

Amplify.configure({
  Auth: {
    userPoolId: window.sessionStorage.getItem(BROWSER_STORE_POOL_ID) || "",
    userPoolWebClientId: window.sessionStorage.getItem(BROWSER_STORE_WEB_CLIENT_ID) || "",
    region: getConfig().region,
    mandatorySignIn: getConfig().mandatorySignIn,
    authenticationFlowType: getConfig().authenticationFlowType,
  },
  graphql_headers: generateHeader,
});

interface OwnProps {
  /**
   * layout children elements.
   */
  children?: any;
}

/**
 * Application layout page contains top and left tool bars.
 */
export const PiletLayout = withRouter(
  withStyles(theme => ({
    root: {
      position: "absolute",
      width: "100%",
      alignItems: "flex-start",
      left: 0,
      right: 0,
      top: 0,
      background: "#FFFFFF",
      pointerEvents: "none",
      "& > *": {
        pointerEvents: "auto",
      },
      minWidth: 300,
      minHeight: 500,
      [theme.breakpoints.down("md")]: {
        height: "100vh",
        marginTop: 0,
        padding: "0 15px",
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {

      }
    },
    drawerWrap: {
      paddingLeft: 120,
      paddingTop: 60,
      width: "100%",
      transition: "width 0.3s",
      flexDirection: "column",
      background: "#fff",
      alignItems: "flex-start",
      minWidth: "100%",
      minHeight: "60vh",

      [theme.breakpoints.between("xs", "sm")]: {
        margin: 0,
        padding: 0,
        width: "100%",
      }
    },
    noDrawerWrap: {
      background: "#fff",
      alignItems: "flex-start",
      paddingBottom: 48,
      marginTop: 0,
      marginLeft: 0,
      width: "100%",
      minWidth: 300,
      minHeight: 500,
      [theme.breakpoints.down("xs")]: {
        width: "97%",
        height: 500,
      },
      // [theme.breakpoints.up("sm")]: {
      //   display: "none",
      // },
    },
    uxBelowSm: {
      display: "none",
      [theme.breakpoints.between('xs', 'sm')]: {
        display: "block"
      }
    },
    uxAboveSm: {
      display: "none",
      [theme.breakpoints.between('md', 'xl')]: {
        display: "block"
      }
    },
    onlyChildren: {
      minWidth: 300,
      minHeight: 500,
      margin: 55,
      [theme.breakpoints.down("xs")]: {
        width: 300,
        height: 500,
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        margin: 15
      }
    }
  }))(
    ({
      classes,
      children,
      location,
      history
    }: OwnProps & WithStyles & RouteComponentProps) => {

      const hideMenu = window.sessionStorage.getItem(BROWSER_STORE_IS_MENU_HIDDEN) || "false";

      React.useEffect(() => {
        const interval = setInterval(() => {
          handleInactiveUser();
        }, 1000 * 60 * 5);
        return () => clearInterval(interval);
      }, []);

      /**
      * An handle Inactive User event 
      */
      const handleInactiveUser = async () => {
        try {
          const isValid = ((await Auth.currentSession()) as any).isValid();
          if (!isValid) {
            onLogout();
          }
        }
        catch (err) {
          console.log("Auto signOut ", err)
          onLogout();
        }
      }

      /**
      * An logout function when token not valid
      */
      const onLogout = async () => {
        await Auth.signOut({ global: true });
        window.sessionStorage.clear();
        history.push(route("app.shell.login"));
        i18n.changeLanguage();
      };
      const smallScreen = hideMenu.toLowerCase() === "true";
      return (<>
        <div>
          <div className={smallScreen ? classes.onlyChildren : classes.root}>
            {!smallScreen && <Menu type="general" />}
            <div className={
              smallScreen ? classes.noDrawerWrap : classes.drawerWrap}>
              {children}
            </div>
            {!smallScreen && <TopBar location={location} history={history} />}
            <FeedbackDrawer />
          </div>
        </div>
      </>);
    }
  )
);
