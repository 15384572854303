import React from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

/**
 *Error alert
 */
interface IOwnProps extends WithStyles<"root" | "backgroundColor"> {
  /**
   * Error message
   */
  text: string;
  /**
   * Show error alert
   */
  enableError: boolean;
}

export const AlertError = withStyles(theme=>({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  backgroundColor: {
    borderRadius: 0,
    backgroundColor: "#D31E1E",
  },
}))(({ classes, text, enableError }: IOwnProps) => {
  return enableError ? (
    <div className={classes.root}>
      <Alert
        variant="filled"
        severity="error"
        className={classes.backgroundColor}
      >
        {text}
      </Alert>
    </div>
  ) : null;
});
