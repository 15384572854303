import * as React from "react";
import { EnglishLang } from "./lang/english";
import { FrenchLang } from "./lang/french";
import { GermanLang } from "./lang/german";
import { SpanishLang } from "./lang/spanish";
/**
 * Back propagation button
 */
interface IOwnProps {
  /**
   * Value
   */
  value: string;
}

export const LangIcon = ({ value }: IOwnProps) => {
  return (
    <span style={{ cursor: "pointer", fontSize: 24, paddingRight: 5 }}>
      {
        {
          en: (<EnglishLang />),
          de: (<GermanLang />),
          fr: (<FrenchLang />),
          es: (<SpanishLang />),
        }[value.toLowerCase()]
      }
    </span>
  );
};
