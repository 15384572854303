import { BasePresenter } from "@pentair/react";
import { NetworkModule } from "../../../../application/network";

/**
 * Component state
 */
interface IComponentState {
    // To enable or disable component state
    disable: boolean;
    // Time stamp when the T&C accepted
    termsAndConditionAccepted: string;
    // Time stamp when the news subscriptions accepted
    newsSubscriptionAccepted: string;
    // An error state
    error: any;
}

/**
 * Class component to handle the TnC view logic
 */
export class TnCDialogPresenter extends BasePresenter<IComponentState> {

    // On accept callback to continue
    public onContinue: (termsAndConditionAccepted: string, newsSubscriptionAccepted: string) => any;

    // On logout action callback
    public onLogout: () => void;

    public state: IComponentState = {
        disable: true,
        newsSubscriptionAccepted:``,
        termsAndConditionAccepted:``,  
        error: {
            errorMessage: "",
            showError: ""
        }
    }

    /**
     * Constructor
     * @param _network A network module to query APIs
     */
    constructor(protected _network: NetworkModule) {
        super();
    }

    /**
     * Set local state object
     * @param state New state
     */
    public change = (state: Partial<IComponentState>) => {
        this.setState({ ...this.state, ...state });
    }

    /**
     * Mount event
     */
    public mount = async () => { }

    /**
     * On back button action navigation
     */
    public onBackAction = async () => {
        this.change({
            termsAndConditionAccepted: '',
            newsSubscriptionAccepted: '',
            disable: true
        });
        this.onLogout();
    }

    /**
     * A callback for "I agree" button click
     */
    public onContinueAction = async () => {
        if (!this.state.termsAndConditionAccepted) {
            return;
        }
        // Continue to process
        this.onContinue(
            this.state.termsAndConditionAccepted,
            this.state.newsSubscriptionAccepted
        );
    }
    
    /**
     * On Terms and condition checkbox event trigger
     * @param _event The checkbox event
     */
    public onValueChange = (_event: any) => {
        const value = _event.target.checked;
        const property = _event.target.name;
        const timeStamp = value ? `${new Date().getTime()}` : '';

        switch (property) {
            case "termsAndConditionAccepted":
                this.change({
                    termsAndConditionAccepted: timeStamp,
                    disable: value !== true
                });
                break;

            case "newsSubscriptionAccepted":
                this.change({ newsSubscriptionAccepted: timeStamp });
                break;
            default: // Empty default as unreachable
                break;
        }
    }

    /**
     * Un-mount event
     */
    public unmount = async () => { }
}