import { Amplify, Auth } from "aws-amplify";
import { BROWSER_STORE_DEF_CONFIG, BROWSER_STORE_DOMAINS, BROWSER_STORE_POOL_ID, BROWSER_STORE_WEB_CLIENT_ID, MICRO_APP_NAME } from "../../config/constants";
import { IConfigType, __CONFIGS__ } from "../../config/default-config";
import { IAwsConfig, IMicroAppFeature } from "../common/types";
import { queryGetDomains } from "./login/graphql/queries";
import { api } from "./user";

/**
 * Load default config from JSON i.e. config.json
 */
export const loadDefaultConfigJson = async () => {
  return fetch("/config.json")
    .then((r) => r.text())
    .then((txt) => {
      if (txt) {
        const configuration: IConfigType = {
          ...__CONFIGS__,
          ...JSON.parse(txt),
        };
        window.sessionStorage.setItem(
          BROWSER_STORE_DEF_CONFIG,
          JSON.stringify(configuration),
        );
        return configuration;
      }
    })
    .catch((error) => {
      throw (error);
    });
};

/**
 * Configuring Amplify configuration based on login Default login  or User login
 * @param identity
 * @param configureLocalPool
 */
export const configureAmplify = async (
  identity: IAwsConfig,
  configureLocalPool: boolean,
) => {
  if (identity.userPoolId && identity.userPoolWebClientId) {
    await Amplify.configure({
      Auth: {
        userPoolId: identity.userPoolId,
        userPoolWebClientId: identity.userPoolWebClientId,
        region: identity.region,
        mandatorySignIn: identity.mandatorySignIn,
        authenticationFlowType: identity.authenticationFlowType,
      },
    });
    if (configureLocalPool) {
      window.sessionStorage.setItem(BROWSER_STORE_POOL_ID, identity.poolID);
      window.sessionStorage.setItem(
        BROWSER_STORE_WEB_CLIENT_ID,
        identity.clientID,
      );
    }
  } else {
    console.log("No Identity is provided");
  }
};

export const defaultUserLogin = async () => {
  const configuration: IAwsConfig = await loadDefaultConfigJson();
  try {
    await configureAmplify(configuration, false);
  } catch (error) {
    console.error("error configuring amplify", error);
    return;
  }
  if (configuration.pentair_user_name && configuration.pentair_user_password) {

    const result = await Auth.signIn(configuration.pentair_user_name, configuration.pentair_user_password);
    const gqlEndPoint = result.signInUserSession.idToken.payload.appsync
    /**
     * Storing App-shell appsync into sessionStorage when user login
     */
    window.sessionStorage.setItem(`${MICRO_APP_NAME}-appsync`, gqlEndPoint);

    Amplify.configure({
      API: {
        aws_appsync_graphqlEndpoint: gqlEndPoint,
        aws_appsync_region: configuration.aws_appsync_region,
        aws_appsync_authenticationType:
          configuration.aws_appsync_authenticationType,
      },
    });
    const microAppDetails: IMicroAppFeature[] = [];

    const domains = await api(queryGetDomains, "fetchPilets");
    domains.forEach((item: IMicroAppFeature) => {
      microAppDetails.push({ ...item });
    });

    window.sessionStorage.setItem(
      BROWSER_STORE_DOMAINS,
      JSON.stringify(microAppDetails),
    );

    return result;
  }

  return;
}
