import * as React from "react";
import { RouteComponentProps } from "react-router";
import {
    TextField,
    Typography
} from "@material-ui/core";
import { AuthLayout } from "../common/auth-layout";
import { PasswordField } from "../../../components/password";
import { AlertError } from "../../../components/mui-alert/alert";
import { makePresenter } from "../../../application/application";
import { ResetOldPasswordPresenter } from "./reset-old-password-presenter";
import { useTranslate } from "../../../components/translator/use-translator";
import LoadingIcon from "../../../components/loading-icon";

/**
 * An utility to create the presenter instance
 */
const usePresenter = makePresenter(
    () => new ResetOldPasswordPresenter(),
    (presenter, props: RouteComponentProps) => {
        presenter.history = props.history;
        presenter.location = props.location;
    }
);

/**
 * A reset old password component definition
 */
export const ResetOldPassword: React.FC<RouteComponentProps> = (props) => {

    const { state,
        onKeyPress,
        onContinueClick,
        onChange,
        onNavigateBack
    } = usePresenter(props);

    const {
        isLoading,
        error,
        disable
    } = state;

    return (
        <AuthLayout
            isBackExists={true}
            onClick={onContinueClick}
            enableLoginButton={disable}
            navigateBack={onNavigateBack}
            buttonText={useTranslate("login:RESET PASSWORD")}
        >
            <TextField
                fullWidth
                onChange={onChange}
                margin="normal"
                placeholder="000000"
                id="code"
                name="code"
                disabled={disable}
                onKeyPress={onKeyPress}
                label={<div>{useTranslate("login:Code")}</div>}
            />
            <PasswordField
                fullWidth
                onChange={onChange}
                margin="normal"
                placeholder="Enter new password"
                id="password"
                name="password"
                onKeyPress={onKeyPress}
                disabled={disable}
                autoComplete="currentPassword"
                label={<div>{useTranslate("login:New Password")}</div>}
            />
            <PasswordField
                fullWidth
                onChange={onChange}
                margin="normal"
                placeholder={useTranslate("login:Enter password again")}
                id="confirmPassword"
                name="confirmPassword"
                onKeyPress={onKeyPress}
                disabled={disable}
                autoComplete="currentPassword"
                label={<div>{useTranslate("login:Confirm New Password")}</div>}
            />
            <Typography paragraph variant="body2"> {useTranslate("login:message1")}</Typography>
            <div>
                <AlertError
                    text={error.errorMessage}
                    enableError={error.showError}
                />
            </div>
            {isLoading > 0 ? <LoadingIcon /> : null}
        </AuthLayout>
    )
}