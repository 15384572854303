import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  IconButton as MuiIconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import {
  PentairBlue,
  PentairSilverGray,
  PentairBrightBlue,
} from "@pentair/react";
import { Link } from "react-router-dom";

interface OwnProps extends Partial<IconButtonProps> {
  icon: IconName;
  active?: boolean;
  variant?: "default" | "primary";
  onClick?(e: any): void;
  to?: string;
}
/**
 * Icon button to show icons
 * @param props
 */
const useStyles = (props: OwnProps) => {
  return makeStyles((theme: Theme) => ({
    root: {
      borderRadius: 0,
      "&:hover": {
        "& $icon": {
          "& >:first-child": {
            opacity: 0,
          },
          "& >:last-child": {
            opacity: 1,
          },
        },
      },
    },
    profileIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      fontSize: theme.spacing(1),
      backgroundColor: "#06265A",
      fontWeight: 500,
      font: "Barlow Bold",
      cursor: "pointer"
    },
    profileIconActive: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      fontSize: theme.spacing(1),
      backgroundColor: "#FFF",
      opacity: 0.7,
      fontWeight: 650,
      font: "Barlow Bold",
      color: "#06265A",
      cursor: "pointer"
    },
    icon: {
      color: PentairSilverGray[500],
      position: "relative",
      "& >:first-child": {
        opacity: 1,
        position: "absolute",
        transform: "translate(-50%, -50%)",
      },
      "& >:last-child": {
        opacity: 0,
        position: "absolute",
        transform: "translate(-50%, -50%)",
      },
      "&$primary": {
        color: "#fff",
      },
    },
    default: {
      color: PentairSilverGray[500],
      backgroundColor: "inherit",
      "& $icon": {
        "& >:last-child": {
          color: PentairBrightBlue[500],
        },
      },

      "&:hover": {
        "& $icon": {
          backgroundColor: PentairBrightBlue[500],
        },
      },
    },
    primary: {
      color: "#fff",
      backgroundColor: PentairBlue[500],
    },
    active: {
      "&$primary": {
        backgroundColor: "#fff",
      },
      "& $icon": {
        "& >:first-child": {
          opacity: 0,
        },
        "& >:last-child": {
          opacity: 1,
          color: PentairBrightBlue[500],
        },
      },
    },
    active_2: {
      "&$primary": {
        backgroundColor: "transparent",
      },
      "& $icon": {
        "& >:first-child": {
          opacity: 1,
        },
        "& >:last-child": {
          opacity: 1,
          color: "#FFF",
        },
      },
    }
  }));
};

/**
 * Link button UI components definition
 * @param props 
 * @returns 
 */
export function LinkIconButton(props: OwnProps) {
  const classes = useStyles(props)();
  const variant = props.variant || "default";

  return (
    <Link to={props.to || ""} onClick={props.onClick}>
      <MuiIconButton
        size={"small"}
        className={classNames(
          classes.root,
          props.active && classes.active,
          classes[variant]
        )}
        style={{
          height: 12,
          marginTop: 16
        }}
        {...props}
      >
        <div className={classNames(classes.icon, classes[variant])}>
          <FontAwesomeIcon icon={["fal", props.icon]} size={"sm"} />
          <FontAwesomeIcon icon={["fas", props.icon]} size={"sm"} />
        </div>
      </MuiIconButton>
    </Link>
  );
}

/**
 * Link button UI components definition
 * @param props 
 * @returns 
 */
 export function ProfileIconButton(props: any) {
  const classes = useStyles(props)();
  const variant = props.variant || "default";

  return (
    <Link to={props.to || ""} onClick={props.onClick}>
      <MuiIconButton
        size={"small"}
        className={classNames(
          classes.root,
          props.active && classes.active,
          classes[variant]
        )}
        style={{
          height: 12,
          marginTop: 16
        }}
        {...props}
        >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
            <div className={classNames(classes.icon, classes[variant])}>
              <Avatar className={classes.profileIcon}>{props?.userName || ""}</Avatar>
              <Avatar className={classes.profileIconActive}>{props?.userName || ""}</Avatar>
            </div>
        </span>
      </MuiIconButton>
    </Link>
  );
}

/**
 * Icon button UI components definition
 * @param props 
 * @returns 
 */
export function IconButton(props: OwnProps) {
  const classes = useStyles(props)();
  const variant = props.variant || "default";

  return (
    <MuiIconButton
      size={"small"}
      className={classNames(
        classes.root,
        props.active && classes.active,
        classes[variant]
      )}
      {...props}
    >
      <div className={classNames(classes.icon, classes[variant])}>
        <FontAwesomeIcon icon={["fal", props.icon]} size={"sm"} />
        <FontAwesomeIcon icon={["fas", props.icon]} size={"sm"} />
      </div>
    </MuiIconButton>
  );
}

/**
 * Favorite button UI components definition
 * @param props 
 * @returns 
 */
export function FavoriteButton(props: OwnProps) {
  const classes = useStyles(props)();
  const variant = props.variant || "default";

  return (
    <MuiIconButton
      size={"small"}
      className={classNames(
        classes.root,
        props.active && classes.active_2,
        classes[variant]
      )}
      {...props}
    >
      <div className={classNames(classes.icon, classes[variant])}>
        <FontAwesomeIcon icon={["fal", props.icon]} size={"sm"} />
        <FontAwesomeIcon icon={["fas", props.icon]} size={"sm"} />
      </div>
    </MuiIconButton>
  );
}