/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { History } from "history";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Breadcrumbs,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "aws-amplify";
import { getSessionData, PentairBlue } from "@pentair/react";
import {
  routes,
  IRoute,
  route
} from "../../config/routes";
import {
  IFavorite,
  IFeature,
  IMicroAppFeature,
  IRedirectEvent,
  IUpdateEvent
} from "./types";
import { IconButton } from "../../components/mui-extended/mui-icon-button";
import {
  BROWSER_STORE_FAVORITES,
  LANDING_PAGE_OBJECT_KEY,
  BREADCRUMB_UPDATE_EVENT,
  REDIRECT_EVENT,
  BROWSER_STORE_SHELL_PERMISSIONS,
  MICRO_APP_NAME,
} from "../../config/constants";
import { MicroAppPiletSingleton } from "../../default";
import i18n from "../../i18n";

const logo = require("../../images/logo.svg") as string;

/**
 * Component props
 */
interface IOwnProps {
  location: any;
  history: History;
}

/**
 * Component styles definitions
 */
const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    top: 0,
    left: 48,
    width: "98%",
    height: 42,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logoLink: {
    display: "inline-flex",
    alignItems: "center",
    textDecoration: "none",
    position: "relative",
    paddingTop: 6,
  },
  logoutButtonSm: {
    margin: 0,
    top: 5,
    right: 5,
    bottom: "auto",
    left: "auto",
    fontWeight: 300,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  appBarSm: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  crumb: {
    color: PentairBlue[500],
    textDecoration: "none",
    fontWeight: 600,
    cursor: "pointer",
  },
  currentCrumb: {
    color: PentairBlue[500],
    textDecoration: "none",
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

/**
 * Top bar with user profile information and Breadcrumb
 */
export const TopBar: React.FC<IOwnProps> = (props) => {

  const routeDetails: any = [];

  const [updateEventState, setUpdateEventState] = React.useState<IUpdateEvent>();

  const [, updateState] = React.useState<any>();
  const refreshBar = React.useCallback(() => updateState({}), []);

  /**
   * Style instance
   */
  const classes = useStyles(props);

  /**
   * Theme instance
   */
  const appTheme = useTheme();

  /// Location props
  const location = props.location as any;
  const { pathname } = location;

  /**
   * User key Account Id
   */
  const [keyAccount, setKeyAccount] = React.useState({ name: "", id: "" });

  /**
   * Reading default path for current route from route state in order to show as prefix route
   * example for user management or product management default path is Manage (Administration)
   */
  const path = (props.location.state && location.state["defaultPath"]) || null;


  const favories = getSessionData(BROWSER_STORE_FAVORITES, []);
  const landingPage = favories?.find((fav: any) => fav.key === LANDING_PAGE_OBJECT_KEY);
  if (landingPage) {
    try {
      const favoriteRoute = JSON.parse(landingPage?.value);
      if (favoriteRoute?.pathname?.toLocaleLowerCase() === pathname?.toLocaleLowerCase()) {
        // Create the location object to navigate
        location.state = {
          ...favoriteRoute?.state,
          locationName: favoriteRoute.state?.locationName,
          details: favoriteRoute.state?.details,
          isActive: true,
        };
      }
    } catch (ex) {
      console.warn("error parsing favorit", ex);
    }
  }

  /**
   * Reading user key account id in current user session
   */
  React.useEffect(() => {
    const getKeyAccount = async () => {
      try {
        const idToken = ((await Auth.currentSession()) as any).idToken;
        setKeyAccount({
          name: idToken.payload["keyaccountname"],
          id: idToken.payload["keyaccountid"],
        });
      }
      catch (ex) {
        onLogout();
      }
    };
    getKeyAccount();
  }, []);

  /// Listen the Breadcrumb updates
  if (MicroAppPiletSingleton.getInstance()) {
    const app = MicroAppPiletSingleton.getInstance().getPiralInstance();
    app.on(BREADCRUMB_UPDATE_EVENT, (data: IUpdateEvent) => {
      refreshBar();
      setUpdateEventState(data);
    });
  }

  /**
   * Reading all micro apps information based on user permissions in order to get micro app description and features descriptions (used to show in breadcrumb)
   */
  const microApps: IMicroAppFeature[] = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);

  /**
   * Splitting current route
   */
  const pathNames = pathname.split("/").filter((x: any) => x);

  /** No default route is defined in pilet route level it will take first route as default route */
  const defaultPath = path === null ? pathname : path;

  /**
   * Get the default path of the micro app feature
   * @param microAppName Get the path for given micro-app
   */
  const getDefaultPath = (microAppName: string) => {
    const path = microApps?.find((microApp: IMicroAppFeature) => microApp.microapp === microAppName)?.features[0]?.path;
    if (path && path.lastIndexOf(":keyAccountId") > -1) {
      const url = path.replace(":keyAccountId", keyAccount.id);
      return path ? `/${microAppName}/${url}` : null;
    }
    return path ? `/${microAppName}/${path}` : null;
  }

  /**
   * On logout action callback
   */
  const onLogout = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      await Auth.signOut({ global: true });
    } catch (error) { }
    window.sessionStorage.clear();
    props.history.push(route("app.shell.login"));
    i18n.changeLanguage();
  };

  /**
   * Reading all routes from route configuration and storing in local array as key value pair for route description
   * Routes are defined in route config file
   */
  routes.forEach((route: IRoute) => {
    routeDetails.push({ key: route.path.substring(1), value: route?.title || '' });
  });

  /**
   * Reading key account from user session and storing in local array as key value pair in order to show key account name in breadcrumb (dashboards)
   */
  if (keyAccount) {
    routeDetails.push({ key: keyAccount.id, value: keyAccount.name });
  }

  /**
   * Reading all micro app features and storing in local array as key values pair for feature descriptions in order to show in breadcrumb
   */
  microApps.forEach((app: IMicroAppFeature) => {
    routeDetails.push({ key: app.microapp, value: app.title });
    app.features.forEach((feature: IFeature) => {
      routeDetails.push({ key: feature.path, value: feature.title });
    });
  });

  /**
   * On click of Home button, redirect to favorite page if set,
   * else redirect to admin home
   * @returns an URL
   */
  const redirectTo = () => {
    try {
      const app = MicroAppPiletSingleton.getInstance().getPiralInstance();
      const redirect: IRedirectEvent = {
        history: props.history as any,
        locationState: props.location,
        path: ``,
      }
      const favorites = getSessionData(BROWSER_STORE_FAVORITES, []) as IFavorite[];
      if (favorites && favorites.length) {
        const favoritesArr: IFavorite[] = favorites;
        let landingPage = favoritesArr?.find((fav: IFavorite) => fav.key === LANDING_PAGE_OBJECT_KEY)
        let favoriteRoute: any;
        try {
          favoriteRoute = JSON.parse(landingPage.value);
          redirect.path = favoriteRoute && favoriteRoute.pathname ? favoriteRoute.pathname : route("app.shell.administration");

        } catch (ex) {
          console.error("Error parsing favorit", ex);
        }
        if (landingPage?.description || landingPage?.value) {
          app.emit(REDIRECT_EVENT, redirect);
          return;
        }
      }
      redirect.path = route("app.shell.administration");
      app.emit(REDIRECT_EVENT, redirect);
    } catch (err) {
      props.history.push(route("app.shell.administration"));
    }
  }

  return (
    <AppBar
      className={classes.root}
      elevation={0}
      position="static"
      color="inherit"
    >
      <Toolbar
        variant="dense"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Breadcrumbs
          style={{ fontSize: "inherit", fontFamily: "inherit" }}
          separator={
            <div>
              <span style={{ fontSize: 20, color: "#EBEBEB" }}> &#10095; </span>
            </div>
          }
          aria-label="breadcrumb"
        >
          {/* Brand logo: On click will redirect to default Admin/management page */}
          <span>
            <Link to={route("app.shell.administration")} className={`${classes.logoLink}`}>
              <img src={logo} style={{ height: 28 }} />
            </Link>
          </span>
          {/* Home Button: On click will redirect to favorite page if set or else to default Admin/management page */}
          <span data-testid="home-button">
            <FontAwesomeIcon
              id="home-button"
              icon={["fas", "home"]}
              style={{ height: 16, width: 16, cursor: "pointer" }}
              onClick={(event: any) => redirectTo()}
              color={appTheme.palette.primary.main}
            />
          </span>
          {/* Iterating through all routes from browser router and filtering
          descriptions for the routes from local array by filtering key example
          route : "user-overview" array key user-overview array value :Users
          overview in order to show in breadcrumb */}
          {pathNames.map((name: string, index: any) => {
            let routeTo;
            if (microApps.find((microApp: IMicroAppFeature) => microApp.microapp === name)) {
              routeTo = getDefaultPath(name) || defaultPath;
            } else {
              routeTo = `/${pathNames.slice(0, Number(index) + 1).join("/")}`;
            }

            /**
             * Reading location state details in order to display in bread crumb ( for edit records we need to read from route state)
             * state is not available we are reading values from local key value pair array
             */
            let description: string[] = [];
            if (location.state && location.state["details"] && pathname !== getDefaultPath(pathNames[0])) {
              description = [...location.state["details"]];
            } else {
              if (updateEventState) {
                const value = routeDetails.find((p: any) => p.key === name)?.value;
                name = value || name;
                name = updateEventState.data.key === name ? updateEventState.data.value : name;
              } else {
                name = routeDetails.find((p: any) => p.key === name)?.value;
              }
            }

            return (
              <span
                style={{ textTransform: "uppercase", fontWeight: 600 }}
                key={index}
              >
                <Link
                  className={
                    classes[
                    Number(index) === pathNames.length - 1
                      ? "currentCrumb"
                      : "crumb"
                    ]
                  }
                  to={{
                    pathname: routeTo,
                    state: { ...location.state },
                  }}
                >
                  {description === null || description.length === 0
                    ? name
                    : description[Number(index)]}
                </Link>
              </span>
            );
          })}
        </Breadcrumbs>
        <IconButton icon="sign-out" onClick={onLogout} />
      </Toolbar>
    </AppBar>
  );
}
