import * as React from "react";
import {
  TextField,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { PentairBlue } from "@pentair/react";
import { AuthLayout } from "../../common/auth-layout";
import { PasswordField } from "../../../../components/password";
import { AlertError } from "../../../../components/mui-alert/alert";
import { route } from "../../../../config/routes";
import { useTranslate } from "../../../../components/translator/use-translator";

interface OwnProps extends WithStyles<"footer" | "forgetPassword"> {
  /**
   * Authenticating user login
   */
  login: () => any;
  credentials: any;
  onKeyPress: (event: any) => any;
  /**
   * Storing user details in state object
   */
  onChange: (e: any) => any;

  /**
   * Enable or Disable login button
   */
  enableLoginButton: boolean;
  alert: any;
}

/**
 * Login section
 **/
export const LoginSection = withStyles({
  root: {},
  footer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  forgetPassword: {
    color: PentairBlue[500],
    textDecoration: "none",
    fontWeight: 600,
  },
})(
  ({
    classes,
    login,
    onChange,
    credentials,
    enableLoginButton,
    onKeyPress,
    alert,
  }: OwnProps) => {

    return (
      <AuthLayout
        isBackExists={false}
        onClick={login}
        enableLoginButton={enableLoginButton}
      >
        <form noValidate autoComplete="off">
          <TextField
            fullWidth
            onChange={onChange}
            margin="normal"
            placeholder={useTranslate("login:User Name")}
            id="userName"
            name="userName"
            value={credentials?.userName}
            disabled={enableLoginButton}
            label={<div>{useTranslate("login:User Name")}</div>}
          />
          <PasswordField
            fullWidth
            onChange={onChange}
            onKeyPress={onKeyPress}
            margin="normal"
            placeholder={useTranslate("login:Password")}
            id="password"
            name="password"
            value={credentials?.password}
            autoComplete="current-password"
            disabled={enableLoginButton}
            label={<div>{useTranslate("login:Password")}</div>}
          />
          <br />
          <AlertError
            text={alert.errorMessage}
            enableError={alert.enableError}
          />
          <div className={classes.footer}>
            <Link to={route("app.shell.forgetpassword")} className={classes.forgetPassword}>
              {useTranslate("login:Forgot password")}
            </Link>
          </div>
        </form>
      </AuthLayout>
    );
  }
);
