import { BasePresenter, IDropdownDataModel } from "@pentair/react";
import { Auth } from "aws-amplify";
import { NetworkModule } from "../../../application/network";
import { setLanguage } from "../../../components/translator/use-translator";
import { BROWSER_STORE_I18NEXTLNG, BROWSER_STORE_LANGUAGES, MICRO_APP_NAME } from "../../../config/constants";
import { queryGetLanguage } from "../login/graphql/queries";
/**
 * A layout page state
 */
interface IOwnState {
  languages: IDropdownDataModel[],
  selectedLang: string;
}
/**
 * Class component for auth layout
 */
export class AuthLayoutPresenter extends BasePresenter<IOwnState> {
  ///Default language
  private defaultLang = [{
    id: "EN", name: "English"
  }]

  public state: IOwnState = {
    languages: [] as IDropdownDataModel[],
    selectedLang: ""
  }
  /**
    * Constructor
    * @param props Props instance
    */
  constructor(private _network: NetworkModule) {
    super();
  }

  /**
     * Mount event
     */
  public mount = async () => {
    const browserLang = sessionStorage.getItem(BROWSER_STORE_I18NEXTLNG) || "en";
    const browserLanguages = sessionStorage.getItem(BROWSER_STORE_LANGUAGES) || "";
    try {
      ///Check if value already available in session
      if (browserLanguages && browserLanguages.length > 0) {
        const languages = JSON.parse(browserLanguages);
        this.change({ languages: languages, selectedLang: browserLang });
      }
      else {
        /// If valid user or active user present. Once 
        const isValid = ((await Auth.currentSession()) as any).isValid();
        if (isValid) {
          const response: any = await this._network.fetch(queryGetLanguage, '', MICRO_APP_NAME);
          if (!response.errors && response?.data && response.data?.getLanguage?.length > 0) {
            sessionStorage.setItem(BROWSER_STORE_LANGUAGES, JSON.stringify(response.data.getLanguage))
            this.change({ languages: response.data.getLanguage, selectedLang: browserLang });
          }
          else {
            this.change({
              languages: this.defaultLang, selectedLang: browserLang
            })
          }
        }
        else {
          this.change({
            languages: this.defaultLang, selectedLang: browserLang
          })
        }
      }
    } catch (error) {
      this.change({
        languages: this.defaultLang, selectedLang: browserLang
      })
    }
  }
  /**
   * This method will called on language change
   * @param event Event change
   */
  public languageChange = (event: any) => {
    const lang: string = event.target.value || "en";
    setLanguage(lang?.toLowerCase());
    this.change({ selectedLang: lang });
  }
  /**
   * Set local state object
   * @param state New state
   */
  public change = (state: Partial<IOwnState>) => {
    this.setState({ ...this.state, ...state });
  }

  /**
  * Un-Mount event
  */
  public unmount = () => {
  }
}