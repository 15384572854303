import * as React from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { Auth } from "aws-amplify";
import {
  getSessionData,
  PageTitles,
  PentairBlue,
  PentairDarkGray,
  setSessionData
} from "@pentair/react";
import {
  IFavorite,
  IFeature,
  IMicroAppFeature,
  IRedirectEvent
} from "./types";
import {
  IRoute,
  route,
  routes
} from "../../config/routes";
import { mutationSaveUserFavorite } from "./graphql/mutation";
import { AlertError } from "../../components/mui-alert/alert";
import {
  BROWSER_STORE_DEF_LANDING_PAGE,
  BROWSER_STORE_FAVORITES,
  BROWSER_STORE_PILET_INFO,
  BROWSER_STORE_SHELL_PERMISSIONS,
  LANDING_PAGE_OBJECT_KEY,
  MICRO_APP_USER_MANAGEMENT,
  REDIRECT_EVENT,
  MICRO_APP_NAME,
} from "../../config/constants";
import { ConfirmDialog } from "../../components/confirmation-dialog";
import { NetworkModule } from "../../application/network";
import { MicroAppPiletSingleton } from "../../default";
import { useTranslate } from "../../components/translator/use-translator";

/**
 * Component props
 */
interface IOwnProps {
  open: number;
  location: any;
}

/// Constants to control the favorite page setup
export const CAN_SET_FAV = "CAN_SET_FAV";
export const CAN_NOT_SET_FAV = "CAN_NOT_SET_FAV";

/**
 * Favorite Drawer component definition to alow user to view and set
 * default/favorite landing page.
 */
export const Favorite: React.FC<IOwnProps> = (props) => {
  // Access state constants
  const {
    open,
    location,
  } = props;

  // Favorite drawer display state
  const [openDrawer, setOpenDrawer] = React.useState<number>(0);

  // Flag to maintain whether current page is favorite page
  const [isFavoritePageFlag, setFavoritePageFlag] = React.useState<boolean>(false);

  // Current landing page state
  const [currentLandingDetails, setCurrentLandingDetails] = React.useState<string>("");

  // Confirmation dialog display state
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false)

  // Alert message state
  const [alert, setAlert] = React.useState<any>({
    errorMessage: "errorEmptyMsg",
    enableError: false
  });

  //////Call the method on mount only.
  React.useEffect(() => {
    setFavoritePageFlag(isFavoritePage());
    setDefaultLandingPage();
  }, [openDrawer, isFavoritePageFlag]);

  /**
   * To validate the subroute i.e.
   *  If route is `/user-management/useroverview`
   *      then it will validate the `/useroverview` route is exists
   * @param featurePath Feature path i.e. `useroverview`
   * @param microApp A Micro-app
   * @param keyaccountId key account ID if needed
   * @returns feture path
   * @throws error if route not matching
   */
  const validateSubRoute = (featurePath: string, microApp: IMicroAppFeature, keyaccountId: string) => {
    /// If no features for micro-app, throw error
    if (!microApp?.features?.length) {
      throw "Path is invalid. Redirected to 'Admin Page'."
    }
    let path: string | null = "";
    /// Look through the features to get the sub-route
    for (let feature of microApp?.features) {
      if (feature.path) {
        if (featurePath === feature.path) {
          path = feature.path;
          break;
        } else if (feature.path.lastIndexOf(":keyAccountId") > -1) {
          path = feature.path.replace(":keyAccountId", keyaccountId);
          path = (path === featurePath) ? path : null;
          break;
        }
        path = null;
      }
    }
    /// Validate path, if exists or throw error
    if (path && path.lastIndexOf(":keyAccountId") > -1) {
      const url = path.replace(":keyAccountId", keyaccountId);
      return url;
    } else if (!path) {
      throw "Path is invalid. Redirected to 'Admin Page'."
    }
    return path;
  }

  /**
   * To validate the route i.e. `/user-management/useroverview/xx/yy/...`
   *  If route is no matching `/user-management/useroverview`
   *      then it will throw an error
   * @param session Current session
   * @param path current route
   * @param appPermission micro-app 
   * @throws error if route not matching
   */
  const validateLandingPage = (session: any, path: string, appPermission: IMicroAppFeature) => {
    try {
      const idToken = (session as any).idToken;
      const keyaccountId = idToken.payload["keyaccountid"];
      /**
       * Splitting current route
       **/
      const paths = path.split("/").filter((x: any) => x);
      let routeTo;

      if (paths?.length >= 2 && paths[0] === appPermission.microapp) {
        routeTo = `/${appPermission.microapp}/${validateSubRoute(paths[1], appPermission, keyaccountId)}`;
      }
      /// Compare the route with feature path, if not found there then throw an error
      if (!routeTo || !path.startsWith(routeTo)) {
        throw "Path is invalid. Redirected to 'Admin Page'.";
      }
    } catch (error) {
      throw error;
    }
  }


  /**
   * Set default landing page
   */
  const setDefaultLandingPage = async () => {
    try {
      const session = await Auth.currentSession();
      const defaultLandingPage = window.sessionStorage.getItem(BROWSER_STORE_DEF_LANDING_PAGE) || "";
      /////If already displayed don't bind again
      if (!defaultLandingPage) {
        let favorites: IFavorite[] = getSessionData(BROWSER_STORE_FAVORITES, []) as IFavorite[];
        let favoritesLandingPage;
        let favoriteRoute: any;
        if (favorites && favorites.length) {
          let pathName = "";
          favoritesLandingPage = favorites?.find((fav: any) => fav.key === LANDING_PAGE_OBJECT_KEY);
          try {
            if (favoritesLandingPage?.value) {
              favoriteRoute = JSON.parse(favoritesLandingPage.value);
              pathName = favoriteRoute && favoriteRoute.pathname ? favoriteRoute.pathname : ""
            }
            const microAppName = favoritesLandingPage?.description || "-";
            const appPermissions: IMicroAppFeature[] = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);
            const microAppFeature = appPermissions?.find((app: IMicroAppFeature) => app.microapp === microAppName) || "";
            const paths = pathName.split("/").filter((x: any) => x);
            if (microAppFeature) {
              validateLandingPage(session, pathName, microAppFeature);
              setPathname(session, pathName, favoriteRoute?.state);
            }
            ///For routes inside appShell
            else if(paths.length === 1)
            {
              setPathname(session, pathName, favoriteRoute?.state);
            }
          } catch (err) { }
        }
      }
      else {
        setCurrentLandingDetails(defaultLandingPage);
      }
    }
    catch (ex) {
      console.log("Favorite landing screen- setDefaultLandingPage", ex)
    }
  }

  /**
   * On click of Home button, redirect to favorite page if set,
   * else redirect to admin home
   * @returns an URL
   */
  const redirectTo = () => {
    const app = MicroAppPiletSingleton.getInstance().getPiralInstance();
    const redirectTo: IRedirectEvent = {
      locationState: undefined,
      path: ``,
    }

    const favorites = getSessionData(BROWSER_STORE_FAVORITES, []) as IFavorite[];
    setOpenDrawer(open);
    if (favorites && favorites.length) {
      const favoritesArr: IFavorite[] = favorites;
      let landingPage = favoritesArr?.find((fav: IFavorite) => fav.key === LANDING_PAGE_OBJECT_KEY)
      if (landingPage?.value) {
        const favoriteRoute = JSON.parse(landingPage.value);
        redirectTo.path = favoriteRoute && favoriteRoute.pathname ? favoriteRoute.pathname : route("app.shell.administration");
        app.emit(REDIRECT_EVENT, redirectTo);
        return;
      }
    }
    redirectTo.path = route("app.shell.administration");
    app.emit(REDIRECT_EVENT, redirectTo);
  }

  /**
   * Set path name
   * @param session Active session
   * @param pathname Path name
   * @param state Location state info
   */
  const setPathname = (session: any, pathname: string, state: any) => {
    try {
      if (pathname && pathname?.length) {
        const pathNames = pathname.split("/").filter((x: any) => x);
        const routeDetails: any = getRoutesArray(session)
        let currentLanding: string[] = [];
        pathNames.forEach((name: string, index: any) => {
          /**
           * Reading location state details in order to display in bread crumb ( for edit records we need to read from route state)
           * state is not available we are reading values from local key value pair array
           */
          let description: string[] = [];
          if (state && state["details"]) {
            description = [...state["details"]];
          } else {
            name = routeDetails.find((p: any) => p.key === name)?.value;
          }
          let routeDesc = description === null || description.length === 0
            ? name
            : description[Number(index)]
          currentLanding.push(routeDesc);
        })
        if (currentLanding.length > 0) {
          const landingPageDetails = currentLanding?.reduce((prev, curr) => {
            return `${prev} <span style={{ fontSize: 20, color: "#EBEBEB" }}> &#10095; </span> ${curr}`;
          });
          window.sessionStorage.setItem(BROWSER_STORE_DEF_LANDING_PAGE, landingPageDetails.toUpperCase());//
          setFavoritePageFlag(isFavoritePage());
          setCurrentLandingDetails(landingPageDetails.toUpperCase());
        }
      }
    }
    catch (ex) {
      console.log("Favorite landing screen-setPathname", ex)
    }
  }

  /**
   * Get all the micro-app routes info
   * @param session Currently logged in User session
   * @returns List of routes
   */
  const getRoutesArray = (session: any) => {
    const routeDetails: any = [];
    try {
      /**
     * Reading all micro apps information based on user permissions in order to get micro app description and features descriptions (used to show in breadcrumb)
     */
      const microApps: IMicroAppFeature[] = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);
      /**
       * Reading all routes from route configuration and storing in local array as key value pair for route description
       * Routes are defined in route config file
       */
      routes.forEach((route: IRoute) => {
        routeDetails.push({ key: route.path.substring(1), value: route.title });
      });
      /**
       * Reading key account from user session and storing in local array as key value pair in order to show key account name in breadcrumb (dashboards)
       */
      const keyAccountId = session.getIdToken().payload["keyaccountid"];
      const keyAccountName = session.getIdToken().payload["keyaccountname"];

      routeDetails.push({ key: keyAccountId, value: keyAccountName });
      /**
       * Reading all micro app features and storing in local array as key values pair for feature descriptions in order to show in breadcrumb
       */
      microApps.forEach((app: IMicroAppFeature) => {
        routeDetails.push({ key: app.microapp, value: app.title });
        app.features.forEach((feature: IFeature) => {
          routeDetails.push({ key: feature.path, value: feature.title });
        });
      });
    }
    catch (ex) {
      console.log("Favourite landing screen- getRoutesArray", ex)
    }
    return routeDetails;
  }

  /**
   * To check whether current page can be set as favorite page
   */
  const canSetAsFavoritePage = () => {
    if (location.state && location.state["details"]) {
      let description: string[] = [...location.state["details"]];
      if (description.find((p) => p === CAN_NOT_SET_FAV)) {
        return false;
      }
    }
    return true;
  }

  /**
   * To show the confirmation dialogue callback
   */
  const saveFavorite = () => {
    setConfirmOpen(true)
  }

  /**
   * On Okay of confirmation dialogue callback
   */
  const onOk = async () => {
    try {
      setAlert({
        errorMessage: "errorEmptyMsg",
        enableError: false
      });
      const session = await Auth.currentSession();
      const microAppFeatures: IMicroAppFeature[] = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);
      let canBeSetToFav: boolean = canSetAsFavoritePage();
      /**
       * Store pilet info in Description
       * This is required when landing page navigation
       */
      const piletInfo = window.sessionStorage.getItem(BROWSER_STORE_PILET_INFO) as string;

      let microApp = microAppFeatures.find((app: IMicroAppFeature) => app.domain === piletInfo)?.microapp || "";
      if (location && location?.pathname) {
        const paths = location.pathname.split("/").filter((x: any) => x);
        if (paths?.length == 1) {
          microApp = MICRO_APP_NAME;
        }
      }
      if (microApp && canBeSetToFav) {
        /**
         * Configuring amplify with user management app sync in order to save favorite url
         */
        const _network: NetworkModule = new NetworkModule();
        let favorites: IFavorite[] = [{
          key: LANDING_PAGE_OBJECT_KEY,
          value: JSON.stringify(location),
          description: microApp,
        }];
        const response: any = await _network.fetch(mutationSaveUserFavorite, {
          input: {
            userId: session.getIdToken().payload.sub,
            keyAccountId: session.getIdToken().payload["keyaccountid"],
            favourites: favorites,
          }
        },
          MICRO_APP_USER_MANAGEMENT
        );
        if (!response.errors && response.data.saveUserFavourite != null) {
          setOpenDrawer(0);
          let locationState = location.state || [];
          setPathname(session, location.pathname, locationState);
          setSessionData(BROWSER_STORE_FAVORITES, JSON.stringify(favorites));
          setFavoritePageFlag(isFavoritePage());
        }
        else {
          setAlert({
            errorMessage: "errorMsg_1",
            enableError: true
          });
        }
      }
      else {
        setAlert({
          errorMessage: "errorMsg_2",
          enableError: true
        });
      }
    }
    catch (ex) {
      setOpenDrawer(0);
      console.log(ex);
    } finally {
      setConfirmOpen(false);
    }
  }

  /**
   * On close of confirmation dialogue callback
   */
  const onClose = () => {
    setConfirmOpen(false);
  };

  /**
   * On close favorite drawer callback
   */
  const handleDrawerClose = () => {
    setOpenDrawer(0);
    setAlert({ errorMessage: "errorEmptyMsg", enableError: false });
  };

  /**
   * Update drawer state on init of component and state changes
   */
  React.useEffect(() => {
    setOpenDrawer(open);
  }, [open]);

  /**
   * Checks if current page is favorite page
   * @returns true or false
   */
  const isFavoritePage = () => {
    try {
      const favorites = getSessionData(BROWSER_STORE_FAVORITES, []) as IFavorite[];
      if (favorites && favorites.length) {
        const favoritesArr: IFavorite[] = favorites;
        let landingPage = favoritesArr?.find((fav: IFavorite) => fav.key === LANDING_PAGE_OBJECT_KEY)
        if (landingPage?.value) {
          const favoriteRoute = JSON.parse(landingPage.value);
          return favoriteRoute && favoriteRoute.pathname === window.location.pathname;
        }
      }
    } catch (err) {
      /// 
    }
    return false;
  }

  return (
    <Drawer
      open={openDrawer > 0 ? true : false}
      anchor="left"
      style={{ padding: 12 }}
      PaperProps={{
        style: { marginLeft: 48, width: 360, overflow: "hidden" },
      }}
      BackdropProps={{ style: { marginLeft: 48 } }}
      onEscapeKeyDown={handleDrawerClose}
      onBackdropClick={handleDrawerClose}
    >
      <Grid container spacing={3} style={{ margin: 0 }}>
        <Grid item>
          <PageTitles title={useTranslate("favorites:title")} subtitle={useTranslate("favorites:subTitle")} />
          <Typography variant="h5" style={{ textTransform: "unset", color: PentairDarkGray[500] }} component={"span"}>
            {useTranslate(`favorites:${currentLandingDetails?.length ? "landingPageMsg" : "noLandingPageMsg"}`)}
          </Typography>
          <br />
          <span style={{
            color: PentairBlue[500],
            textDecoration: "none",
            cursor: "pointer",
            textTransform: "uppercase",
            fontWeight: 600
          }} key={"key-fave-page-link-index"}
            onClick={(event: any) => redirectTo()}
          >
            <div dangerouslySetInnerHTML={{ __html: currentLandingDetails }} />
          </span>
        </Grid>
        <Grid item>
          {canSetAsFavoritePage() ?
            <AlertError
              text={useTranslate(`favorites:${alert.errorMessage}`)}
              enableError={alert.enableError}
            />
            : <AlertError
              text={useTranslate(`favorites:errorMsg`)}
              enableError={true}
            />
          }
        </Grid>
        {canSetAsFavoritePage() && <Grid item style={{ paddingLeft: "12px" }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={isFavoritePageFlag}
            style={{
              backgroundColor: isFavoritePageFlag ? "#BEBEBE" : "#008ABC",
              color: "white",
              width: 280,
              textTransform: "unset",
              height: 35,
              fontSize: 14,
              fontWeight: 450,
              margin: 7
            }}
            startIcon={<FontAwesomeIcon icon={faStar} />}
            onClick={saveFavorite}
          >
            {useTranslate(`favorites:buttonLabel`)}
          </Button>
        </Grid>}
      </Grid>
      <ConfirmDialog
        title={useTranslate(`favorites:dialogTitle`)}
        subTitle={useTranslate(`favorites:dialogSubTitle`)}
        open={confirmOpen}
        onClose={onClose}
        onOk={onOk}
      >
        <Typography style={{ marginTop: -12 }}>
          {useTranslate(`favorites:dialogContent`)}
        </Typography>
      </ConfirmDialog>
    </Drawer>
  );
}
