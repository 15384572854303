import { BasePresenter } from "@pentair/react";
import { NetworkModule } from "../../../application/network";

/**
 * Component state
 */
interface IComponentState {
    // A password state
    password: any;
    // An error state
    error: any;
    // Enable or disable password component state
    disableResetPassword: boolean;
    // A flag to control show/hide for confirmation dialog
    isLoading: number;
    // Flag for news subscription accepted
    newsSubscriptionAccepted: string;
    // Flag for TnC subscription accepted
    termsAndConditionAccepted: string;
}

/**
 * Class component to handle reset password
 */
export class ResetPasswordPresenter extends BasePresenter<IComponentState> {

    // Update the user the identity password
    public completeNewPassword: (newPassword: string, termsAndConditionAccepted: string, newsSubscriptionAccepted: string) => any;

    public state: IComponentState = {
        isLoading: 0,
        password: {
            newPassword: "",
            confirmPassword: "",
        },
        // TODO disable: !this.props.showTnCCheckboxes ? true : false,
        disableResetPassword: false,
        newsSubscriptionAccepted: ``,
        termsAndConditionAccepted: ``,
        error: {
            errorMessage: "",
            showError: ""
        }
    }

    /**
     * Constructor
     * @param _network A network module to query APIs
     */
    constructor(protected _network: NetworkModule) {
        super();
    }

    /**
     * Set local state object
     * @param state New state
     */
    public change = (state: Partial<IComponentState>) => {
        this.setState({ ...this.state, ...state });
    }

    /**
     * Mount event
     */
    public mount = async () => { }

    /**
     * Set error message state to hide on show message on UX
     * @param errorMessage An error string
     * @param enable Show or hide error
     */    
    private setErrorMessage = (errorMessage: string, enable: boolean) => {
        const error = {
            ...this.state.error
        };
        error.showError = enable;
        error.errorMessage = errorMessage;
        this.change({ error });
    }

    /**
     * Set state of input target on change action callback
     * @param event Input target element
     */
    public onChange = (event: any) => {
        event.preventDefault();
        const password = {
            ...this.state.password,
            [event.target.name]: event.target.value,
        };
        this.change({ password });
    }

    /**
     * On <ENTER> key press action callback
     * @param _event A key event
     */
    public onKeyPress = (_event: any) => {
        // Validate the <ENTER> key code on key press
        if (_event.charCode === 13) {
            this.onContinueClick();
        }
    }

    /**
     * Submitting user new password
     */
    public onContinueClick = async () => {
        this.setErrorMessage("", false);
        // Updating user's new password
        if (
            this.state.password.newPassword === this.state.password.confirmPassword
        ) {
            this.completeNewPassword(
                this.state.password.newPassword,
                this.state.termsAndConditionAccepted,
                this.state.newsSubscriptionAccepted
            );
        } else {
            this.change({ disableResetPassword: false });
            this.setErrorMessage("New password and confirm password are not matching", true);
        }
    }

    /**
     * On Terms and condition checkbox event trigger
     * @param _event The checkbox event
     */
    public onValueChange = (_event: any) => {
        const value = _event.target.checked;
        const property = _event.target.name;
        const timeStamp = value ? new Date().getTime() : '';

        switch (property) {
            case "termsAndConditionAccepted":
                this.change({
                    termsAndConditionAccepted: timeStamp.toString(),
                    disableResetPassword: value !== true
                });
                break;

            case "newsSubscriptionAccepted":
                this.change({ newsSubscriptionAccepted: timeStamp.toString() });
                break;
            default: // Empty default as unreachable
                break;
        }
    }

    /**
     * Un-mount event
     */
    public unmount = async () => { }

}