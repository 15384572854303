import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Back propagation button
 */
interface IOwnProps {
  /**
   * Back button text
   */
  text: string;
  /**
   * Button click event handler
   */
  onClick?: () => void;
}

export const BackButton = ({ text, onClick }: IOwnProps) => (
  <span onClick={onClick} style={{ cursor: "pointer" }}>
    <FontAwesomeIcon
      icon={["fas", "long-arrow-left"]}
      style={{ marginRight: 8 }}
      color="#464747"
    />
    {text}
  </span>
);
