/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import * as React from "react";
import {
  Route,
  Switch,
  BrowserRouter,
  withRouter,
} from "react-router-dom";
import { getSessionData } from "@pentair/react";
import {
  SecureRoute,
  SecurePiletRoute,
} from "./secureRoute";
import {
  IRoute,
  isExternalPath,
  routes,
} from "./config/routes";
import { IMicroAppFeature } from "./application/types";
import { PiletLayout } from "./features/common/layouts/pilet-layout";
import { BROWSER_STORE_SHELL_PERMISSIONS } from "./config/constants";

export const AuthRoutes = ({ children }: any):JSX.Element => {
  /**
   * A function utility
   * @param appKey
   * @param route
   * @returns
   */
  function refreshRoutes(appKey: string, route: IRoute): IRoute[] {
    const newMap = {
      [appKey]: {
        ...route,
      },
    };
    const routes = Object.keys(newMap).map((name: string) => newMap[name]);
    return routes;
  }

  const updateRoutes = () => {
    /**
     * Reading all micro apps information based on user permissions in order to get micro app description and features descriptions (used to show in breadcrumb)
     */
    const microApps: IMicroAppFeature[] = getSessionData(BROWSER_STORE_SHELL_PERMISSIONS, []);

    /**
     * Reading all micro app features and storing in local array as key values pair for feature descriptions in order to show in breadcrumb
     */
    const localRoutes: IRoute[] = routes;
    if (microApps && microApps.length) {
      microApps.forEach((item: IMicroAppFeature) => {
        const rts: IRoute[] = refreshRoutes(`app.${item.microapp}`, {
          path: `/${item.microapp}/**`,
          isSecure: true,
          component: undefined,
          title: `${item.title}`,
          securedAt: "PILETS",
          mainMenu: false,
        });
        localRoutes.push(rts[0]);
      });
    }
    return localRoutes;
  };

  const allRoutes: IRoute[] = updateRoutes();
  return (
    <BrowserRouter>
      <Switch>
        {allRoutes.map((route: IRoute, index: number) => (
          /// If security NILL then
          // eslint-disable-next-line no-nested-ternary
          route.securedAt === "NILL" ? (
            <Route
              key={`app-route-${index}`}
              exact
              path={route.path}
              component={route.component}
            />
          )
            /// Else-If security APP_SHELL level then
            : route.securedAt === "APP_SHELL" ? (
              <SecureRoute
                key={`shell-route-${index}`}
                exact
                path={route.path}
                component={route.component}
              />
            ) : null
          /// Else-Else-If security APP_SHELL level then
        ))}
        {isExternalPath() && children
          && (
            <SecurePiletRoute
              key="pilet-route-cfg"
              Component={
                <PiletLayout children={children} />
              }
            />
          )}

      </Switch>
    </BrowserRouter>
  );
};

export default withRouter(AuthRoutes);
