import { getSessionData } from "@pentair/react";
import { SERVICE_CUSTOMER } from "../config/constants";
import { CognitoUserSession } from "amazon-cognito-identity-js";

export const getRouteKeyAccount = (user: CognitoUserSession) => {
  if (!user)
    return;
  try {
    const sessionKeyAccount: { id: string; name: string } = getSessionData(
      SERVICE_CUSTOMER,
      null,
    );
    if (
      sessionKeyAccount
      && sessionKeyAccount.id
      && sessionKeyAccount?.name?.trim() != ""
    ) {
      return sessionKeyAccount;
    }
  } catch (error) {
    console.error("No service customer/ decryption error")
    return user?.getIdToken().payload.keyaccountid;
  }

  return user?.getIdToken().payload.keyaccountid;
};
