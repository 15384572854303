/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import {
  BasePresenter,
  decryptUtils,
  getSessionData,
  setSessionData,
} from "@pentair/react";
import { History } from "history";
import { Amplify, Auth } from "aws-amplify";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { NetworkModule } from "../../../../application/network";
import { IConfigType, __CONFIGS__ } from "../../../../config/default-config";
import {
  GetKeyAccount,
  GetUser,
  queryGetDomains,
  queryIdentityInfo,
  queryUpdateUserSubscription,
  queryUserFavorites,
} from "../graphql/queries";
import {
  BROWSER_STORE_DEF_CONFIG,
  BROWSER_STORE_DOMAINS,
  BROWSER_STORE_FAVORITES,
  BROWSER_STORE_I18NEXTLNG,
  BROWSER_STORE_IS_AUTHENTICATED,
  BROWSER_STORE_IS_MENU_HIDDEN,
  BROWSER_STORE_PILET_INFO,
  BROWSER_STORE_POOL_ID,
  BROWSER_STORE_REDIRECT_TO,
  BROWSER_STORE_SHELL_PERMISSIONS,
  BROWSER_STORE_USER_ID,
  BROWSER_STORE_WEB_CLIENT_ID,
  ERROR_FAV_PAGE_INVALID,
  IS_MOBILE_LOGIN,
  LANDING_PAGE_OBJECT_KEY,
  MICRO_APP_DASHBOARDS,
  MICRO_APP_NAME,
  MICRO_APP_PLATFORM_MANAGEMENT,
  MICRO_APP_USER_MANAGEMENT,
  MOBILE_APP,
  MOBILE_APP_FEATURE,
  PRODUCT_CATEGORY_MICROAPPS,
  SERVICE_CUSTOMER,
} from "../../../../config/constants";
import { setUserPermission } from "../../../../config/userPermission";
import { route, routeMap } from "../../../../config/routes";
import {
  IAwsConfig,
  IFavorite,
  IFeature,
  IIdentityDetails,
  IMicroAppFeature,
  IUpdateUserSubscription,
} from "../../../common/types";
import { MicroAppPiletSingleton } from "../../../../default";
import { getRouteKeyAccount } from "../../../../utils/routeKeyAccount";
import {
  getPermissionToken,
  QueryProductCategory,
} from "../../../common/graphql/queries";
import i18n from "../../../../i18n";
import { updateUserAttributes } from "../graphql/mutation";

// import "./login-mobile";
import { getSessionValue } from "../../../../utils/sessionStorage";
import { isExternal } from "../../../../config/data";

/**
 * A login page state
 */
interface ILoginState {
  jsonConfig: IConfigType;
  isLoading: boolean;
  credentials: any;
  alert: any;
  enableLogin: boolean;
  cognitoUser: any;
  params: string;
  showQR: boolean;
  qrCode: string;
  qrSecret: string;
  autoLogin: boolean;
  termsAndConditionAccepted: boolean;
  captureTnC: boolean;
  // Display error if flag is set
  isError: boolean;
}

/**
 * Class component for login
 */
export class LoginPresenter extends BasePresenter<ILoginState> {
  public history: History;

  public location: any;

  private defaultConfig: IConfigType;

  private identityDetails: IIdentityDetails;

  private microAppDetails: IMicroAppFeature[];

  public state: ILoginState = {
    jsonConfig: __CONFIGS__,
    isError: false,
    isLoading: false,
    credentials: {
      userName: "",
      password: "",
      token: "",
    },
    alert: {
      errorMessage: "",
      showError: false,
    },
    enableLogin: false,
    cognitoUser: {} as any,
    params: "",
    showQR: false,
    qrCode: "",
    qrSecret: "",
    autoLogin: false,
    termsAndConditionAccepted: false,
    captureTnC: false,
  };
  userPermissionPromise: Promise<any>;

  /**
   * Constructor
   * @param props Props instance
   */
  constructor(private _network: NetworkModule) {
    super();
  }

  /**
   * Load default config from JSON i.e. config.json
   */
  private loadDefaultConfigJson = async () => {
    await fetch("/config.json")
      .then((r) => r.text())
      .then((txt) => {
        if (txt) {
          const configuration: IAwsConfig = {
            ...this.state.jsonConfig,
            ...JSON.parse(txt),
          };
          window.sessionStorage.setItem(
            BROWSER_STORE_DEF_CONFIG,
            JSON.stringify(configuration),
          );
          this.defaultConfig = configuration;
          this.change({ jsonConfig: configuration });
        }
      })
      .catch((error) => {
        this.showError(true);
      });
  };

  /**
   * An Load default config event
   */
  private loadDefaultConfig = async () => {
    await this.loadDefaultConfigJson();

    /**
     * Retry reading default config if not present in session.
     */
    if (
      !(
        this.defaultConfig.pentair_user_name &&
        this.defaultConfig.pentair_user_password
      )
    ) {
      await this.loadDefaultConfigJson();
    }
    if (
      this.defaultConfig.pentair_user_name &&
      this.defaultConfig.pentair_user_password
      && !isExternal()
    ) {
      await this.getLogin(
        this.defaultConfig.pentair_user_name,
        this.defaultConfig.pentair_user_password,
      );
    } else {
      throw "Default login credentials not exists";
    }
  };

  /**
   * Parsing jwt token to get login appsync end point
   */
  private parseJwtToken = (token: any) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(""),
    );
    return JSON.parse(jsonPayload);
  };

  /**
   * On login API call to get User authenticated
   */
  private getLogin = async (userName: string, password: string) => {
    /**
     * Configure amplify cognito pool details
     * input PoolId,clientId, false -- used to store cognito pool details in local storage (false :will not store , true :store)
     *
     */
    const identity: IIdentityDetails = {
      poolID: this.defaultConfig.userPoolId,
      clientID: this.defaultConfig.userPoolWebClientId,
    };
    this.configureAmplify(identity, false);

    /**
     * Default pentair login
     */
    try {
      const result = await Auth.signIn(userName, password);
      /**
       * Parsing Jwt token and reading login appsync end point
       */
      const loginEndPoint = this.parseJwtToken(
        result.signInUserSession.idToken.jwtToken,
      ).appsync;
      /**
       * Storing App-shell appsync into sessionStorage when user login
       */
      window.sessionStorage.setItem(`${MICRO_APP_NAME}-appsync`, loginEndPoint);
      /**
       * Configuring login appsync endpoint in Amplify
       */
      Amplify.configure({
        API: {
          aws_appsync_graphqlEndpoint: loginEndPoint,
          aws_appsync_region: this.defaultConfig.aws_appsync_region,
          aws_appsync_authenticationType:
            this.defaultConfig.aws_appsync_authenticationType,
        },
      });
      try {
        const domainDetails: any = await this._network.fetch(queryGetDomains);
        const microAppDetails: IMicroAppFeature[] = [];
        if (domainDetails.data.fetchPilets) {
          domainDetails.data.fetchPilets.forEach((item: IMicroAppFeature) => {
            microAppDetails.push({ ...item });
          });
        }
        /**
         * Storing all micro apps information in local storage in order to filter selected micro app metadata information to while restoring Pilet
         */
        this.microAppDetails = microAppDetails;
        window.sessionStorage.setItem(
          BROWSER_STORE_DOMAINS,
          JSON.stringify(microAppDetails),
        );
        return result;
      } catch (error) {
        console.log(error);
        throw "Invalid User";
      }
    } catch (error) {
      /**
       * Storing App-shell appsync so that we will get to know default user login failed.
       */
      const errorData: any = [
        { message: "App-shell default login failed", error },
      ];
      window.sessionStorage.setItem(
        `${MICRO_APP_NAME}-appsync`,
        JSON.stringify(errorData),
      );
      console.log(error);
      throw "Please reload the screen";
    }
  };

  /**
   * To update user TnC and subscription
   * @param userSubscription The input user subscription
   */
  private updateUserSubscription = async (
    userSubscription: IUpdateUserSubscription,
  ) => {
    /**
     * Configure amplify cognito pool details
     * input PoolId,clientId, false -- used to store cognito pool details in local storage (false :will not store , true :store)
     *
     */
    const identity: IIdentityDetails = {
      poolID: this.defaultConfig.userPoolId,
      clientID: this.defaultConfig.userPoolWebClientId,
    };
    this.configureAmplify(identity, false);
    return await this._network
      .fetch(queryUpdateUserSubscription, { input: userSubscription })
      .then((response: any) => {
        if (!response.errors) {
          return response.data.updateUserSubscription;
        }
        if (response.errors.length) {
          throw response.errors[0].message;
        } else {
          throw response.errors;
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  /**
   * An event for New Password
   * @param newPassword Input element for new password
   * @param termsAndConditionAccepted Timestamp for TnC acceptance
   * @param newsSubscriptionAccepted Timestamp for news subscriptions acceptance
   */
  public onTnCAcceptance = async (
    termsAndConditionAccepted: string,
    newsSubscriptionAccepted: string,
  ) => {
    this.setErrorMessage("", false);
    try {
      this.change({ isLoading: true });
      // Update the user subscription
      const userSubscription: IUpdateUserSubscription = {
        id: this.state.credentials.userName.trim() || "",
        newsSubscriptionAccepted,
        termsAndConditionAccepted,
      };
      await this.updateUserSubscription(userSubscription)
        .then(async (result: any) => {
          this.change({
            captureTnC: false,
          });
          await this.onUserLogin();
        })
        .catch((error: any) => {
          this.setErrorMessage(error.message || error, true);
        });
    } catch (error: any) {
      this.setErrorMessage(error.message, true);
    } finally {
      this.change({ isLoading: false });
    }
  };

  /**
   * An event for Back navigation
   */
  public onBackNavigate = () => {
    this.setErrorMessage("", false);
    this.change({
      params: "login",
      enableLogin: false,
    });
  };

  private completeNewPassword = async (
    newPassword: string,
    cognitoUser: any,
  ) => {
    return await Auth.completeNewPassword(cognitoUser, newPassword, {})
      .then((result: any) => {
        return result;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  /**
   * An event for New Password
   * @param newPassword Input element for new password
   * @param termsAndConditionAccepted Timestamp for TnC acceptance
   * @param newsSubscriptionAccepted Timestamp for news subscriptions acceptance
   */
  public onCompleteNewPassword = async (
    newPassword: string,
    termsAndConditionAccepted: string,
    newsSubscriptionAccepted: string,
  ) => {
    this.setErrorMessage("", false);
    try {
      // Update the user subscription
      const userSubscription: IUpdateUserSubscription = {
        id: this.state.credentials.userName.trim() || "",
        newsSubscriptionAccepted,
        termsAndConditionAccepted,
      };
      this.updateUserSubscription(userSubscription)
        .then(async (result: any) => {
          this.completeNewPassword(newPassword, this.state.cognitoUser)
            .then(async (result: any) => {
              const session = await Auth.currentSession();
              if (session.getIdToken().payload.mfaConfig === "ON") {
                this.generateQR();
              } else {
                await this.onLogout();
              }
            })
            .catch((error: any) => {
              this.setErrorMessage(error.message || error, true);
            });
        })
        .catch((error: any) => {
          this.setErrorMessage(error.message || error, true);
        });
    } catch (error: any) {
      this.setErrorMessage(error.message, true);
    }
  };

  /**
   * Validating login MFA
   * Success : It will return cognito user session
   * Error : It will return error message to presenter
   * @param code
   * @param cognitoUser
   */
  private verifyMfaCode = async (code: string, cognitoUser: any) => {
    return await Auth.confirmSignIn(cognitoUser, code, "SOFTWARE_TOKEN_MFA")
      .then((result: any) => {
        return result;
      })
      .catch((error: any) => {
        throw error;
      });
  };

  /**
   * Return user permission in encrypted
   * @param session User Session object
   * @returns Permission in String
   */
  private getUserPermission = async (session: any) => {
    try {
      const response: any = await this._network.fetch(
        getPermissionToken
          .replace(
            "@keyAccountId",
            session.getIdToken().payload["keyaccountid"],
          )
          .replace("@emailId", session.getIdToken().payload.email),
        {},
        MICRO_APP_USER_MANAGEMENT,
      );

      if (!response.errors && response.data.getPermissionToken !== null) {
        return response.data.getPermissionToken;
      }
    } catch (error) { }
    return "";
  };

  /**
   * An event for Login with mfa
   * @param mfaVerificationCode Input element for mfa
   */
  public onLoginWithMFA = async (mfaVerificationCode: string) => {
    this.setErrorMessage("", false);
    await this.verifyMfaCode(mfaVerificationCode, this.state.cognitoUser)
      .then(async (result: any) => {
        /// Update the app permissions
        this.getDefaultLandingPage();
      })
      .catch((error: any) => {
        this.setErrorMessage(error.message, true);
        return error;
      });
  };

  /**
   * Update the App permission for the user
   */
  private updateUserAppPermission = async (session?: CognitoUserSession) => {
    try {
      /// Update the app permissions
      if (!session) session = await Auth.currentSession();
      const { sub } = session.getIdToken().payload;
      window.sessionStorage.setItem(BROWSER_STORE_USER_ID, sub);

      // const microApps: IMicroAppFeature[] = getSessionData();

      // // Check if permission already stored in session
      // if (microApps && microApps?.length > 0) {
      //   return microApps;
      // }
      // fetch the user permission and Store the data in session
      const permission = await Promise.any([this.userPermissionPromise]);
      // const permission = await this.getUserPermission(session);
      return setUserPermission(this.microAppDetails, permission, session);
    } catch (ex) { }
    return [];
  };

  /**
   * Storing user inputs to state onchange event
   * @param event Input element
   */
  public onChange = (event: any) => {
    event.preventDefault();
    const credentials = {
      ...this.state.credentials,
      [event.target.name]: event.target.value,
    };
    this.change({ credentials });
  };

  /**
   * An event for verifying TOTP for mfa
   */
  private verifyTOtp = async () => {
    try {
      this.setErrorMessage("", false);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.verifyTotpToken(currentUser, this.state.credentials.token);
      await Auth.setPreferredMFA(currentUser, "TOTP");
      await this.onLogout();
    } catch (error: any) {
      this.setErrorMessage(error.message ? error.message : error, true);
    }
  };

  /**
   * An event for logout redirection
   */
  private logout = async () => {
    await Auth.signOut({ global: true });
    window.sessionStorage.clear();
    this.history.push(route("app.shell.login"));
    i18n.changeLanguage();
  };

  /**
   * An event for Logout
   */
  public onLogout = async () => {
    this.setErrorMessage("", false);
    this.change({
      credentials: {
        userName: "",
        password: "",
        token: "",
      },
      enableLogin: false,
      showQR: false,
      params: "login",
      captureTnC: false,
    });
    await this.logout();
  };

  /**
   * An event for closing QR code window
   */
  public onCloseQRCode = async () => {
    await this.verifyTOtp();
  };

  /**
   * Method to sign in default login and display message
   */
  async signInDefaultLogin() {
    this.change({ isLoading: true });
    let flagDefaultLogin = 0;
    try {
      await this.loadDefaultConfig();
      /**
       * flagDefaultLogin is true if login is success.
       */
      flagDefaultLogin = 1;
    } catch (ex) {
      this.setErrorMessage(
        "Application error on login, try again later. Contact support if still exists.",
        true,
      );
    } finally {
      this.change({ isLoading: false });
      return flagDefaultLogin;
    }
  }

  /**
   * Get the micro-app feature by app-name and feature name from the DOMAINS object
   * @param microAppName A micro-app name
   * @param microAppFeatureName An app feature name
   */
  private getMicroAppFeature = (
    microAppName: string,
    microAppFeatureName: string,
  ) => {
    /// / Get the DOMAINS object
    const microAppFeatures: IMicroAppFeature[] = [
      ...JSON.parse(
        window.sessionStorage.getItem(BROWSER_STORE_DOMAINS) as string,
      ),
    ];
    /// / Find the app by name
    const microApp: IMicroAppFeature | undefined = microAppFeatures.find(
      (app: IMicroAppFeature) => app.microapp === microAppName,
    );

    if (!microApp?.features) {
      return undefined;
    }
    const appFeature: IFeature | undefined = microApp?.features.find(
      (feature: IFeature) => feature.name === microAppFeatureName,
    );
    return appFeature;
  };

  /**
   * An Login button state event
   * @param buttonState Input element
   */
  private setLoginButtonState = (buttonState: boolean) => {
    this.change({ enableLogin: buttonState });
  };

  /**
   * Set local state object
   * @param state New state
   */
  public change = (state: Partial<ILoginState>) => {
    this.setState({ ...this.state, ...state });
  };

  /**
   * Generic method to enable error message on presenter
   * @param errorMessage Input element
   * @param enable Input boolean element
   */
  private setErrorMessage = (errorMessage: string, enable: boolean) => {
    const alert = {
      ...this.state.alert,
    };
    alert.showError = enable;
    alert.errorMessage = errorMessage;
    this.change({ alert });
  };

  /**
   *Actual user login (after default Pentair user login)
   * Success: it will return new user cognito session
   * Error: it will return status to presenter
   * @param userName
   * @param password
   * @param identity
   */
  private postLogin = async (
    userName: string,
    password: string,
    identity: IIdentityDetails,
  ) => {
    this.change({ isLoading: true });
    /**
     * User cognito pool configuration
     */
    return await Auth.signIn(userName, password)
      .then((result: any) => {
        window.sessionStorage.setItem(BROWSER_STORE_IS_AUTHENTICATED, "true");
        this.change({ isLoading: false });
        return result;
      })
      .catch((error) => {
        window.sessionStorage.setItem(BROWSER_STORE_IS_AUTHENTICATED, "false");
        this.change({ isLoading: false });
        throw error;
      });
  };

  /**
   * On default config JSON load, if any issue occurred, error will be displayed.
   * @param flag To show/hide the error popup
   */
  public showError = async (flag: boolean) => {
    this.change({ isError: flag });
    if (!flag) {
      this.history.go(0);
    }
  };

  /**
   * An Login event
   */
  public onUserLogin = async () => {
    this.change({ isLoading: true });
    this.setErrorMessage("", false);
    /** Validating user name and password should not be empty */
    try {
      await Auth.currentAuthenticatedUser();
    } catch (error) {
      await this.loadDefaultConfig();
    }
    try {
      this.change({ isLoading: true });
      if (
        !(
          this.state.credentials.userName === "" ||
          this.state.credentials.password === ""
        )
      ) {
        let flagDefaultLogin = 1;
        /**
         * Check if default appsync url available, if not load default again.
         */
        const flagAppsync = JSON.parse(
          window.sessionStorage.getItem(BROWSER_STORE_DEF_CONFIG) || "{}",
        );
        if (!flagAppsync.userPoolId) {
          flagDefaultLogin = await this.signInDefaultLogin();
        }
        if (flagDefaultLogin) {
          this.setLoginButtonState(true);
          this.setErrorMessage("", false);
          /**
           * Getting user identity details from appsync resolver
           */
          this.getIdentityDetails(this.state.credentials.userName.trim())
            .then(async (identityResult: any) => {
              // Get the flag to see the T&C checkboxes
              this.change({
                termsAndConditionAccepted:
                  identityResult?.termsAndConditionAccepted,
              });
              this.identityDetails = identityResult;

              /**
               * Signing out from default login
               * If user has valid identity details , system will allow to login user
               */
              Auth.signOut()
                .then((defaultLogOutResult: any) => {
                  this.postLogin(
                    this.state.credentials.userName.trim(),
                    this.state.credentials.password,
                    this.identityDetails,
                  )
                    .then(async (userLoginResult: any) => {
                      /**
                       * Checking user challenge , user created by admin its mandatory to set user password on first time login
                       * In order to reset password it will redirect to reset password page
                       */
                      if (
                        userLoginResult.challengeName ===
                        "NEW_PASSWORD_REQUIRED"
                      ) {
                        this.change({
                          params: "resetPassword",
                          cognitoUser: userLoginResult,
                          enableLogin: false,
                        });
                      } else if (!this.state.termsAndConditionAccepted) {
                        // Get the flag to see the T&C checkboxes
                        this.change({
                          params: "login",
                          captureTnC:
                            !identityResult?.termsAndConditionAccepted,
                        });
                      } else if (
                        userLoginResult.challengeName === "SOFTWARE_TOKEN_MFA"
                      ) {
                        /// / Update the app permissions
                        await this.updateUserAppPermission();
                        /**
                         * User is enabled with MFA then user will navigate to MFA validation screen
                         */
                        this.change({
                          params: "verification",
                          cognitoUser: userLoginResult,
                        });
                      } else {
                        /**
                         * If User pool change the MFA as mandatory, user should force to generate MFA
                         */
                        const session = await Auth.currentSession();
                        if (session.getIdToken().payload["mfaConfig"] === "ON") {
                          this.generateQR();
                        } else {
                          // Get the flag to see the T&C checkboxes
                          this.change({
                            params: "login",
                            captureTnC:
                              !identityResult?.termsAndConditionAccepted,
                          });

                          /**
                           * get selected language in login page and update user language according to login page selected language
                           */
                          const loginLanguage = window.sessionStorage.getItem(
                            BROWSER_STORE_I18NEXTLNG,
                          );
                          const selectedLanguage = loginLanguage?.toUpperCase();
                          const keyAccountId =
                            session.getIdToken().payload["keyaccountid"];
                          const userId = session.getIdToken().payload.sub;
                          const userLang = session.getIdToken().payload.locale;
                          const userEmail = session.getIdToken().payload.email;
                          if (selectedLanguage != userLang) {
                            await this.applyChanges(
                              userId,
                              userEmail,
                              keyAccountId,
                              selectedLanguage,
                            );
                          }

                          /**
                           * Get the User Default landing page and navigate to url.
                           */
                          if (identityResult?.termsAndConditionAccepted) {
                            await this.getDefaultLandingPage(session);
                          }
                        }
                      }
                    })
                    .catch((errorMessage: any) => {
                      /**
                       * Check if the User needs reset password,
                       * Navigate to reset password screen where user can submit the code and password
                       */
                      this.change({ autoLogin: false });
                      if (
                        errorMessage &&
                        errorMessage.code &&
                        errorMessage.code === "PasswordResetRequiredException"
                      ) {
                        this.history.push(route("app.shell.submitpassword"), {
                          userName: this.state.credentials.userName.trim(),
                        });
                      } else {
                        /** Catching user invalid credentials */
                        this.setErrorMessage(errorMessage.message, true);
                        this.setLoginButtonState(false);
                      }
                      this.change({ isLoading: false });
                    });
                })
                .catch((error: any) => {
                  /** Catching invalid user or not */
                  this.setErrorMessage(error, true);
                  this.setLoginButtonState(false);
                  this.change({ autoLogin: false, isLoading: false });
                });
            })
            .catch((error: any) => {
              /** Catching invalid user or not */
              this.setErrorMessage(error, true);
              this.setLoginButtonState(false);
              this.change({ autoLogin: false, isLoading: false });
            });
        }
      } else {
        this.setErrorMessage("Enter valid credentials.", true);
        this.change({ isLoading: false });
      }
    } catch {
      this.change({ isLoading: false });
    }
  };

  /**
   * An API call to get list of favorite items
   * @param session Current user session
   * @returns list of favorite items or empty array
   */
  private getFavoriteLandingPage = async (session: any) => {
    try {
      const response: any = await this._network.fetch(
        queryUserFavorites
          .replace(
            "@keyAccountId",
            session.getIdToken().payload["keyaccountid"],
          )
          .replace("@userId", session.getIdToken().payload.sub),
        {},
        MICRO_APP_USER_MANAGEMENT,
      );

      if (!response.errors && response.data.getUserFavourites !== null) {
        return response.data.getUserFavourites;
      }
    } catch (error) { }
    return [];
  };

  /**
   * An API call to get product category configured
   */
  private getProductCategoryMicroapps = async () => {
    try {
      const response: any = await this._network.fetch(
        QueryProductCategory,
        {},
        MICRO_APP_PLATFORM_MANAGEMENT,
      );

      if (
        !response.errors &&
        response.data.getProductCategoryMicroappDetails !== null
      ) {
        setSessionData(
          PRODUCT_CATEGORY_MICROAPPS,
          JSON.stringify(response?.data?.getProductCategoryMicroappDetails),
        );
      } else {
        console.error("Error fetching product category", response);
      }
    } catch (error) {
      console.error("Error fetching product category", error);
    }
    return [];
  };

  /**
   * To validate the subroute i.e.
   *  If route is `/user-management/useroverview`
   *      then it will validate the `/useroverview` route is exists
   * @param featurePath Feature path i.e. `useroverview`
   * @param microApp A Micro-app
   * @param keyaccountId key account ID if needed
   * @returns feture path
   * @throws error if route not matching
   */
  private validateSubRoute = (
    featurePath: string,
    microApp: IMicroAppFeature,
    keyaccountId: string[],
  ) => {
    /// If no features for micro-app, throw error
    if (!microApp?.features?.length) {
      throw "Path is invalid. Redirected to 'Admin Page'.";
    }
    let path: string | null = "";
    /// Look through the features to get the sub-route
    for (const feature of microApp?.features) {
      if (feature.path) {
        if (featurePath === feature.path) {
          path = feature.path;
          break;
        } else if (feature.path.lastIndexOf(":keyAccountId") > -1) {
          const keyPaths = keyaccountId.map(ka => feature.path.replace(":keyAccountId", ka))
          const foundPathIndex = keyPaths.indexOf(featurePath);
          if (foundPathIndex > -1) {
            path = keyPaths[foundPathIndex];
            break;
          }
        }
        path = null;
      }
    }
    /// Validate path, if exists or throw error
    if (path && path.lastIndexOf(":keyAccountId") > -1) {
      const url = path.replace(":keyAccountId", keyaccountId);
      return url;
    }
    if (!path) {
      throw "Path is invalid. Redirected to 'Admin Page'.";
    }
    return path;
  };

  /**
   * To validate the route i.e. `/user-management/useroverview/xx/yy/...`
   *  If route is no matching `/user-management/useroverview`
   *      then it will throw an error
   * @param session Current session
   * @param path current route
   * @param appPermission micro-app
   * @throws error if route not matching
   */
  private validateLandingPage(
    session: any,
    path: string,
    appPermission: IMicroAppFeature,
  ) {
    try {
      const { idToken } = session as any;
      const keyaccountId = idToken.payload["keyaccountid"];
      const routeKeyAccount = getRouteKeyAccount(session)
      const routeKeyAccountID = routeKeyAccount?.id || routeKeyAccount;
      /**
       * Splitting current route
       * */
      const paths = path.split("/").filter((x: any) => x);
      let routeTo;

      if (paths?.length >= 2 && paths[0] === appPermission?.microapp) {
        routeTo = `/${appPermission.microapp}/${this.validateSubRoute(
          paths[1],
          appPermission,
          [keyaccountId, routeKeyAccountID],
        )}`;
      } else if (paths?.length === 1) {
        routeTo = `/${paths[0]}`;
      }
      /// Compare the route with feature path, if not found there then throw an error
      if (!routeTo || !path.startsWith(routeTo)) {
        throw "Path is invalid. Redirected to 'Admin Page'.";
      }
    } catch (error) {
      /// Capture piral instance
      const piral: MicroAppPiletSingleton =
        MicroAppPiletSingleton.getInstance();
      const data: any = {
        message:
          "It seems favorite landing page has became invalid. " +
          "To continue we request you to reset your favorite page again.</br>" +
          'If this issue persist, please reach out to us at <a style={{ color: PentairBlue[500] }} id="link" href="Mailto:pentairq.support@pentair.com">pentairq.support@pentair.com</a>',
        path,
      };
      piral.getPiralInstance().root.setData(ERROR_FAV_PAGE_INVALID, data);
      throw error;
    }
  }

  /**
   * Preparing for the default landing page based on the favorite checks
   * and redirection if any
   */
  private getDefaultLandingPage = async (session?: CognitoUserSession) => {
    try {
      if (!session) session = await Auth.currentSession();
      const tokendata = {
        data: {
          "action": "getData",
          "secret": "localSecret",
          payload: {
            accessToken: session?.accessToken.jwtToken,
            idToken: session?.idToken.jwtToken,
            refreshToken: session?.refreshToken.token,
          }
        },
      };
      console.log("### session object for mobile", tokendata);
      this.userPermissionPromise = this.getUserPermission(session);
      let pathName = route("app.shell.administration");
      let microAppName = "";
      /** All async calls to backend can happen in parallel, to reduce wait time */
      const [cat, microAppFeatures, favorites, sc] = await Promise.all([
        this.getProductCategoryMicroapps(),
        this.updateUserAppPermission(),
        this.getFavoriteLandingPage(session),
        this.setDefaultServiceCustomer(session),
      ]) as [any, IMicroAppFeature[], IFavorite[], any];
      let favoriteRoute: any;
      if (favorites && favorites.length) {
        // To filter out favorites which doesn't have any value.
        const filteredFav = favorites?.filter(
          (fav: any) => fav.value && fav.value.trim(),
        );
        setSessionData(BROWSER_STORE_FAVORITES, JSON.stringify(filteredFav));
        /**
         * FavoriteRoute contain
         * [{
         *      key:"landingPage",
         *      value: contain "location Route object" in string format
         *      description: "product-management"
         * }]
         */
        const landingPage = favorites?.find(
          (fav: any) => fav.key === LANDING_PAGE_OBJECT_KEY,
        );
        if (landingPage) {
          try {
            favoriteRoute = landingPage.value
              ? JSON.parse(landingPage.value)
              : null;
            /**
             * Example for location Route  object
             *  pathname: "/administration/producttypeoverview/e9550680-370f-45fa-9cf1-9f14284164dd"
             *  search: "",
             *  hash: "",
             *  state: {
             *      details: [ MICRO_APP_NAME, "product types", "prod-type-853232020"]
             *      keyAccountId: "c73cede3-d868-4a61-90d7-b10a03a36606"
             *  },
             *  key: "fj4ob8" # A hash code
             * */
            pathName =
              favoriteRoute && favoriteRoute.pathname
                ? favoriteRoute.pathname
                : route("app.shell.administration");
            microAppName = landingPage.description;
            const appPermissions: IMicroAppFeature[] = await getSessionData(
              BROWSER_STORE_SHELL_PERMISSIONS,
              [],
            );
            const microAppFeature = appPermissions?.find(
              (app: IMicroAppFeature) => app.microapp === microAppName,
            );
            if (microAppName === MICRO_APP_NAME && !microAppFeature) {
              this.validateLandingPage(session, pathName, microAppFeature);
            } else if (!microAppFeature) {
              pathName = route("app.shell.administration");
            } else {
              this.validateLandingPage(session, pathName, microAppFeature);
            }
          } catch (err) {
            // pathName = route("app.shell.administration");
            console.error("Error getting default landing page", err);
            throw err;
          }
        }
      }

      const isMobileLogin = (window.sessionStorage.getItem(IS_MOBILE_LOGIN) == 'true');

      if (!favoriteRoute?.pathname && isMobileLogin) {
        const dashboardPath = routeMap["app.mobile.dashboard"].path;
        const keyAccount = getRouteKeyAccount(session);
        const keyAccountId =
          typeof keyAccount === "string" ? keyAccount : keyAccount.id;
        pathName = `${dashboardPath}/${keyAccountId}`;
        microAppName = MICRO_APP_DASHBOARDS;
        // this.history.push(route("app.mobile.dashboard"));
      }

      /**
       * Check whether the app is redirected from the Dashboard (@function SecurePiletRoute) page to authenticate
       * user. Once user authenticated successfully, redirect user back to the requested page.
       * Currently this behavior is supported for "dashboards" Pilet
       */
      const redirectTo =
        window.sessionStorage.getItem(BROWSER_STORE_REDIRECT_TO) || "";
      if (redirectTo !== "") {
        microAppName = MICRO_APP_DASHBOARDS;

        const keyAccount = getRouteKeyAccount(session);
        const keyAccountId =
          typeof keyAccount === "string" ? keyAccount : keyAccount.id;
        pathName = `${redirectTo}/${keyAccountId}`;
      }

      if (
        pathName !== route("app.shell.administration") &&
        microAppName &&
        microAppName.length
      ) {
        /**
         * Storing selected pilet domain url in local storage in order to re render shell with selected pilet
         */
        const domain =
          microAppFeatures.find(
            (app: IMicroAppFeature) => app.microapp === microAppName,
          )?.domain || "";
        if (domain) {
          window.sessionStorage.setItem(BROWSER_STORE_PILET_INFO, domain);
        }

        window.location.href = pathName.toLocaleLowerCase();
      } else {
        this.history.push(route("app.shell.administration"));
      }
    } catch (ex) {
      this.history.push(route("app.shell.administration"));
    }
  };

  /**
   * Configuring Amplify configuration based on login Default login  or User login
   * @param identity
   * @param configureLocalPool
   */
  private configureAmplify = async (
    identity: IIdentityDetails,
    configureLocalPool: boolean,
  ) => {
    if (identity.poolID && identity.clientID) {
      await Amplify.configure({
        Auth: {
          userPoolId: identity.poolID,
          userPoolWebClientId: identity.clientID,
          region: this.defaultConfig.region,
          mandatorySignIn: this.defaultConfig.mandatorySignIn,
          authenticationFlowType: this.defaultConfig.authenticationFlowType,
        },
      });
      if (configureLocalPool) {
        window.sessionStorage.setItem(BROWSER_STORE_POOL_ID, identity.poolID);
        window.sessionStorage.setItem(
          BROWSER_STORE_WEB_CLIENT_ID,
          identity.clientID,
        );
      }
    } else {
      console.log("No Identity is provided");
    }
  };

  /**
   * Checking user identity pool information.
   *
   * Success: it will return user identity pool details.
   * Error :it will return error message to presenter.
   * @param userId
   */
  private getIdentityDetails = async (userId: string) => {
    this.change({ isLoading: true });
    let identity: IIdentityDetails = {
      poolID: this.defaultConfig.userPoolId,
      clientID: this.defaultConfig.userPoolWebClientId,
    };
    /**
     * Check this is working? -Fix for Session timeout and login again
     * the default config is empty even thought value exists in session
     */
    if (!identity?.poolID) {
      const defaultConfigIdentity = JSON.parse(
        window.sessionStorage.getItem(BROWSER_STORE_DEF_CONFIG) || "{}",
      );
      identity = {
        poolID: defaultConfigIdentity.userPoolId,
        clientID: defaultConfigIdentity.userPoolWebClientId,
      };
    }
    /**
     * Configure amplify cognito pool details
     * input PoolId,clientId, false -- used to store cognito pool details in local storage (false :will not store , true :store)
     */
    this.configureAmplify(identity, false);
    // eslint-disable-next-line no-return-await
    return await this._network
      .fetch(queryIdentityInfo.replace("@userId", userId.trim()))
      .then((userIdentity: any) => {
        /** Validating  user has valid cognito pool id and client id, in order to perform user sign in */
        if (
          userIdentity.data.fetchAccount.poolID !== "" &&
          userIdentity.data.fetchAccount.clientID !== ""
        ) {
          const identityInfo: IIdentityDetails = {
            clientID: userIdentity.data.fetchAccount.clientID,
            poolID: userIdentity.data.fetchAccount.poolID,
          };
          this.configureAmplify(identityInfo, true);
          this.change({ isLoading: false });
          return userIdentity.data.fetchAccount;
        }
        this.change({ isLoading: false });
        throw "Incorrect username or password.";
      })
      .catch((error: any) => {
        console.log(error);
        this.change({ isLoading: false });
        throw "Incorrect username or password.";
      });
  };

  /**
   * An event for generate QR for mfa
   */
  private generateQR = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const code = await Auth.setupTOTP(currentUser);
    const qrCode = `otpauth://totp/Pentair:Brewassist-{${currentUser.attributes.email}?secret=${code}&issuer=Pentair`;
    this.change({ qrCode, showQR: true, qrSecret: code });
  };

  /**
   * Mount event
   */
  public mount = async () => {
    try {
      const currentSession = await Auth.currentSession();
      if (currentSession && currentSession.isValid()) {
        this.history.push(route("app.shell.administration"));
        return;
      }
    } catch (error) { }

    /**
     * Loading default configuration
     * out put : pentair default pool id and client id will be configured in amplify
     */
    this.change({
      isLoading: true,
      params: "login",
    });

    window.sessionStorage.setItem(IS_MOBILE_LOGIN, "false");
    const flagDefaultLogin = await this.signInDefaultLogin();
    if (flagDefaultLogin) {
      // Read URL params
      const query = new URLSearchParams(this.location.search);
      let userName: string | undefined = query.get("username") || undefined;
      let password: string | undefined = query.get("password") || undefined;
      const hideMenu: string | undefined = query.get("hide-menus") || undefined;

      const autoLogin: boolean =
        userName !== undefined && password !== undefined;
      this.change({
        params: "login",
        autoLogin: !autoLogin,
      });
      // Invoke auto-login(silent login) execution if autoLogin=TRUE
      window.sessionStorage.setItem(
        BROWSER_STORE_IS_MENU_HIDDEN,
        hideMenu || "false",
      );
      if (autoLogin) {
        try {
          /// / Get the mobile app to load the offsets
          const appFeature: IFeature | undefined = this.getMicroAppFeature(
            MOBILE_APP,
            MOBILE_APP_FEATURE,
          );
          if (!appFeature) {
            window.location.href = route("app.shell.default");
          }
          /// / Offsets and secrete key
          const iv = appFeature?.iv;
          const secret_key = appFeature?.id.replaceAll("-", "");
          /// / Decrypt the URL params
          userName = decryptUtils(userName, secret_key, iv, true);
          password = decryptUtils(password, secret_key, iv, true);
        } catch (error) {
          console.log(error);
          /// / If any failure occurs, redirect user to login page
          window.location.href = route("app.shell.default");
        }
        /// / If user-name and/or password is/are empty, redirect user to login page
        if (!userName || !password) {
          window.location.href = route("app.shell.default");
        }
        /// / update the state with credentials and process silent login
        this.change({
          credentials: {
            ...this.state.credentials,
            userName,
            password,
          },
        });
        this.onUserLogin();
      }
    }
  };

  /**
   * Set service customer if available from db to session object
   * @param session
   */
  private setDefaultServiceCustomer = async (session: any) => {
    const serviceCustomer = { id: "", name: "" };

    const keyAccountId = session.getIdToken().payload["keyaccountid"];
    const userId = session.getIdToken().payload.sub;
    const userResponse = await this.getUser(keyAccountId, userId);
    const defaultServiceCustomerId = userResponse?.defaultServiceCustomer;

    if (defaultServiceCustomerId && defaultServiceCustomerId.trim()) {
      serviceCustomer.id = defaultServiceCustomerId;
      const keyAccountResponse = await this.getKeyAccount(
        defaultServiceCustomerId,
      );
      if (keyAccountResponse && keyAccountResponse.name) {
        serviceCustomer.name = keyAccountResponse.name;
        setSessionData(SERVICE_CUSTOMER, JSON.stringify(serviceCustomer));
      }
    }
  };

  /**
   * Get user default service customer id
   * @param session
   */
  private getUser = async (keyAccountId: string, userId: string) => {
    try {
      const response: any = await this._network.fetch(
        GetUser.replace("@keyAccountId", keyAccountId).replace(
          "@userId",
          userId,
        ),
        {},
        MICRO_APP_USER_MANAGEMENT,
      );

      if (!response.errors && response.data.getUser != null) {
        return response.data.getUser;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  };

  /**
   * Get Key account details
   * @param session
   */
  private getKeyAccount = async (keyAccountId: string) => {
    try {
      const response: any = await this._network.fetch(
        GetKeyAccount.replace("@keyAccountId", keyAccountId),
        {},
        MICRO_APP_USER_MANAGEMENT,
      );

      if (!response.errors && response.data.getKeyAccount != null) {
        return response.data.getKeyAccount;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  };

  /**
   * An key press event
   * @param _event Input element
   */
  public onKeyPress = (_event: any) => {
    if (_event.charCode === 13) {
      this.onUserLogin();
    }
  };

  private applyChanges = async (
    userId: any,
    userEmail: any,
    kaId: any,
    language: any,
  ) => {
    const userInput = {
      id: userId,
      email: userEmail,
      keyAccountId: kaId,
      language,
    };
    const response: any = await this._network.fetch(
      updateUserAttributes,
      { input: userInput },
      MICRO_APP_USER_MANAGEMENT,
    );
    if (!response.errors) {
      await Auth.currentSession();
      this.change({
        alert: {
          errorMessage: "",
          enableError: false,
        },
      });
    } else if (response.errors.length) {
      this.change({
        alert: {
          errorMessage: response.errors[0].message,
          enableError: true,
        },
      });
    } else {
      this.change({
        alert: {
          errorMessage: response.errors[0].message,
          enableError: true,
        },
      });
    }
  };

  /**
   * Un-mount event
   */
  public unmount = async () => { };
}
