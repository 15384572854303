import { BasePresenter } from "@pentair/react";
import { History } from "history";
import { NetworkModule } from "../../../../application/network";
import {Amplify, Auth } from "aws-amplify";
import { IConfigType, __CONFIGS__ } from "../../../../config/default-config";
import {
    BROWSER_STORE_DEF_CONFIG,
    BROWSER_STORE_DOMAINS,
    BROWSER_STORE_POOL_ID,
    BROWSER_STORE_WEB_CLIENT_ID,
    MICRO_APP_NAME
} from "../../../../config/constants";
import {
    queryGetDomains,
    queryIdentityInfo
} from "../../login/graphql/queries";
import { queryResetPassword } from "../graphql/queries";
import { route } from "../../../../config/routes";
import {
    IMicroAppFeature,
    IIdentityDetails
} from "../../../common/types";

/**
 * Component state
 */
interface IComponentState {
    jsonConfig: IConfigType;
    // Enable or disable state
    disable: boolean;
    // Enable or disable T&C page
    termsAndConditionAccepted: boolean;
    // A flag to control show/hide for confirmation dialog
    isLoading: boolean;
    // A reset-password state
    resetPassword: any;
    // An error state
    error: any;
    // An user identity state
    identityDetails: IIdentityDetails;
}

/**
 * Class component to handle reset password
 */
export class ForgetPasswordPresenter extends BasePresenter<IComponentState> {

    public history: History;
    public location: any;
    private defaultConfig: IConfigType;
    private defaultUserName: any = {
        userName: ""
    };

    public state: IComponentState = {
        jsonConfig: __CONFIGS__,
        isLoading: false,
        disable: false,
        resetPassword: {
            userName: null,
        },
        error: {
            errorMessage: "",
            showError: false,
        },
        identityDetails: {} as IIdentityDetails,
        termsAndConditionAccepted: false
    };

    /**
     * Constructor
     * @param props Props instance
     */
    constructor(private _network: NetworkModule) {
        super();
    }

    /**
     * Set local state object
     * @param state New state
     */
    public change = (state: Partial<IComponentState>) => {
        this.setState({ ...this.state, ...state });
    }

    /**
    * Mount event
    */
    public mount = async () => {
        await this.signInDefaultLogin();
    }

    /**
     * Set error message state to hide on show message on UX
     * @param errorMessage An error string
     * @param enable Show or hide error
     */
    private setErrorMessage = async(errorMessage: string, enable: boolean) => {
        const error = {
            ...this.state.error,
        };
        error.showError = enable;
        error.errorMessage = errorMessage;
        this.state.error = error;
    }   

    /**
     * Checking user identity pool information.
     *
     * Success: it will return user identity pool details.
     * Error :it will return error message to presenter.
     * @param userId
     */
     private getUserIdentityDetails = async (userId: string) => {
        try {
            this.change({ isLoading: true });
            let identity: IIdentityDetails = {
                poolID: this.defaultConfig.userPoolId,
                clientID: this.defaultConfig.userPoolWebClientId,
            };
            /**
             * Check this is working? -Fix for Session timeout and login again
             * the default config is empty even thought value exists in session 
             */
            if (!identity?.poolID) {
                const defaultConfigIdentity = JSON.parse(window.sessionStorage.getItem(BROWSER_STORE_DEF_CONFIG) || "{}");
                identity = {
                    poolID: defaultConfigIdentity.userPoolId,
                    clientID: defaultConfigIdentity.userPoolWebClientId,
                };
            }
            /**
             * Configure amplify cognito pool details
             * input PoolId,clientId, false -- used to store cognito pool details in local storage (false :will not store , true :store)
             */
            this.configureAmplify(identity, false);
        } catch (error) {           
            console.log(error);
            throw "Incorrect username or password.";
        }
        return await this._network.fetch(queryIdentityInfo.replace("@userId", userId.trim()))
            .then((userIdentity: any) => {
                /** Validating  user has valid cognito pool id and client id, in order to perform user sign in */
                if (userIdentity.data.fetchAccount.poolID !== "" && userIdentity.data.fetchAccount.clientID !== "") {
                    const identityInfo: IIdentityDetails = {
                        clientID: userIdentity.data.fetchAccount.clientID,
                        poolID: userIdentity.data.fetchAccount.poolID,
                    };
                    this.configureAmplify(identityInfo, true);
                    this.change({ isLoading: false });
                    return userIdentity.data.fetchAccount;
                } else {
                    this.change({ isLoading: false });
                    throw "Incorrect username or password.";
                }
            })
            .catch((error: any) => {
                console.log(error);
                this.change({ isLoading: false });
                throw "Incorrect username or password.";
            });
    }

    /**
     * Parsing jwt token to get login appsync end point
     */
    private parseJwtToken = (token: any) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
        return JSON.parse(jsonPayload);
    }

    /**
     * On login API call to get User authenticated
     */
    private login = async (userName: string, password: string) => {
        this.change({ isLoading: true });
        const identity: IIdentityDetails = {
            poolID: this.defaultConfig.userPoolId,
            clientID: this.defaultConfig.userPoolWebClientId,
        };
        /**
         * Configure amplify cognito pool details
         * input PoolId,clientId, false -- used to store cognito pool details in local storage (false :will not store , true :store)
         *
         */
        this.configureAmplify(identity, false);

        /**
         * Default pentair login
         */
        try {
            const result = await Auth.signIn(userName, password);
            /**
            * Parsing Jwt token and reading login appsync end point
            */
            const loginEndPoint = this.parseJwtToken(
                result.signInUserSession.idToken.jwtToken
            )["appsync"];
            /**
            * Storing appshell appsync into sessionStorage when user login
            */
            window.sessionStorage.setItem(MICRO_APP_NAME + "-appsync", loginEndPoint);
            /**
            * Configuring login appsync endpoint in amplify
            */
            Amplify.configure({
                API: {
                    aws_appsync_graphqlEndpoint: loginEndPoint,
                    aws_appsync_region: this.defaultConfig.aws_appsync_region,
                    aws_appsync_authenticationType: this.defaultConfig.aws_appsync_authenticationType,
                },
            });
            try {
                const domainDetails:any = await this._network.fetch(queryGetDomains);
                const microAppDetails: IMicroAppFeature[] = [];
                if (domainDetails.data.fetchPilets) {
                    domainDetails.data.fetchPilets.forEach((item: IMicroAppFeature) => {
                        microAppDetails.push({ ...item });
                    });
                }
                /**
                 * Storing all micro apps information in local storage in order to filter selected micro app metadata information to while restoring pilet
                 */
                window.sessionStorage.setItem(BROWSER_STORE_DOMAINS, JSON.stringify(microAppDetails));
                this.change({ isLoading: false });
                return result;
            } catch (error) {
                this.change({ isLoading: false });
                console.log(error);
                throw "Invalid User";
            }
        } catch (error) {
            /**
            * Storing appshell appsync so that we will get to know default user login failed.
            */
            this.change({ isLoading: false });
            const errorData: any = [{ message: "Appshell default login failed", error }];
            window.sessionStorage.setItem(MICRO_APP_NAME + "-appsync", JSON.stringify(errorData));
            console.log(error);
            throw "Please reload the screen";
        }
    }

    /**
     * Fetch the user identity details
     */
    private getIdentityDetails = async () => {
        try {
            await this.login(
                    this.defaultConfig.pentair_user_name,
                    this.defaultConfig.pentair_user_password
                )
                .then(async (defaultLoginResult: any) => {
                    /**
                     *Checking user challenge , user created by admin its mandatory to set user password on first time login
                     *In order to reset password it will redirect to reset password page
                     *Pentair user challenge checking
                     */
                    if (defaultLoginResult.challengeName === "NEW_PASSWORD_REQUIRED")
                        this.history.push("/resetpassword");
                    else {
                        /**
                         * Getting user identity details from appsync resolver
                         */
                        await this.sendCode(this.state.resetPassword.userName);
                    }
                })
        }
        catch (error) {
            throw error;
        }
    }

    /**
     * On <ENTER> key press action callback
     * @param _event A key event
     */
    public onKeyPress = (_event: any) => {
        this.setErrorMessage("", false);
        // Validate the <ENTER> key code on key press
        if (_event.charCode === 13) {
            this.onSendCode();
        }
    }

    /**
     * On browser back navigation callback
     */
    public onNavigateBack = () => {
        window.location.href = route("app.shell.login");
    }

    /**
    * Method to sign in default login and display message 
    */
    async signInDefaultLogin() {
        this.change({ isLoading: true });
        let flagDefaultLogin = 0;
        try {
            await this.loadDefaultConfig();
            /**
            * flagDefaultLogin is true if login is success.
            */
            flagDefaultLogin = 1;
        }
        catch (ex) {
            this.setErrorMessage("Application error on login, try again later. Contact support if still exists.", true);
        }
        finally {
            this.change({ isLoading: false });
            return flagDefaultLogin;
        }
    }
    
    /**
     * On change password
     */
    public onChangePassword = async () => {
        try {
            if (!this.state.resetPassword.userName || this.state.resetPassword.userName.trim() === "") {
                this.setErrorMessage("Enter user name", true);
                return;
            } else {
                this.state.disable = true;
                this.state.isLoading = true;
                this.setErrorMessage("", false);
                try {
                    await Auth.currentAuthenticatedUser();
                } catch (error) {
                    await this.loadDefaultConfig();
                }
                let flagDefaultLogin = 1;
                /**
                * Check if default appsync url available, if not load default again.
                */
                const flagAppsync = JSON.parse(
                    window.sessionStorage.getItem(BROWSER_STORE_DEF_CONFIG) || "{}"
                );
                if (!flagAppsync.userPoolId) {
                    flagDefaultLogin = await this.signInDefaultLogin();
                }
                if (flagDefaultLogin) {
                    await this.getUserIdentityDetails(this.state.resetPassword.userName)
                    .then((identityResult: any) => {
                        this.history.push(route("app.shell.submitpassword"), {
                            userName: this.state.resetPassword.userName.trim(),
                        });
                    })
                    .catch((error: any) => {
                        throw "Incorrect username.";
                    });
                }
            }
        }
        catch (error:any) {
            this.setErrorMessage(error?.message || "Enter valid user name", true);
        } finally {
            this.change({
                resetPassword: this.defaultUserName,
                isLoading: false,
                disable: false,
            });
        }
    }

    /**
     * Set state of input target on change action callback
     * @param event target element
     */
    public onChange = (event: any) => {
        event.preventDefault();
        const resetPassword = {
            ...this.state.resetPassword,
            [event.target.name]: event.target.value,
        };
        this.change({ resetPassword });
    }
    
    /**
     * Configuring Amplify configuration based on login Default login  or User login
     * @param identity
     * @param configureLocalPool
     */
    private configureAmplify = (
        identity: IIdentityDetails,
        configureLocalPool: boolean
    ) => {
        if (identity.poolID && identity.clientID) {
            Amplify.configure({
                Auth: {
                    userPoolId: identity.poolID,
                    userPoolWebClientId: identity.clientID,
                    region: this.defaultConfig.region,
                    mandatorySignIn: this.defaultConfig.mandatorySignIn,
                    authenticationFlowType: this.defaultConfig.authenticationFlowType,
                },
            });
            if (configureLocalPool) {
                window.sessionStorage.setItem(BROWSER_STORE_POOL_ID, identity.poolID);
                window.sessionStorage.setItem(BROWSER_STORE_WEB_CLIENT_ID, identity.clientID);
            }
        }
        else {
            console.log("No Identity is provided");
        }
    }

    /**
     * A send code API call back
     */
    private sendCode = async (userName: string) => {
        this.change({ isLoading: true });
        const identity: IIdentityDetails = {
            poolID: this.defaultConfig.userPoolId,
            clientID: this.defaultConfig.userPoolWebClientId,
        };
        /**
        * Configure amplify cognito pool details
        * input PoolId,clientId, false -- used to store cognito pool details in local storage (false :will not store , true :store)
        *
        */
        this.configureAmplify(identity, false);
        return await this._network.fetch(queryResetPassword.replace("@userId", userName.trim()))
            .then((response: any) => {
                this.change({ isLoading: false });
                if (!response.errors && response.data.resetPassword) {
                    return response.data.resetPassword;
                } else {
                    throw response.errors ? response.errors : response;
                }
            })
            .catch((error: any) => {
                this.change({ isLoading: false });
                throw error;
            })
    }
    
    /**
     * Load default config from JSON i.e. config.json
     */
     private loadDefaultConfigJson = async () => {
        await fetch("/config.json")
            .then((r) => r.text())
            .then((txt) => {
                if (txt) {
                    const configuration: IConfigType = { ...this.state.jsonConfig, ...JSON.parse(txt) };
                    window.sessionStorage.setItem(BROWSER_STORE_DEF_CONFIG, JSON.stringify(configuration));
                    this.defaultConfig = configuration;
                    this.change({ jsonConfig: configuration });
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    /**
     * On login API call to get User authenticated
     */
     private getLogin = async (userName: string, password: string) => {
        /**
         * Configure amplify cognito pool details
         * input PoolId,clientId, false -- used to store cognito pool details in local storage (false :will not store , true :store)
         *
         */
        const identity: IIdentityDetails = {
            poolID: this.defaultConfig.userPoolId,
            clientID: this.defaultConfig.userPoolWebClientId,
        };
        this.configureAmplify(identity, false);

        /**
         * Default pentair login
         */
        try {
            const result = await Auth.signIn(userName, password);
            /**
            * Parsing Jwt token and reading login appsync end point
            */
            const loginEndPoint = this.parseJwtToken(
                result.signInUserSession.idToken.jwtToken
            )["appsync"];
            /**
            * Storing App-shell appsync into sessionStorage when user login
            */
            window.sessionStorage.setItem(MICRO_APP_NAME + "-appsync", loginEndPoint);
            /**
            * Configuring login appsync endpoint in Amplify
            */
            Amplify.configure({
                API: {
                    aws_appsync_graphqlEndpoint: loginEndPoint,
                    aws_appsync_region: this.defaultConfig.aws_appsync_region,
                    aws_appsync_authenticationType: this.defaultConfig.aws_appsync_authenticationType,
                },
            });
            try {
                const domainDetails: any = await this._network.fetch(queryGetDomains);
                const microAppDetails: IMicroAppFeature[] = [];
                if (domainDetails.data.fetchPilets) {
                    domainDetails.data.fetchPilets.forEach((item: IMicroAppFeature) => {
                        microAppDetails.push({ ...item });
                    });
                }
                /**
                * Storing all micro apps information in local storage in order to filter selected micro app metadata information to while restoring Pilet
                */
                window.sessionStorage.setItem(BROWSER_STORE_DOMAINS, JSON.stringify(microAppDetails));
                return result;
            } catch (error) {
                console.log(error);
                throw "Invalid User";
            }
        } catch (error) {
            /**
             * Storing App-shell appsync so that we will get to know default user login failed.
             */
            const errorData: any = [{ message: "App-shell default login failed", error }];
            window.sessionStorage.setItem(MICRO_APP_NAME + "-appsync", JSON.stringify(errorData));
            console.log(error);
            throw "Please reload the screen";
        }
    }

    /**
    * An Load default config event 
    */
    private loadDefaultConfig = async () => {
        await this.loadDefaultConfigJson();

        /**
         * Retry reading default config if not present in session.
         */
        if (!(this.defaultConfig.pentair_user_name && this.defaultConfig.pentair_user_password)) {
            await this.loadDefaultConfigJson();
        }
        if (this.defaultConfig.pentair_user_name && this.defaultConfig.pentair_user_password) {
            await this.getLogin(
                this.defaultConfig.pentair_user_name,
                this.defaultConfig.pentair_user_password
            )
        }
        else {
            throw "Default login credentials not exists"
        }
    }

    /**
     * On send code action callback
     */
    public onSendCode = async () => {
        try {
            if (!this.state.resetPassword.userName || this.state.resetPassword.userName.trim() === "") {
                this.setErrorMessage("Enter user name", true);
                return;
            } else {
                this.state.disable = true;
                this.state.isLoading = true;
                this.setErrorMessage("", false);
                await this.loadDefaultConfig();
                let flagDefaultLogin = 1;
                /**
                * Check if default appsync url available, if not load default again.
                */
                const flagAppsync = JSON.parse(
                    window.sessionStorage.getItem(BROWSER_STORE_DEF_CONFIG) || "{}"
                );
                if (!flagAppsync.userPoolId) {
                    flagDefaultLogin = await this.signInDefaultLogin();
                }
                if (flagDefaultLogin) {
                    /**
                     * To-do:Even if User is not valid we should redirect to Submit password 
                     * So that we will not display "No User found message"
                     */
                    await this.getIdentityDetails()
                        .then(async (response: any) => {
                            await this.getUserIdentityDetails(this.state.resetPassword.userName)
                            .then((identityResult: any) => {
                                // Get the flag to see the T&C checkboxes
                                this.change({
                                    termsAndConditionAccepted: identityResult?.termsAndConditionAccepted
                                })
                                this.history.push(route("app.shell.submitpassword"), {
                                    userName: this.state.resetPassword.userName.trim(),
                                });
                            });
                        })
                        .catch((error: any) => {
                            throw "Incorrect username.";
                        })
                }
            }
        }
        catch (error:any) {
            this.setErrorMessage(error?.message || "Enter valid user name", true);
        } finally {
            this.change({
                resetPassword: this.defaultUserName,
                isLoading: false,
                disable: false,
            });
        }
    }

    /**
     * Un-mount component lifecycle event
     */
    public unmount = async () => { }

}
