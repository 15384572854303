/* eslint-disable import/prefer-default-export */
import * as React from "react";
import {
  makeStyles,
  StyleRules,
} from "@material-ui/core";
import { Auth } from "aws-amplify";
import {
  getSessionData,
  PentairBlue,
  PentairTheme,
} from "@pentair/react";
import {
  IFavorite,
  IFeature,
  IMicroAppFeature,
} from "./types";
import {
  IconButton,
  FavoriteButton,
  LinkIconButton,
  ProfileIconButton,
} from "../../components/mui-extended/mui-icon-button";
import {
  BROWSER_STORE_FAVORITES,
  FAQ_PAGE_PATH,
  LANDING_PAGE_OBJECT_KEY,
  MICRO_APP_DASHBOARDS,
  MICRO_APP_USER_MANAGEMENT,
  PERSONAL_PROFILE,
} from "../../config/constants";
import {
  onNavigate,
  route,
} from "../../config/routes";
import { ToolBarItem } from "./partials/toolbar-dock-item";
import { Tooltip } from "./partials/tooltip";

const PROFILE_PAGE_PATH = "personalprofile/myprofile";

/**
 * Component props
 */
interface IOwnProps {
  // List of micro-apps
  microApps: IMicroAppFeature[];
  // On click of open favorite side bar
  openFavoriteSideDrawer: (openDrawer: boolean) => void;
  // On click of open notification side bar
  openNotificationSideDrawer: (openDrawer: boolean) => void;
}

/**
 * Component UX styles
 */
const useStyles = makeStyles((theme: PentairTheme): StyleRules => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: 48,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: PentairBlue[500],
    "& > nav": {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
  },
}));

/**
 * Left Navigation tool bar.
 */
export const ToolBar: React.FC<IOwnProps> = (props) => {
  // Style object
  const classes = useStyles();

  // Access state constants
  const {
    microApps,
    openFavoriteSideDrawer,
    openNotificationSideDrawer,
  } = props;

  /// Hold the username captured from user session
  const [userName, setUserName] = React.useState("");

  /// Hold User Management-Micro app-domain
  const [userManagementDomain, setUserManagementDomain] = React.useState("");

  /// Hold User Management-Micro app-profile page route
  const [profilePermission, setProfilePermission] = React.useState(true);

  /// Hold sorted list of all Micro apps
  const [localMicroApps, setLocalMicroApps] = React.useState<IMicroAppFeature[]>([]);
  let operationsApp = "";
  React.useEffect(() => {
    Auth.currentSession()
      .then((user: any) => {
        setUserName(
          user.idToken.payload.family_name[0].toUpperCase()
          + user.idToken.payload.given_name[0].toUpperCase(),
        );
      })
      .catch((error: any) => { });

    /// Set sorted list of all Micro apps
    setLocalMicroApps(microApps?.sort((a: IMicroAppFeature, b: IMicroAppFeature) => Number(a.order) - Number(b.order)));

    /// Set User Management-Micro app-domain
    operationsApp = microApps.find((microApp: IMicroAppFeature) => microApp.microapp === MICRO_APP_DASHBOARDS)?.domain;
    const userManagementApps = microApps.find((microApp: IMicroAppFeature) => microApp.microapp === MICRO_APP_USER_MANAGEMENT);
    setUserManagementDomain(userManagementApps?.domain || "");

    /// Set User Management-Micro app-profile page route
    const userProfile = userManagementApps?.features?.find((features: IFeature) => features.name === PERSONAL_PROFILE);
    setProfilePermission(!!(userProfile && userProfile?.permissions.length > 0));
  });

  /**
   * Checks if current page is favorite page
   * @returns true or false
   */
  const isFavoritePage = () => {
    try {
      const favorites = getSessionData(BROWSER_STORE_FAVORITES, []) as IFavorite[];
      if (favorites && favorites.length) {
        const favoritesArr: IFavorite[] = favorites;
        const landingPage = favoritesArr?.find((fav: IFavorite) => fav.key === LANDING_PAGE_OBJECT_KEY);
        if (landingPage && landingPage.value) {
          const favoriteRoute = JSON.parse(landingPage.value);
          return favoriteRoute && favoriteRoute.pathname === window.location.pathname;
        }
      }
    } catch (err) {
      ///
    }
    return false;
  };


  return (
    <aside className={classes.root}>
      <div>
        <nav style={{ display: "none" }}>
          <IconButton
            variant="primary"
            icon="bell"
            onClick={() => openNotificationSideDrawer(true)}
          />
        </nav>
        {localMicroApps.map((microApp: IMicroAppFeature, index: any) => {
          // Filter and ignore the Micro apps with empty `domain`
          if (!microApp.domain || microApp.domain === "") {
            return null;
          }
          /**
           * Check whether 'displayToolbar' enabled for App level
           * If YES-> Then check list of features and check for 'isDefaultPath=true'
           *          if multiple are there then get the first one from list of feature
           *          And prepare the icons list in sorted way.
           * If NO -> Then check list of features and check for 'displayToolbar=true'
           *          And prepare the icons list in sorted way. */
          const features = microApp?.features?.sort((a: IFeature, b: IFeature) => Number(a.order) - Number(b.order));
          if ((microApp.displayInToolbar === null || microApp.displayInToolbar === true) && features?.length) {
            const feature = features?.find((fe: IFeature) => fe.isDefaultPath === "true");
            if (feature) {
              return (
                <ToolBarItem
                  key={`child-app-key+${index}`}
                  features={[feature]}
                  microApp={microApp}
                />
              );
            } if (features?.length) {
              return (
                <ToolBarItem
                  key={`child-app-key+${index}`}
                  features={[features[0]]}
                  microApp={microApp}
                />
              );
            } return null;
          } if (features?.length) {
            return (
              <ToolBarItem
                key={`child-feature-key+${index}`}
                features={features.filter((f: IFeature) => f.displayInToolbar !== null && f.displayInToolbar === true)}
                microApp={microApp}
              />
            );
          } return null;
        })}
      </div>
      <div style={{ paddingBottom: 16 }}>
        <Tooltip title="Favorite" placement="right" arrow>
          <nav style={{ height: 40 }}>
            <FavoriteButton
              variant="primary"
              icon="star"
              style={{ height: "auto", padding: 0 }}
              active={isFavoritePage()}
              onClick={() => openFavoriteSideDrawer(true)}
              data-testid="fav-button"
            />
          </nav>
        </Tooltip>
        <Tooltip title="Home" placement="right" arrow>
          <nav style={{ height: 48 }}>
            <LinkIconButton variant="primary" icon="cog" to={route("app.shell.administration")} />
          </nav>
        </Tooltip>
        {profilePermission && (
          <Tooltip title={PERSONAL_PROFILE} placement="right" arrow>
            <nav style={{ height: 48 }}>
              <ProfileIconButton
                variant="primary"
                userName={userName}
                onClick={() => onNavigate(
                  MICRO_APP_USER_MANAGEMENT,
                  PROFILE_PAGE_PATH,
                  userManagementDomain,
                )}
              />
            </nav>
          </Tooltip>
        )}
        <Tooltip title="Support" placement="right" arrow>
          <nav>
            <IconButton
              data-testid="faq-button"
              variant="primary"
              icon={"headset"}
              key={"faq"}
              onClick={() => {
                onNavigate(
                  MICRO_APP_DASHBOARDS,
                  FAQ_PAGE_PATH,
                  operationsApp,
                )
              }
              }
            />
          </nav>
        </Tooltip>
      </div>
    </aside>
  );
};
