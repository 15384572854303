import * as React from "react";
import {
  Drawer,
  Grid,
  Typography
} from "@material-ui/core";
import { PageTitles } from "@pentair/react";

/**
 * Component props
 */
interface IOwnProps {
  open: number;
}

/**
 * Notification Drawer component definition
 * to alow user to view the list of notifications
 */
export const Notifications = ({ open }: IOwnProps) => {

  // Notification drawer display state
  const [openDrawer, setOpenDrawer] = React.useState<number>(0);

  /**
   * On drawer close callback
   */
  const handleDrawerClose = () => {
    setOpenDrawer(0);
  };

  //////Call the method on mount only.
  React.useEffect(() => {
    setOpenDrawer(open);
  }, [open]);

  return (
    <Drawer
      open={openDrawer > 0 ? true : false}
      anchor="left"
      style={{ padding: 12 }}
      PaperProps={{
        style: { marginLeft: 48, width: 360, overflow: "hidden" },
      }}
      BackdropProps={{ style: { marginLeft: 48 } }}
      onEscapeKeyDown={handleDrawerClose}
      onBackdropClick={handleDrawerClose}
    >
      <Grid container spacing={3} style={{ margin: 0 }}>
        <Grid item>
          <PageTitles title={"My"} subtitle={"Notifications"}/>
          <Typography variant="body2" component={"span"}>
            Notifications
          </Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
}