import { IConfigType } from "../features/common/types";

export const __CONFIGS__: IConfigType = {
  userPoolId: "",
  userPoolWebClientId: "",
  region: "us-east-1",
  mandatorySignIn: true,
  authenticationFlowType: "USER_SRP_AUTH",

  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  pentair_user_name: "brewassist.default@pentair.com",
  pentair_user_password: "Itistimeforchickendinnerandsomesoopandrest#3",
};
