import { CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import {
  BROWSER_STORE_IS_AUTHENTICATED,
  BROWSER_STORE_POOL_ID,
  BROWSER_STORE_WEB_CLIENT_ID,
  CALLBACK_SET_TOKEN,
  IS_MOBILE_LOGIN,
} from "../../config/constants";
import { IMobileAuthResponse } from "../common/types";

interface BasePayload {
  callback: string;
}

declare global {
  interface Window {
    externalApp?: {
      getExternalAuth(payload: string);
    };
    webkit?: {
      messageHandlers: {
        getExternalAuth: {
          postMessage(payload: BasePayload);
        };
      };
    };
  }
}

export class ExternalAuth {
  private _authPromise?: Promise<IMobileAuthResponse>;

  constructor() { }

  public getExternalAuth = async (payload: BasePayload) => {

    if (this._authPromise) {
      try {
        const tokens = await this._authPromise;
        return tokens
      } catch (err: any) {
        // _authPromise is in a rejected state
        // Clear the _authPromise and go on refreshing access token
        this._authPromise = undefined;
      }
    }
    this._authPromise = new Promise<IMobileAuthResponse>((resolve, reject) => {
      window.addEventListener(CALLBACK_SET_TOKEN, (event: any) => {
        if (event?.data?.payload) {
          resolve(event.data.payload)
        } else {
          reject();
        }
      });
      window[CALLBACK_SET_TOKEN] = (data: any) => {
        if (data?.payload) {
          resolve(data.payload);
        } else reject();
      }
    });
    await Promise.resolve();

    if (window.externalApp?.getExternalAuth) {
      window.externalApp.getExternalAuth(JSON.stringify(payload));
    } else if (window.webkit?.messageHandlers?.getExternalAuth) {
      window.webkit?.messageHandlers?.getExternalAuth?.postMessage(payload)
    }

    const tokens = await this._authPromise;
    return tokens;
    //  { idToken, accessToken, refreshToken }  return await assignTokenToSession(idToken, accessToken, refreshToken);
  }
}

/**
 * To transfer tokens from mobile device to web. will create session from the received tokens
 * @param idToken 
 * @param accessToken 
 * @param refreshToken 
 * @returns none
 */
export const assignTokenToSession = async (
  idToken: string,
  accessToken: string,
  refreshToken: string,
) => {
  if (!idToken || !accessToken) return;
  window.sessionStorage.setItem(BROWSER_STORE_IS_AUTHENTICATED, "true");
  let session: CognitoUserSession | undefined = undefined;
  try {
    const cognitoIdToken = new CognitoIdToken({
      IdToken: idToken,
    });
    const cognitoAccessToken = new CognitoAccessToken({
      AccessToken: accessToken,
    });
    const cognitoRefreshToken = refreshToken
      ? new CognitoRefreshToken({ RefreshToken: refreshToken })
      : undefined;
    const username = cognitoIdToken.payload.email;
    const poolId: string = cognitoAccessToken.payload.iss
      .split("/")
      .slice(-1)[0];
    const userPool = new CognitoUserPool({
      UserPoolId: poolId,
      ClientId: cognitoAccessToken.payload.client_id,
      endpoint: cognitoAccessToken.payload.iss,
      AdvancedSecurityDataCollectionFlag: true,
    });
    const cognitoUser = {
      Username: username,
      Pool: userPool,
    }
    const user = new CognitoUser(cognitoUser);
    const localSession = new CognitoUserSession({
      IdToken: cognitoIdToken,
      AccessToken: cognitoAccessToken,
      RefreshToken: cognitoRefreshToken,
    });
    window.sessionStorage.setItem(BROWSER_STORE_POOL_ID, poolId);
    window.sessionStorage.setItem(
      BROWSER_STORE_WEB_CLIENT_ID,
      cognitoAccessToken.payload.client_id,
    );
    window.sessionStorage.setItem(IS_MOBILE_LOGIN, "true");
    user.setSignInUserSession(localSession);

    Auth.currentSession = async () => localSession;
    Auth.currentAuthenticatedUser = async () => cognitoUser;
    session = await Auth.currentSession();

  } catch (error) {
    console.error("Mobile Token Login Exception: ", error);
    return;
  }
  return session;
}
